import { Helmet } from "react-helmet"
import Layout from "../../../components/layouts"
import Header from "../../../components/header"
import { Link, useNavigate, useParams } from "react-router-dom"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { showPatient } from "../../../services/patient_service"
import { parseISO, isAfter } from "date-fns"

const DetailPasien = () => {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    
    const { id } = useParams()
    const [data, setData] = useState(null)
    const fetchPatient = async () => {
        setLoading(true)
        try {
            const response = await showPatient(localStorage.getItem('access_token'), id)
            setLoading(false)
            console.log(response);
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    break;

                default:
                    navigate('/404')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }
    useEffect(() => {
        if (refresh === true) {
            fetchPatient()
        }
    }, [refresh]);

    // eslint-disable-next-line
    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            // if (!['super admin', 'admin', 'dokter umum', 'dokter gigi', 'perawat'].includes(userRole)) {
            //     navigate('/403?back=/dashboard/patient')
            // } else
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {

                fetchPatient()
            }
        }
    }, [])

    const CardList = ({ name, value }) => {
        return (
            <div className="flex items-center space-x-3">
                <p className="w-40">{name}</p>
                <p>:</p>
                <p>{value}</p>
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title>Detail Pasien</title>
            </Helmet>
            <Layout>
                <Header>
                    <Link to="/dashboard/pasien" className="flex items-center space-x-3 px-3 py-2" >
                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                        <p className="hidden md:flex">Kembali</p>
                    </Link>
                    <p>Detail Pasien</p>

                </Header>
                <div className="w-full flex flex-col space-y-3 p-3">
                    <CardList name={`Kategori Pasien`} value={data?.category} />
                    {data?.category === 'non_bpjs' ? '' : <CardList name={`No BPJS`} value={data?.bpjs_number} />}
                    <CardList name={`Nik`} value={data?.nik} />
                    <CardList name={`Nama Pasien`} value={data?.name} />
                    <CardList name={`Tempat Lahir`} value={data?.place_of_birth} />
                    <CardList name={`Tanggal Lahir`} value={data?.birthday} />
                    <CardList name={`Jenis Kelamin`} value={data?.gender} />
                    <CardList name={`No Telepon`}
                        value={data?.phone_number === null ||
                            data?.phone_number === 'null' ? '-' :
                            data?.phone_number} />
                    <CardList name={`Total Kunjungan`} value={data?.total_kunjungan} />
                </div>
            </Layout>
        </>
    )
}

export default DetailPasien 