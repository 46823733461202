const CustomSelect = ({ name, value, setValue, list, displayName, CustomOption, onChange }) => {
    const data = list ?? []
    return (
        <div className="w-full flex flex-col">
            <label htmlFor={name} className="font-semibold text-xs md:text-sm">{name}</label>
            <select id={name} value={value}
                onChange={
                    setValue === null || setValue === undefined || setValue === '' ?
                        onChange :
                        (e) => setValue(e.target.value)
                } className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md text-xs md:text-sm lg:text-base overflow-auto">
                {CustomOption}
                {data.map((item) => (
                    <option className="text-xs md:text-sm lg:text-base" value={item.id}>{item[displayName ?? 'name']}</option>
                ))}
            </select>
        </div>
    )
}

export default CustomSelect