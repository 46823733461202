import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import Layout from "../../../../components/layouts"
import { Helmet } from "react-helmet"
import ModalAddTarifLayanan from "./modals/add"
import Header from "../../../../components/header"
import { getTarifLayanan, getTarifLayananCategory, searchTarifLayanan } from "../../../../services/tarif_layanan_service"
import { parseISO, isAfter } from "date-fns"
import { useHref, useNavigate } from "react-router-dom"
import Pagination from "../../../../components/pagination"
import CustomCurrency from "../../../../components/custom_currency"

const TarifLayanan = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModal, setOpenModal] = useState(false)

    const [category, setCategory] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [categoryIdV2, setCategoryIdV2] = useState('')
    const [keyword, setKeyword] = useState('')

    let [number, setNumber] = useState(1);
    // let [numberV2, setNumberV2] = useState(1);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchData = async () => {
        setLoading(true)
        const response = await getTarifLayanan(accessToken, categoryIdV2, currentPage)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    const searchData = async () => {
        setLoading(true)
        const response = await searchTarifLayanan(accessToken, categoryIdV2, keyword, currentPage)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    const fetchDataCategory = async () => {
        setLoading(true)
        const response = await getTarifLayananCategory(accessToken)
        if (response.status === 200) {
            setCategory(response.data)
            setCategoryId(response.data[0].id)
            setCategoryIdV2(response.data[0].id)
        } else {
            setCategory([])
            setCategoryId('')
            setCategoryIdV2('')
        }


        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'farmasi'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
                fetchDataCategory()
            }
        }
    }, [currentPath, navigate]);


    const handleSearch = (e) => {
        e.preventDefault()
        searchData()
    }
    return (
        <>
            <Helmet>
                <title>Tarif Layanan - Klinik Yasinta</title>
            </Helmet>
            <Layout

                modal={
                    <>
                        <ModalAddTarifLayanan accessToken={accessToken}
                            openModal={openModal} setOpenModal={setOpenModal}
                            setRefresh={setRefresh} getCategory={category} getCategoryId={categoryId} />
                    </>
                }>
                <Header>
                    <p className="uppercase">Tarif Layanan - Klinik Yasinta</p>
                    <form onSubmit={handleSearch} className="flex items-center relative">
                        <input type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            className="outline-none border-2 rounded-l-md px-3 py-2 text-black" />
                        <button type="submit" className="bg-green-500 px-3 py-2 rounded-r-md border-2 border-green-500">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                    <p className="flex items-end space-x-2">
                        <span className="text-2xl font-semibold">{totalData}</span>
                        <span>Tarif Layanan</span>
                    </p>
                </Header>

                <div className="w-full flex items-center justify-between px-5 py-3 text-white">
                    <div className="flex items-center space-x-3">
                        <button type="button" className="px-3 py-2 bg-custom_orange font-semibold rounded-md w-32">
                            Semua
                        </button>
                        <button type="button" className="px-3 py-2 bg-custom_green hover:bg-custom_orange font-semibold rounded-md w-32">
                            Jasa
                        </button>
                        <button type="button" className="px-3 py-2 bg-custom_green hover:bg-custom_orange font-semibold rounded-md w-32">
                            Tindakan
                        </button>

                    </div>
                    <button type="button" onClick={() => setOpenModal(true)} className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Tambah Tarif Layanan</p>
                    </button>
                </div>

                <div className="w-full px-5 pb-10">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                <th className="px-3 py-2 text-start">Kategori</th>
                                <th className="px-3 py-2 text-start">Nama Layanan</th>
                                <th className="px-3 py-2 text-start">Tarif Layanan</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading === true ? 'Loading' :
                                    totalData === 0 ? 'Tidak ada data' :
                                        data.map((item) => (
                                            <tr className="hover:bg-gray-200 transition-colors">
                                                <td className="px-3 py-2 text-center">{number++}</td>
                                                <td className="px-3 py-2 ">{item.category}</td>
                                                <td className="px-3 py-2 ">{item.name}</td>
                                                <td className="px-3 py-2 ">
                                                    <CustomCurrency number={item.tarif} />
                                                </td>
                                                <td className="">
                                                    <button type="button" className="px-3 py-1 mx-auto w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </td>
                                                <td className="px-1 py-2">
                                                    <button type="button" className="px-3 py-1 w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </td>
                                                <td className="">
                                                    <button type="button" className="px-3 py-1 w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                            }


                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />
                        </tbody>
                    </table>
                </div>
            </Layout>
        </>
    )
}

export default TarifLayanan