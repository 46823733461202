import apiService from "./api_service"

export const getPengadaanObat = async (accessToken, page) => {
    try {

        const response = await apiService.get(`/pengadaan-obat?page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const showPengadaanObat = async (accessToken, id) => {
    try {

        const response = await apiService.get(`/pengadaan-obat/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postPengadaanObat = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/pengadaan-obat/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

