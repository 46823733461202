import apiService from "./api_service";

export const getPatient = async (accessToken, page ) => {
    try {
        const response = await apiService.get(`patients/?page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}
export const searchPatient = async (accessToken, keyword) => {
    try {
        const response = await apiService.get(`patient/search?keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}
export const showPatient = async (accessToken, patientId) => {
    try {
        const response = await apiService.get(`patient/show/${patientId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}
export const postPatient = async (accessToken, formData) => {
    try {
        const response = await apiService.post(`patient/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}
export const deletePatient = async (accessToken, id) => {
    try {
        const response = await apiService.post(`patient/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}