import apiService from "./api_service"

export const getDrugs = async (accessToken) => {
    try {
        const response = await apiService.get(`drugs`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}

export const searchDrugs = async (accessToken, keyword) => {
    try {
        const response = await apiService.get(`drug/search?keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}

export const postDrug = async (
    accessToken,
    drug_suplier_id,
    name,
    unit,
    invoice_price,
    fixed_price,
    stock,
) => {
    try {
        const formData = new FormData()
        formData.append('drug_suplier_id', drug_suplier_id ?? null)
        formData.append('name', name)
        formData.append('unit', unit)
        formData.append('invoice_price', invoice_price)
        formData.append('fixed_price', fixed_price)
        formData.append('stock', stock)

        const response = await apiService.post(`drug/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}

export const importDrugs = async (
    accessToken,
    file
) => {
    try {
        const formData = new FormData()
        formData.append('file', file)

        const response = await apiService.post(`drug/import`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}

export const deleteDrug = async (accessToken, id) => {
    try {
        const response = await apiService.post(`drug/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}