import apiService from "./api_service"

export const getBarang = async (accessToken, orderBy, sortBy, page) => {
    try {

        const response = await apiService.get(`/barang?order_by=${orderBy}&sort_by=${sortBy}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searcBarang = async (accessToken, keyword, orderBy, sortBy, page) => {
    try {

        const response = await apiService.get(`/barang?keyword=${keyword}&order_by=${orderBy}&sort_by=${sortBy}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}