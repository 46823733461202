import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { Link, useHref, useNavigate, useParams } from "react-router-dom"
import ButtonBack from "../../../../components/button_back"
import { useState } from "react"
import { showPengadaanObat } from "../../../../services/pengadaan_obat_service"
import { parseISO, isAfter } from "date-fns"
import { useEffect } from "react"
import CustomHour from "../../../../components/custom_hour"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomCurrency from "../../../../components/custom_currency"

const DetailPengadaanObat = () => {
    const path = useHref()
    const pathParts = path.split('/');
    const backLink = pathParts.slice(0, -2).join('/');
    const { id } = useParams()

    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const navigate = useNavigate()
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let number = 1
    const [data, setData] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const fetchData = async () => {
        setLoading(true)
        const response = await showPengadaanObat(accessToken, id)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'farmasi'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [path, navigate]);

    const Row = ({ name, data }) => {
        return (
            <tr>
                <td className="pr-3 font-semibold">{name}</td>
                <td className="px-3">:</td>
                <td className="">{data}</td>
            </tr>
        )
    }

    return (
        <>
            <Helmet>
                <title>Detail Pengadaan Obat</title>
            </Helmet>
            <Layout>
                <Header>
                    <ButtonBack link={backLink} />
                    <p>Detail Pengadaan Obat</p>
                </Header>


                {loading === true ? 'Loading...' :
                    <div className="w-full px-3 md:px-5 py-3 bg-gra">
                        <div className="flex items-end justify-between">
                            <table>
                                <Row name={`Waktu`} data={<CustomHour data={data?.created_at} />} />
                                <Row name={`Tanggal`} data={
                                    <div className="flex">
                                        <CustomDayName data={data?.created_at} />, &nbsp;
                                        <CustomDay data={data?.created_at} />-
                                        <CustomMonth data={data?.created_at} />-
                                        <CustomYear data={data?.created_at} />
                                    </div>
                                } />

                                <Row name={`Status`} data={
                                    <div className={`font-semibold text-white px-3 rounded-full text-sm text-center ${data?.status === 'dalam proses' ? 'bg-yellow-500' : data?.status === 'selesai' ? 'bg-custom_green' : data?.status === 'dibatalkan' ? 'bg-red-500' : ''}`}>
                                        {data?.status}
                                    </div>
                                } />
                                <Row name={`Dibuat Oleh`} data={data?.created_by?.name} />
                                <Row name={`Diupdate Oleh`} data={data?.updated_by?.name ?? '-'} />
                            </table>
                            <div className="flex space-x-3 text-sm">
                                <Link to="print-surat-pengadaan" className="px-3 py-2 rounded-md bg-custom_green hover:bg-green-500 text-white">
                                    Update
                                </Link>
                                <Link to="print-surat-pengadaan" className="px-3 py-2 rounded-md bg-custom_green hover:bg-green-500 text-white">
                                    Print Surat
                                </Link>
                            </div>
                        </div>

                        <div className="w-full overflow-auto pt-3">
                            <table className="w-full min-w-max max-w-screen-xl">
                                <thead>
                                    <tr className="bg-custom_green text-white">
                                        <th className="px-3 py-2 text-center rounded-tl-xl w-fit">No</th>
                                        <th className="px-3 py-2 text-center">Nama Barang</th>
                                        <th className="px-3 py-2 text-center w-fit">Harga Faktur</th>
                                        <th className="px-3 py-2 text-center w-fit">Jumlah Barang</th>
                                        <th className="px-3 py-2 text-center ">keterangan</th>
                                        <th className="px-3 py-2 text-center rounded-tr-xl">Total Harga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.item_pengadaan?.map((item, index) => (
                                        <tr className={`${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'} font-semibold hover:bg-gray-200 transition-colors text-sm`}>
                                            <td className="text-center px-3 py-2 ">{number++}</td>
                                            <td className="text-start px-3 py-2 ">{item.obat?.name}</td>
                                            <td className="text-end px-3 py-2">
                                                <CustomCurrency number={item?.price} />
                                            </td>
                                            <td className="text-center px-3 py-2">{item.quantity}</td>
                                            <td className="text-start px-3 py-2 capitalize">{item.note ?? '-'}</td>
                                            <td className="px-3 py-2 text-end text-base">
                                                <CustomCurrency number={item?.price * item?.quantity} />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr className="bg-custom_green text-white font-bold">
                                        <td className="px-3 py-2 text-end" colSpan={5}>Total</td>
                                        <td className="px-3 py-1 text-end text-xl">
                                            <CustomCurrency number={data?.total_price} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </Layout>
        </>
    )
}

export default DetailPengadaanObat