import { useEffect, useState } from "react"
import { getIcd, searchIcd } from "../../../../../services/icd_service"
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { postDiagnosa } from "../../../../../services/diagnosa_service"
import { useParams } from "react-router-dom"
import Kunjungan from "../.."

const ModalDiagnosa = ({ kunjunganId, accessToken, openModal, setOpenModal, setRefresh }) => {

    const accessToken2 = localStorage.getItem('access_token2')
    const [loading, setLoading] = useState(false)
    const [openSelect, setOpenSelect] = useState(false)

    const [dataIcd, setDataIcd] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [diagnosaId, setDiagnosaId] = useState(null)
    const [diagnosaName, setDiagnosaName] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)
    const [keyword, setKeyword] = useState('')

    const fetchIcd = async (page) => {
        setLoading(true)
        try {
            const response = await getIcd(page)
            setLoading(false)
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setDataIcd(response.data.data);
                    setCurrentPage(response.data.current_page);
                    setLastPage(response.data.last_page);
                    break;

                default:
                    // navigate('/404')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    const searchFecthIcd = async (page = 1) => {
        try {
            const response = await searchIcd(keyword, page)
            setLoading(false)
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setDataIcd(response.data.data);
                    setDiagnosaId(response.data.data[0].id ?? null)
                    setDiagnosaName(response.data.data[0].display ?? null)
                    setCurrentPage(response.data.current_page);
                    setLastPage(response.data.last_page);
                    break;

                default:
                    // navigate('/404')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    useEffect(() => {
        if (keyword.length === 0) {
            fetchIcd(currentPage)
        } else {
            searchFecthIcd(currentPage)
        }
    }, [currentPage])


    const handleSearch = () => {
        searchFecthIcd()
    }

    const handleNextPage = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()
        formData.append('kunjungan_id', kunjunganId)
        formData.append('icd_id', diagnosaId)
        formData.append('token_v2', accessToken2)

        const response = await postDiagnosa(accessToken, formData)
        console.log(response);
    }
    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? `w-full delay-200 py-[70px]` : 'w-0 h-0 overflow-hidden delay-0'} `}>

                <div className="w-full absolute z-10 top-0 text-center  h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Diagnosa Pasien</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full space-y-3 px-3 overflow-auto scrollbar-thin scrollbar-track-custom_green scrollbar-thumb-custom_orange">
                    <div className=" w-full ">
                        <button type="button"
                            onClick={() => {
                                setOpenSelect(!openSelect)
                            }}
                            className="w-full flex items-center justify-between space-x-5 capitalize py-2 px-3 border-2 border-custom_orange rounded-md">
                            <div className="flex items-center space-x-3">
                                {
                                    diagnosaName === null ?
                                        <p className="line-clamp-1">Pilih Diagnosa</p> :
                                        <p>{diagnosaName}</p>
                                }
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className={`transition-all duration-500 ${openSelect === true ? '-rotate-180' : 'rotate-0'}`} />
                        </button>

                        {errorMessage === null ? '' :
                            <p className="text-sm text-red-500">
                                {errorMessage}
                            </p>
                        }
                        <div className={` bg-white w-full inline-flex flex-col bg-transparent rounded-xl  text-sm text-black space-y-3 transition-all duration-300 mt-3 ${openSelect === true ? ' max-h-[350px] px-3 pb-5 border shadow-md overflow-auto' : 'max-h-0 border-none mt-0 overflow-hidden'}`}>
                            <div className="w-full sticky top-0 pt-5 bg-white flex items-center group">
                                <input
                                    type="search"
                                    placeholder="Cari Diagnosa - ICD_10"
                                    value={keyword}
                                    onChange={(e) => setKeyword(e.target.value)}
                                    className="w-full outline-none border-2 border-custom_green px-3 py-2 rounded-l-md group-hover:border-custom_green" />
                                <button type="button" onClick={handleSearch} className="border-2 focus:border-custom_green px-3 py-2 rounded-r-md border-custom_green bg-custom_green text-white">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                            {dataIcd.map((item) => (
                                <button type="button"
                                    onClick={() => {
                                        setDiagnosaId(item.id)
                                        setDiagnosaName(item.display)
                                        setOpenSelect(false)
                                    }}
                                    className={`w-full flex items-start justify-between space-x-3 border-2 rounded-md px-3 py-2 hover:bg-gray-200 transition-colors ${item.id === diagnosaId ? 'bg-gray-200' : ''}`}>
                                    <p className="text-justify">{item.display}</p>
                                    <p className="text-end font-semibold">({item.code})</p>
                                </button>
                            ))}
                            <div className="w-full flex items-center justify-between">
                                <button type="button">
                                    Previous Page
                                </button>
                                <p>{currentPage}</p>
                                <button type="button" onClick={handleNextPage}>
                                    Next Page
                                </button>
                            </div>
                        </div>

                    </div>


                    <div className="flex items-center space-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white">
                            Kembali
                        </button>
                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 hover:bg-green-600 py-2 rounded-md font-semibold text-white">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="w-full absolute bottom-0 text-center border-t-2 h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalDiagnosa