import { Helmet } from "react-helmet"
import Layout from "../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { Link, useHref, useNavigate } from "react-router-dom"
import Header from "../../components/header"
import LogoutButton from "../../components/logout"
import { parseISO, isAfter } from 'date-fns'

const Dashboard = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const userName = localStorage.getItem('user_name')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [openDropdown, setOpenDropdown] = useState(false)

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (currentPath === '/dashboard' || currentPath === 'dashboard') {
                navigate('/dashboard/index')
            }
        }

    }, [currentPath, navigate]);
    return (
        <>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Layout>
                <Header>
                    <div className="font-semibold uppercase flex items-center md:space-x-1">
                        <span>Dashboard</span>
                        <span className="hidden md:flex">- Klinik Yasinta</span>
                    </div>
                    <div className="relative w-60 flex start justify-end">
                        <button type="button" onClick={() => setOpenDropdown(!openDropdown)} className="flex items-center space-x-5 capitalize py-2 px-3">
                            <div className="flex items-center md:space-x-3">
                                <div className="h-10 aspect-square rounded-full bg-gray-300"></div>
                                <p className="line-clamp-1 hidden md:flex">{userName}</p>
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className={`hidden md:flex transition-all duration-500 ${openDropdown === true ? '-rotate-180' : 'rotate-0'}`} />
                        </button>
                        <div className={`absolute w-full inline-flex flex-col items-center justify-center bg-transparent rounded-xl  text-sm text-black space-y-3 transition-all duration-500 overflow-hidden mt-16 ${openDropdown === true ? 'h-40 p-3 border shadow-md' : 'h-0 border-none mt-0'}`}>
                            <Link to="/" className="w-full px-3 py-2 bg-gray-50 hover:bg-gray-200 text-center rounded-full transition-colors" >Kembali ke halaman utama</Link>
                            <Link to="/dashboard/setting" className="w-full px-3 py-2 bg-gray-50 hover:bg-gray-200 text-center rounded-full transition-colors" >
                                Pengaturan
                            </Link>
                            <LogoutButton />
                        </div>
                    </div>
                </Header>
                <div className="w-full grid grid-cols-4 gap-3 p-5">
                    {/* <div className="w-full aspect-video bg-white hover:bg-custom_green transition-colors border shadow-md rounded-xl flex items-center justify-center font-semibold">
                        <div className="text-center">
                            <p className="text-xl">Kunjungan</p>
                            <p>100 Pasien</p>
                        </div>
                    </div>

                    {userRole === 'dokter' || userRole === 'apoteker' ?
                        <div className="w-full aspect-video bg-white hover:bg-custom_green transition-colors border shadow-md rounded-xl flex items-center justify-center font-semibold">
                            <div className="text-center">
                                <p className="text-xl">Resep Yang Sudah Dibuat</p>
                                <p>100 Pasien</p>
                            </div>
                        </div> : ''
                    } */}
                </div>
            </Layout>
        </>
    )
}

export default Dashboard