import womanDoctor from '../../assets/icons/patient.png'
import sinatryana from '../../assets/images/doctor/sinatri.jpeg'
import hilda from '../../assets/images/doctor/hilda.jpeg'
import asep from '../../assets/images/doctor/asep.jpeg'
import supita from '../../assets/images/doctor/supita.jpeg'
import product from '../../assets/images/product/one.png';
import product2 from '../../assets/images/product/two.png';
import product3 from '../../assets/images/product/three.png';
import doctor from '../../assets/images/doctor.png';
import heroImage from '../../assets/images/bg.jpg';
import logoYasinta from '../../assets/icons/logo_yasinta.png';

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai'
import { HiMenuAlt3 } from "react-icons/hi";

import FadeIn from '../../components/fade_in'
import Navlinks from '../../components/Navlink'


const LandingPage = () => {
    const [showMobileMenu, setShowMobileMenu] = useState();
    const [scrolling, setScrolling] = useState(false);

    const [accessToken, setAccessToken] = useState(null)

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token')
        setAccessToken(accessToken)
        console.log(accessToken);
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolling(true);
            } else {
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // services
    const services = [
        {
            title: "Skinbooster",
            subtitle: "Perawatan kulit dengan menyuntikan acid",
            icon: womanDoctor,
        },
        {
            title: "Eye Brown",
            subtitle: "100% secure",
            icon: womanDoctor,
        },
        {
            title: "Laser IPL",
            subtitle: "Untuk Menyamarkan tanda tanda penuaan",
            icon: womanDoctor,
        },
    ];
    const doctors = [
        {
            dokterName: "Sinatryana Bahran",
            spesialis: "Poli Umum",
            description: "Dokter pelaksana harian poli umum penanggung jawab Klinik",
            img: sinatryana,
        },
        {
            dokterName: "Hilda Hindasah",
            spesialis: "Dokter Gigi",
            description: "Dokter Pelaksana harian poli gigi Koordinator mutu",
            img: hilda,
        },
        {
            dokterName: "Asep Ridwan",
            spesialis: "Poli Umum",
            description: "dokter pelaksana harian poli umum penanggung jawab mutu",
            img: asep,
        },
        {
            dokterName: "Supita Ratna Wulan",
            spesialis: "Poli Gigi",
            description: "dokter pelaksana harian poli gigi koordinator managemen resiko",
            img: supita,
        },



    ];

    // footer

    const newsletter = "News Information";

    const footerLinksColumnOne = [
        {
            title: "Support",
            links: ["About Us", "Careers", "Blog"],
        },
        {
            title: "Useful Links",
            links: ["Payment & Tax", "Terms of service", "Privacy Policy"],
        },
    ];
    const footerLinksColumnTwo = [
        {
            title: "Our Menu",
            links: ["Best Product", "Categories"],
        },
        {
            title: "Address",
            links: [
                "Jl. Raya Bayongbong, Garut, Indonesia, West Java",
                "Dr.sinatryana1981@gmail.com",
            ],
        },
    ];

    return (
        <div>
            {/*Header */}
            <div
                id="home"
                className={`h-screen relative flex flex-col items-center ${scrolling ? 'fixed top-0 bg-custom-orange shadow-md' : ''
                    }`}
                style={{
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.5)), url(${heroImage})`,
                    backgroundPosition: 'bottom',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                }}
            >
                <div className="fixed z-50 w-full max-w-[1490px] flex items-center justify-between pt-2 mx-auto px-4 shadow-sm shadow-custom_gray/35 backdrop-blur" >
                    <img src={logoYasinta} alt="Yasinta" className=" xl:w-20 lg:w-20 md:w-12 sm:w-8 h-10 aspect-square object-contain  " />
                    <ul className='hidden md:flex items-center gap-10 xs:flex lg:gap-16'>
                        <Navlinks to="/" >Home</Navlinks>
                        <Navlinks to="services" >Services</Navlinks>
                        <Navlinks to="dokter" >Dokter</Navlinks>
                        <Navlinks to="product" >Product</Navlinks>
                        <Navlinks to="about" >About</Navlinks>
                    </ul>
                    <div>
                        {accessToken === null || accessToken === '' ?
                            <Link to="/login" className='text-lg lg:text-xl text-custom_white bg-custom_orange rounded-full cursor-pointer px-6 py-2  hover:text-custom_black hover:bg-custom_green hidden md:block '>
                                Login
                            </Link> :
                            <Link to="/dashboard" className='text-lg lg:text-xl text-custom_white bg-custom_orange rounded-full cursor-pointer px-6 py-2  hover:text-custom_black hover:bg-custom_green hidden md:block '>
                                Dashboard
                            </Link>
                        }
                        <HiMenuAlt3
                            size={30}
                            className="block md:hidden cursor-pointer text-custom_black"
                            onClick={() => setShowMobileMenu((prev) => !prev)}
                        />
                        <div className={`block md:hidden w-full fixed ${!showMobileMenu ? "-top-[410px]" : "top-0"} left-0 bg-custom_white text-custom_black h-[410px] transition-all duration-700 shadow-xl z-10 py-6 px-10 rounded-b-xl`}>
                            <AiOutlineClose
                                size={25}
                                className="absolute top-5 right-5 cursor-pointer"
                                onClick={() => setShowMobileMenu(false)}
                            />
                            <img src={logoYasinta} alt="Yasinta" className='flex w-8 aspect-square object-contain' />
                            <ul className='pt-2 items-left flex flex-col cursor-pointer'>
                                <Navlinks to="/" >Home</Navlinks>
                                <Navlinks to="/dokter" >Dokter</Navlinks>
                                <Navlinks to="/services" >Services</Navlinks>
                                <Navlinks to="/product" >Product</Navlinks>
                                <Navlinks to="/about" >About</Navlinks>
                            </ul>
                            {accessToken === null || accessToken === '' ?
                                <Link to="/login" className='flex mt-8 mx-auto items-center justify-center text-lg lg:text-xl text-custom_white bg-custom_orange rounded-full cursor-pointer px-6 py-2  hover:text-custom_black hover:bg-custom_green  '>
                                    Login
                                </Link> :
                                <Link to="/dashboard/index" className='flex mt-8 mx-auto items-center justify-center text-lg lg:text-xl text-custom_white bg-custom_orange rounded-full cursor-pointer px-6 py-2  hover:text-custom_black hover:bg-custom_green  '>
                                    Dashboard
                                </Link>
                            }

                        </div>

                    </div>
                </div>

                <div className='flex flex-col items-center'>
                    <FadeIn delay={0.2} direction="down" padding fullWidth>
                        <h1 className="mt-[90px] xl:mt-36 text-center leading-tight xs:text-2xl font-bold lg:text-4xl md:text-4xl sm:text-lg block text-black max-w-[1050px] hover:text-custom_blue">
                            Menjaga Keluarga untuk Masa Depan yang Sehat
                        </h1>
                    </FadeIn>
                    <FadeIn delay={0.4} direction="down" padding fullWidth>
                        <h5 className="mt-6 text-center xl:text-xl md:text-sm sm:text-sm xs:text-sm text-black max-w-[500px] hover:text-custom_orange">
                            Di Yasinta Klinik, komitmen kami adalah memelihara kesehatan dan kesejahteraan keluarga Anda. Kami memahami pentingnya lingkungan yang aman dan sehat bagi setiap rumah tangga. Dengan landasan yang dibangun atas dasar kasih sayang dan keahlian medis, Yasinta Klinik berdiri sebagai mercusuar jaminan, memastikan bahwa keluarga Anda tetap aman dan dalam kondisi kesehatan terbaik.
                        </h5>
                    </FadeIn>
                    <FadeIn delay={0.2} direction="up" padding fullWidth>
                        <div className="relative w-full xs:w-[350px] xl:w-1/2  md:w-3/4 mt-11">
                            <img src={doctor} alt="Logo" />
                        </div>
                    </FadeIn>
                </div>

            </div>

            {/*Services */}
            <div id='services' className="mt-[160px] h-screen max-w-[1490px] mx-auto px-10 flex flex-col lg:flex-row md:flex-row xs:flex-col gap-10 xs:gap-6 xs:justify-between w-full">
                {services.map((service, i) => (
                    <FadeIn key={i} delay={0.2} direction="down">
                        <div className="flex flex-col lg:flex-row gap-4 w-full items-center">
                            <img
                                src={service.icon}
                                className="max-h-[84px] max-w-[84px]"
                                alt=""
                            />
                            <div className="flex flex-col gap-1.5">
                                <h3 className="text-center lg:text-start text-2xl lg:text-[28px] text-fontBlack font-medium">
                                    {service.title}
                                </h3>
                                <h6 className="text-center lg:text-start  text-base lg:text-lg text-fontGray font-medium">
                                    {service.subtitle}
                                </h6>
                            </div>
                        </div>
                    </FadeIn>
                ))}
            </div>

            {/*Dokter */}
            <div id='dokter' className="flex  w-full flex-col 2xl:flex-row gap-[128px] 2xl:gap-2 2xl:justify-between mt-[160px] 2xl:mt-[270px] mb-[100px] max-w-[1490px]
      mx-auto px-10">
                <div
                    id="products"
                    className="flex  w-full flex-col 2xl:flex-row gap-[128px] 2xl:gap-2 2xl:justify-between mt-[160px] 2xl:mt-[270px] mb-[100px] max-w-[1490px]
mx-auto px-10"
                >
                    <FadeIn delay={0.2} direction="right">
                        <div className="flex flex-col">
                            <h1 className="text-center 2xl:text-start text-fontBlack text-5xl lg:text-[64px] font-medium mb-4">
                                Best Dokter
                            </h1>
                            <button
                                className="flex items-center gap-2 bg-transparent text-fontBlack border-transparent font-bold py-2
      px-4 rounded-lg w-fit border hover:border-black outline-none focus:outline-none ease-linear transition-all duration-350 mx-auto
      2xl:mx-0 mb-10 2xl:mb-0"
                            >
                                <h5 className="text-lg lg:text-xl text-[#4F4F4F] font-medium">
                                    See all Dokter
                                </h5>

                            </button>
                        </div>
                    </FadeIn>
                    <div className="w-full flex flex-col items-center justify-center md:flex-col lg:flex-row xl:flex-row gap-[160px] md:gap-6 xs:gp-2 sm:ga">
                        {doctors.map((product, i) => (
                            <FadeIn key={i} delay={(i + 1) * 0.2} direction="left" fullWidth>
                                <div className="h-[510px] w-full relative flex-1 bg-custom_orange rounded-[50px] max-w-[500px]  mb-6 md:mb-0 md:w-[250px]  md:flex-col md:items-center ">
                                    <img
                                        src={product.img}
                                        alt=""
                                        className=" left-1/2 rounded-3xl bg-custom_white object-cover "
                                        style={{ maxWidth: '100%', }}
                                    />
                                    <div className="absolute bottom-0 w-full bg-custom_white h-[260px] hover:bg-custom_gray hover:text-white rounded-[50px] shadow-md px-8 py-[26px] flex flex-col justify-between">
                                        <div>
                                            <h2 className="mb-2 text-fontBlack text-3xl lg:text-[32px] font-medium">
                                                {product.dokterName}
                                            </h2>
                                            <p>{product.description}</p>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-fontBlack text-2xl lg:text-3xl font-medium">
                                                {product.spesialis}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </FadeIn>
                        ))}
                    </div>

                </div>
            </div>
            {/*product */}
            <div id='product'>
                <FadeIn delay={0.2} direction="down">
                    <h1 className="text-5xl lg:text-[64px] font-medium text-fontBlack mb-6 text-center xl:mt-24">
                        Best Selling Products
                    </h1>
                </FadeIn>
                <FadeIn delay={0.4} direction="down">
                    <h5 className="text-[#4F4F4F] text-lg xs:text-xl mb-12 text-center">
                        See Colection
                    </h5>
                </FadeIn>
                <div className="flex flex-col md:flex-row md:justify-center gap-8">
                    <FadeIn delay={0.2} direction="right">
                        <div className="flex flex-col gap-8">
                            <img src={product} alt="" className='rounded-md' />
                            <img src={product2} alt="" className='rounded-md' />
                        </div>
                    </FadeIn>
                    <FadeIn delay={0.2} direction="left">
                        <div>
                            <img src={product3} alt="" className='rounded-md' />
                        </div>
                    </FadeIn>
                </div>
            </div>
            {/*footer */}
            <div id='about' className="bg-[linear-gradient(133deg,_#566270_0%,_#283444_100%)]">
                <div className="max-w-[1490px] mx-auto px-10 py-[120px]">
                    <div className="flex flex-col items-center xl:items-start xl:flex-row xl:justify-between gap-12">
                        <div className="w-full xl:w-auto flex flex-col items-center xl:items-start">
                            <h2 className="text-white text-center xl:text-start font-medium text-3xl lg:text-[32px]">
                                {newsletter}
                            </h2>
                            <div className="relative w-full xs:w-[380px] xl:w-[320px] mt-11 xs:px-4">
                                <input
                                    type="text"
                                    placeholder="Email"
                                    className="rounded-full w-full pl-6 pr-[140px] py-4 bg-custom_blue outline-none text-white text-base
          xs:text-lg placeholder-white"
                                />
                                <div
                                    className="absolute top-2/4 -translate-y-2/4 right-3 bg-[linear-gradient(90deg,_#72BB43_0%,_#FF542C_100%)] px-6 py-2.5 rounded-full
        cursor-pointer text-white xs:mx-2"
                                >
                                    Subscribe
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-12 w-full">
                            <div className="flex flex-col xs:flex-row gap-12 w-full md:w-auto">
                                {footerLinksColumnOne.map((item, index) => (
                                    <div
                                        key={index}
                                        className="text-white max-w-screen-md md:max-w-[340px] flex-1 md:flex-auto"
                                    >
                                        <h6 className="mb-8 text-base lg:text-lg font-bold text-center xl:text-start">
                                            {item.title}
                                        </h6>
                                        <ul className="flex flex-col gap-4">
                                            {item.links.map((link, i) => (
                                                <div
                                                    key={`list-item-${i}`}
                                                    className="w-full flex mx-auto justify-center xl:justify-start"
                                                >
                                                    <li className="font-medium text-sm md:text-base cursor-pointer text-center xl:text-start w-fit">
                                                        {link}
                                                    </li>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>

                            <div className="flex flex-col xs:flex-row gap-12 w-full md:w-auto">
                                {footerLinksColumnTwo.map((item, index) => (
                                    <div
                                        key={index}
                                        className="text-white max-w-screen md:max-w-[340px] flex-1 md:flex-auto"
                                    >
                                        <h6 className="mb-8 text-base lg:text-lg font-bold text-center xl:text-start">
                                            {item.title}
                                        </h6>
                                        <ul className="flex flex-col gap-4">
                                            {item.links.map((link, i) => (
                                                <div
                                                    key={`list-item-${i}`}
                                                    className="w-full flex mx-auto justify-center xl:justify-start"
                                                >
                                                    <li
                                                        className={`font-medium text-sm md:text-base ${index !== 1 && "cursor-pointer"
                                                            } text-center xl:text-start w-fit`}
                                                    >
                                                        {link}
                                                    </li>
                                                </div>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-[1px] bg-white my-12 mt-32" />

                    <h6 className="text-white text-center text-base lg:text-lg">
                        &copy; Yasinta - All rights reserved
                    </h6>
                </div>
            </div>
        </div>
        // <div className="w-full h-screen bg-gradient-to-b from-green-500 to-orange-500 overflow-auto flex items-center justify-center">
        //     <p className="text-5xl font-bold">Klinik Yasinta</p>
        // </div>
    )
}

export default LandingPage