import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons"
import CustomRefPopper from "../../../../components/custom_ref_poper"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { parseISO, isAfter } from 'date-fns'
import Pagination from "../../../../components/pagination"

const RawatJalan = () => {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const userName = localStorage.getItem('user_name')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [openModal, setOpenModal] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)

    let [number, setNumnber] = useState(1)
    let [numberV2, setNumnberV2] = useState(1)

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [category, setCategory] = useState('semua');
    const [sortBy, setSortBy] = useState('created_at');
    const [sortFrom, setSortFrom] = useState('desc');

    const [kunjunganId, setKunjunganId] = useState('');
    const [kunjunganStatus, setKunjunganStatus] = useState('');

    const [keyword, setKeyword] = useState('')
    const [openDropdown, setOpenDropdown] = useState(false)
    const popupRef = useState(null)



    // const fetchKunjungan = async () => {
    //     setLoading(true)
    //     try {
    //         const response = await getKunjungan(accessToken, category, sortBy, sortFrom, currentPage)
    //         setLoading(false)
    //         setRefresh(false)
    //         console.log(response);
    //         switch (response.status) {
    //             case 200:
    //                 setData(response.data.data);
    //                 setTotalData(response.data.meta.total);
    //                 setCurrentPage(response.data.meta.current_page);
    //                 setLastPage(response.data.meta.last_page);

    //                 setNumnber(response.data.meta.current_page);
    //                 setNumnberV2(response.data.meta.current_page);
    //                 break;

    //             case 401:
    //                 navigate('/login')
    //                 break;

    //             case 403:
    //                 // navigate('/403')
    //                 break;

    //             case 404:
    //                 // navigate('/403')
    //                 break;


    //         }

    //     } catch (error) {
    //         alert('silahkan cek internet Anda..!')
    //     }
    // }

    const fetchSearchKunjungan = async () => {
        setLoading(true)
        // try {
        //     const response = await searchKunjungan(accessToken, keyword)
        //     setLoading(false)
        //     setRefresh(false)
        //     console.log(response);
        //     switch (response.status) {
        //         case 200:
        //             setData(response.data.data);
        //             setTotalData(response.data.meta.total);
        //             setCurrentPage(response.data.meta.current_page);
        //             setLastPage(response.data.meta.last_page);

        //             setNumnber(response.data.meta.current_page);
        //             setNumnberV2(response.data.meta.current_page);
        //             break;

        //         case 401:
        //             navigate('/login')
        //             break;

        //         case 403:
        //             // navigate('/403')
        //             break;

        //         case 404:
        //             // navigate('/403')
        //             break;
        //     }

        // } catch (error) {
        //     alert('silahkan cek internet Anda..!')
        // }
    }

    const handleSearch = (e) => {
        e.preventDefault()

        setLoading(true)
        fetchSearchKunjungan(accessToken)
        setLoading(false)
    }

    return (
        <>
            <Helmet>
                <title>Kunjungan Rawat Jalan - KLINIK YASINTA</title>
            </Helmet>
            <Layout>
                <Header>
                    <p>Kunjungan Rawat Jalan</p>
                    <p className="space-x-3">
                        <span>100</span>
                        <span>Pasien</span>
                    </p>
                </Header>

                {/* Sub Header */}
                <div className="w-full py-2 flex items-end justify-between px-5 text-sm space-x-3">
                    {/* search */}
                    <form onSubmit={handleSearch} className="flex items-center relative w-full md:w-fit">
                        <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full lg:w-[220px] h-[40px] outline-none border-2 rounded-l-md px-3 py-2 text-black text-sm" />
                        <button type="submit" className="bg-green-500 px-3 h-[40px] rounded-r-md border-2 border-green-500 text-sm text-white font-semibold">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <CustomRefPopper setOpenPopper={setOpenDropdown} popperRef={popupRef} />

                    {/* Button Filter & Add */}
                    <div className="flex items-center space-x-3 " ref={popupRef}>
                        {userRole === 'admin' || userRole === 'super admin' ?
                            <button type="button" onClick={() => setOpenModal(true)} className="flex items-center bg-custom_green hover:bg-custom_green text-white font-semibold space-x-3 px-3 h-[40px] w-fit rounded-md border-2 border-custom_green">
                                <FontAwesomeIcon icon={faPlus} />
                                <p className="hidden md:flex capitalize">Tambah Kunjungan</p>
                            </button> : ''
                        }
                    </div>
                </div>

                <div className="w-full px-5 text-xs md:text-sm">
                    <div className="w-full hidden md:block overflow-auto rounded-t-xl">
                        <table className="w-full min-w-max max-w-screen-2xl">
                            {/* Header Table */}
                            <thead>
                                <tr className="bg-custom_green text-white">
                                    <th className="px-3 py-2 rounded-tl-xl">No</th>
                                    <th className="px-3 py-2" colSpan={2}>Waktu / Tanggal</th>
                                    <th className="px-3 py-2">Nama Pasien</th>
                                    <th className="px-3 py-2">NIK</th>
                                    <th className="px-3 py-2">No Kunjungan</th>
                                    <th className="px-3 py-2">Kategori</th>
                                    <th className="px-3 py-2">Sudah Dilayani</th>
                                    <th className="px-3 py-2 rounded-tr-xl" colSpan={2}>Aksi</th>
                                </tr>
                            </thead>

                            {/* Body Table */}
                            <tbody>
                                {/* {data.map((item) => (
                                    <tr className="hover:bg-gray-200">
                                        <td className="px-3 py-2 text-center">{number++}</td>
                                        <td className="px-3 py-2 ">
                                            <CustomHour data={item.created_at} />
                                        </td>
                                        <td className="px-3 py-2 ">
                                            <CustomDayName data={item.created_at} />,&nbsp;
                                            <CustomDay data={item.created_at} />-
                                            <CustomMonth data={item.created_at} />-
                                            <CustomYear data={item.created_at} />
                                        </td>
                                        <td className="px-3 py-2">{item.patient?.name ?? '-'}</td>
                                        <td className="px-3 py-2">{item.patient?.nik}</td>
                                        <td className="px-3 py-2 text-center">{item.no_kunjungan}</td>
                                        <td className="px-3 py-2 text-center">{item.category}</td>
                                        <td className="px-3 py-2 text-center">
                                            <p className={`${item.status === 'belum dilayani' ? 'bg-red-600' : item.status === 'sedang dilayani' ? 'bg-yellow-600' : 'bg-green-600'} rounded-full font-semibold text-sm text-white`}>{item.status}</p>
                                        </td>
                                        <td className=" text-white px-3">
                                            <Link to={`/dashboard/kunjungan/detail/${item.id}`}
                                                className="px-3 py-2 mx-auto w-full flex items-center justify-center bg-green-400 hover:bg-green-600 transition-colors rounded-md">
                                                <FontAwesomeIcon icon={faEye} />
                                            </Link>
                                        </td>
                                        <td className=" text-white ">
                                            <button type="button"
                                                onClick={() => {
                                                    if (item.status !== 'sudah dilayani') {
                                                        {
                                                            setOpenModalUpdate(true)
                                                            setKunjunganId(item.id)
                                                            setKunjunganStatus(item.status)
                                                        }
                                                    }
                                                }
                                                }
                                                className="px-3 py-2 w-full bg-yellow-400 hover:bg-yellow-600 transition-colors rounded-md">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                    </tr>
                                ))} */}
                                <tr>
                                    <td className="py-2 px-3 text-center">0</td>
                                    <td className="py-2 px-3" colSpan={11}>Tidak ada data</td>
                                </tr>
                                <Pagination
                                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                                    lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />

                            </tbody>
                        </table>

                        <div className="hidden">{numberV2++}</div>
                        <div className="hidden">{totalData}</div>
                    </div>
                </div>

            </Layout >
        </>
    )
}

export default RawatJalan