import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Header from "../../../../components/header"

const StockOpnameObat = () => {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <Helmet>
                <title>Stok Opname Obat</title>
            </Helmet>
            <Layout
                modal={
                    <>

                    </>
                }

                children={
                    <>
                        <Header children={
                            <>
                                <p className="uppercase">Stok Opname Obat - Klinik Yasinta</p>
                                <div className="flex items-center relative">
                                    <input type="text" className="outline-none border-2 rounded-l-md px-3 py-2 text-black" />
                                    <button type="submit" className="bg-green-500 px-3 py-2 rounded-r-md border-2 border-green-500">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                                <button type="button" onClick={() => setOpenModal(true)} className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p>Import</p>
                                </button>
                            </>
                        } />


                        <div className="w-full px-5 pb-10 mt-3">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-green-500 text-white">
                                        <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                        <th className="px-3 py-2 text-start">Nama Obat</th>
                                        <th className="px-3 py-2 text-start">Harga Faktur</th>
                                        <th className="px-3 py-2 text-start">50%</th>
                                        <th className="px-3 py-2 text-start">Harga Jual / Tablet</th>
                                        <th className="px-3 py-2 text-start">Stok</th>
                                        <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="hover:bg-gray-200 transition-colors">
                                        <td className="px-3 py-2 text-center">1</td>
                                        <td className="px-3 py-2 ">ACYCLOVIR 400MG</td>
                                        <td className="px-3 py-2 ">Rp. 600</td>
                                        <td className="px-3 py-2 ">Rp. 300</td>
                                        <td className="px-3 py-2 ">Rp. 900</td>
                                        <td className="px-3 py-2 ">50 Tablet</td>
                                        <td className="bg-green-400 hover:bg-green-600 transition-colors">
                                            <button type="button" className="px-3 py-2 mx-auto w-full">
                                                <FontAwesomeIcon icon={faEye} />
                                            </button>
                                        </td>
                                        <td className="bg-yellow-400 hover:bg-yellow-600 transition-colors">
                                            <button type="button" className="px-3 py-2 w-full">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                        <td className="bg-red-400 hover:bg-red-600 transition-colors">
                                            <button type="button" className="px-3 py-2 w-full">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="w-full h-10 flex items-center justify-end bg-green-500">

                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default StockOpnameObat