import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import SalaryInvoice from "./salary_invoice"
import { PDFViewer } from "@react-pdf/renderer"
import Header from "../../../../components/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import Pagination from "../../../../components/pagination"
import { useState } from "react"

const SalaryStaff = () => {
    const [openModal, setOpenModal] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    return (
        <>
            <Helmet>
                <title>Gaji Staf - KLINIK YASINTA</title>
            </Helmet>

            <Layout>
                <Header>
                    <p>Gaji Staf</p>
                </Header>

                <div className="w-full grid grid-cols-3 gap-3 p-3">
                    <div className="w-full aspect-video bg-slate-500 rounded-2xl">

                    </div>
                    <div className="w-full aspect-video bg-slate-500 rounded-2xl">

                    </div>
                    <div className="w-full aspect-video bg-slate-500 rounded-2xl">

                    </div>
                    <div className="w-full aspect-video bg-slate-500 rounded-2xl">

                    </div>
                </div>

                {/* <div className="w-full px-5 pb-10">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl w-20">No</th>
                                <th className="px-3 py-2 text-start">Jabatan</th>
                                <th className="px-3 py-2 text-text-center">Gaji Pokok</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover:bg-gray-200 transition-colors">
                                <td className="px-3 py-2 text-center">1</td>
                                <td className="px-3 py-2 ">Dokter</td>
                                <td className="px-3 py-2 text-center">Rp. 1500.000</td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>

                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={11} />
                        </tbody>
                    </table>
                </div> */}
                {/* <PDFViewer className="w-full h-full">
                    <SalaryInvoice/>
                </PDFViewer> */}
            </Layout>
        </>
    )
}

export default SalaryStaff