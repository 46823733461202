import { useHref, useNavigate, useParams } from "react-router-dom"
import BackButton from "../../../../../components/buttons/back_button"
import Header from "../../../../../components/header"
import Layout from "../../../../../components/layouts"
import { Document, Image, PDFViewer, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import Logo from '../../../../../assets/icons/logo_yasinta.png'
import { useState } from "react"
import { showPengadaanObat } from "../../../../../services/pengadaan_obat_service"
import CustomDayName from "../../../../../components/custom_day_name"
import CustomDay from "../../../../../components/custom_day"
import CustomMonth from "../../../../../components/custom_month"
import CustomYear from "../../../../../components/custom_year"
import { useEffect } from "react"
import { parseISO, isAfter, getMonth, getYear } from "date-fns"
import CustomHour from "../../../../../components/custom_hour"

const PrintSuratPengadaanObat = () => {
    const path = useHref()
    const pathParts = path.split('/');
    const backLink = pathParts.slice(0, -1).join('/');
    const { id } = useParams()

    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const navigate = useNavigate()
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let number = 1
    const [data, setData] = useState(null)

    const fetchData = async () => {
        setLoading(true)
        const response = await showPengadaanObat(accessToken, id)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'farmasi'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [path, navigate]);

    const getDayName = (data) => {
        const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'kamis', "Jum'at", 'Sabtu'];
        const dayIndex = new Date(data).getDay();
        return days[dayIndex];
    };

    const getDay = (data) => {
        return new Date(data).getDate();
    }

    const getMonth = (data) => {
        return new Intl.DateTimeFormat('id-ID', { month: 'long' }).format(new Date(data));
    }

    const getYear = (data) => {
        return new Date(data).getFullYear();
    }

    return (
        <>
            <Layout>
                <Header>
                    <BackButton link={backLink} />
                    <p>Surat Pengadaan Obat</p>
                </Header>
                <div className="w-full h-full flex items-center justify-center -mt-16 pt-16">
                    {loading === true ? 'Loading....' :
                        <PDFViewer className="w-full h-full">
                            <Document>
                                <Page size={'B5'} style={{ paddingBottom: '20px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px' }} >
                                    <View style={{ flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', marginBottom: '10px', borderBottom: '1px', paddingBottom: '5px' }}>
                                        <Text style={{ fontSize: '12pt', }}>SURAT PESANAN</Text>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ fontSize: '10pt' }}>Kode Pesanan</Text>
                                            <Text style={{ fontSize: '10pt', paddingRight: '5px', paddingLeft: '5px' }}>:</Text>
                                            <Text style={{ fontSize: '10pt' }}>{data?.code}</Text>
                                        </View>
                                    </View>
                                    <View style={{ marginBottom: '5px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingRight: '30px' }}>
                                        <Image src={Logo} style={{ height: '60px', width: '60', objectFit: 'content' }} />
                                        <View style={{ display: 'flex', flexDirection: 'col', alignItems: 'center', justifyContent: 'center' }}>
                                            <Text style={{ textAlign: 'center', fontSize: '16pt' }}>KLINIK YASINTA</Text>
                                            <Text style={{ textAlign: 'center', fontSize: '8pt', fontWeight: 'bold' }} >Jl. Raya Bayongbong Kp. Muara 01/10 No. 4 Muara Sanding - Garut</Text>
                                        </View>
                                    </View>

                                    {/* <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px', width: '70px' }}>Waktu / Tanggal</Text>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px', paddingRight: '5px' }}>:</Text>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px' }}>Senin</Text>
                            </View> */}
                                    {/* <View style={{ flexDirection: 'row' }}>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px', width: '70px' }}>Kode Pesanan</Text>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px', paddingRight: '5px' }}>:</Text>
                                <Text style={{ fontSize: '8pt', marginBottom: '5px' }}>ASGJDSDLK</Text>
                            </View> */}

                                    {/* Tablel */}
                                    <View>
                                        {/* Header */}
                                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', border: '1px', backgroundColor: '#d1d5db' }}>
                                            <Text style={{ fontSize: '10pt', padding: '5px', borderRight: '1px', width: '40px', textAlign: 'center' }}>NO</Text>
                                            <Text style={{ fontSize: '10pt', padding: '5px', borderRight: '1px', width: '200px', textAlign: 'center' }}>NAMA BARANG</Text>
                                            <Text style={{ fontSize: '10pt', padding: '5px', borderRight: '1px', width: '80px', textAlign: 'center' }}>JUMLAH</Text>
                                            <Text style={{ fontSize: '10pt', padding: '5px', width: '150px', textAlign: 'center' }}>KETERANGA</Text>
                                        </View>

                                        {/* Body */}
                                        {
                                            data?.item_pengadaan.map((item, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                        borderLeft: '1px',
                                                        borderRight: '1px',
                                                        borderBottom: '1px',
                                                        // borderBottomWidth: index === 20 - 1 ? 1 : 0,
                                                    }}>
                                                    <Text style={{ fontSize: '8pt', padding: '5px', borderRight: '1px', width: '40px', textAlign: 'center' }}>
                                                        {number++}
                                                    </Text>
                                                    <Text style={{ fontSize: '8pt', padding: '5px', borderRight: '1px', width: '200px' }}>
                                                        {item?.obat.name}
                                                    </Text>
                                                    <Text style={{ fontSize: '8pt', padding: '5px', borderRight: '1px', width: '80px', textAlign: 'center' }}>
                                                        {item.quantity}
                                                    </Text>
                                                    <Text style={{ fontSize: '8pt', padding: '5px', width: '150px' }}>
                                                        {item.note}
                                                    </Text>
                                                </View>
                                            ))
                                        }

                                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                            <Text style={{ fontSize: '8pt', padding: '5px', width: '150px' }}>
                                                Garut, &nbsp;
                                                {data?.created_at ? getDayName(data?.created_at) : ''}, &nbsp;
                                                {data?.created_at ? getDay(data?.created_at) : ''}-
                                                {data?.created_at ? getMonth(data?.created_at) : ''}-
                                                {data?.created_at ? getYear(data?.created_at) : ''}
                                               
                                            </Text>
                                        </View>

                                    </View>
                                </Page>
                            </Document>
                        </PDFViewer>
                    }
                </div>
            </Layout>
        </>
    )
}

export default PrintSuratPengadaanObat