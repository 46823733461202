import apiService from "./api_service"

export const getWeddingStatus = async () => {
    try {
        const response = await apiService.get(`wedding-status`, {
            headers: {
                // Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}