import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useState } from "react"

const ModalUpdateResepList = ({ openModal, setOpenModal, setRefresh, data, setData, index, }) => {
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [invoicePrice, setInvoicePrice] = useState('')
    const [fixedPrice, setFixdPrice] = useState('')

    const editData = (index, newData) => {
        // Buat array baru dengan data yang diubah
        const newList = [...data];
        newList[index] = newData;
        // Update state dengan array baru
        setData(newList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setOpenModal(false)
        setRefresh(true)

        editData(index, { ...data, name: 'INI ADALAH OBAT', quantity: 50 })

    }


    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[450px] lg:h-[550px] delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>
                <button
                    type="button"
                    onClick={() => { setOpenModal(false) }}
                    className="text-red-700 bg-red-200 w-fit aspect-square rounded-full absolute top-5 right-5 hover:bg-red-300 transition-colors">
                    <FontAwesomeIcon icon={faClose} className="w-10"></FontAwesomeIcon>
                </button>

                <div className="w-full text-center border-b-2 pt-12 pb-3 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Obat</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-3 space-y-3 px-3">
                    <div className="w-full">
                        <label htmlFor="category" className="block">Nama Obat</label>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="category" className="block">Harga Faktur</label>
                        <input type="number" value={invoicePrice} onChange={(e) => setInvoicePrice(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="category" className="block">Harga Jual / Tablet</label>
                        <input type="number" value={fixedPrice} onChange={(e) => setFixdPrice(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                        {loading === true ? <>
                            <div className="spinner-wave ">
                                <div className="spinner-wave-dot  bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                            </div>
                        </> : 'SIMPAN'}
                    </button>
                </form>

                <div className="w-full text-center border-t-2 pt-3 pb-12 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalUpdateResepList