import { Link, useHref } from "react-router-dom"

const NavLinkItem = ({ version, link, name }) => {
    const currentLink = useHref()
    return (
        <>
            {version === 2 ?
                <a
                    href={link}
                    className={`w-full py-2 px-3 hover:bg-orange-600 rounded-full transition-colors hover:text-white font-semibold capitalize ${currentLink.startsWith(link) ? 'bg-custom_green text-white' : 'bg-white'}`} >
                    {name}
                </a>
                :
                <Link
                    to={link}
                    className={`w-full py-2 px-3 hover:bg-orange-600 rounded-full transition-colors hover:text-white font-semibold capitalize ${currentLink.startsWith(link) ? 'bg-custom_green text-white' : 'bg-white'}`} >
                    {name}
                </Link>
            }
        </>

    )
}

export default NavLinkItem