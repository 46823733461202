import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { faChevronCircleLeft, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, useHref, useNavigate } from "react-router-dom"
import { useState } from "react"
import { useEffect } from "react"
import { getDrugs } from "../../../../services/drug_service"
import { postPengadaanObat } from "../../../../services/pengadaan_obat_service"
import { getDrugSupliers } from "../../../../services/drug_suplier_service"

const PengadaanBaru = () => {
    const navigate = useNavigate()
    const path = useHref()
    const pathParts = path.split('/');
    const backLink = pathParts.slice(0, -1).join('/');

    const accessToken = localStorage.getItem('access_token')
    const [loading, setLoading] = useState(false)
    let number = 1
    const [dataObat, setDataObat] = useState([])
    const [dataSuplier, setDataSuplier] = useState([])
    const [suplierId, setSuplierId] = useState('')

    const [items, setItems] = useState([
        {
            obat_id: '',
            quantity: '',
            note: ''
        }
    ]);

    const [errors, setErrors] = useState([]);

    const fetchDrugs = async () => {
        const response = await getDrugs(accessToken)
        if (response.status === 200) {
            setDataObat(response.data.data)
        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
    }

    const fetchDrugSupliers = async () => {
        const response = await getDrugSupliers(accessToken)
        console.log(response);
        if (response.status === 200) {
            setDataSuplier(response.data.data)
        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
    }

    useEffect(() => {
        fetchDrugs()
        fetchDrugSupliers()
    }, [])

    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const newItems = [...items];
        newItems[index][name] = value;
        setItems(newItems);
    };

    const handleAddItem = () => {
        setItems([...items, { obat_id: '', quantity: '', note: '' }]);
    };

    const handleDeleteItem = (index) => {
        if (items.length > 1) {
            setItems(items.filter((_, i) => i !== index));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const newErrors = [];
        let valid = true;

        items.forEach((item, index) => {
            let errorMessage = '';
            if (!item.obat_id) {
                errorMessage = 'Nama obat harus diisi!';
                valid = false;
            } else if (!item.quantity) {
                errorMessage = 'jumlah harus diisi!';
                valid = false;
            }
            newErrors[index] = errorMessage;
        });

        setErrors(newErrors);

        if (valid) {
            const body = {
                'suplier_id' : suplierId,
                "items" : items.map(item => ({
                    obat_id: item.obat_id,
                    quantity: item.quantity,
                    note: item.note
                }))
            }
            const response = await postPengadaanObat(accessToken, body)
            console.log(response);
            if (response.status === 200) {
                navigate(backLink)
            } else if (response.status === 403) {
                navigate(`/403?back=${path}`)
            } else {
                alert('silahkan perikas koneksi internet Anda..!')
            }
        } else {
            console.log("Form has errors");
        }


        // console.log(items);
        setLoading(false)
    }

    return (
        <>
            <Helmet>
                <title>Pengadaan Obat Baru</title>
            </Helmet>
            <Layout>
                <form onSubmit={handleSubmit} className="">
                    <Header>
                        <Link to="/dashboard/obat/pengadaan" className="flex items-center space-x-3 px-3 py-2" >
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                            <p>Kembali</p>
                        </Link>
                        <p>Pengadaan Obat Baru</p>
                        <button type="submit" className="flex items-center space-x-3 px-3 py-2 bg-custom_green rounded-md hover:bg-green-600 transition-colors" >
                            <FontAwesomeIcon icon={faSave} />
                            <p>Simpan</p>
                        </button>
                    </Header>
                    <div className="space-y-3 p-3">

                        <div className="w-full flex items-center justify-between">
                            <button
                                type="button"
                                onClick={handleAddItem}
                                className="px-3 py-2 bg-custom_green rounded-md text-white font-semibold hover:bg-green-500 transition-colors">
                                Tambah Item
                            </button>
                            <select
                                value={suplierId}
                                onChange={(e) => setSuplierId(e.target.value)}
                                className="outline-none border-2 focus:border-custom_green px-3 py-1 rounded-md"
                            >
                                <option value="">Pilih Suplier</option>
                                {dataSuplier.map((item) => (
                                    <option value={item.id}>{item.company_name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full overflow-auto">
                            <table className="w-full min-w-max max-w-screen-xl text-sm">
                                <thead>
                                    <tr className="bg-custom_green text-white">
                                        <th className="px-3 py-2 rounded-tl-xl">Aksi</th>
                                        <th className="px-3 py-2 ">No</th>
                                        <th className="px-3 py-2 ">Nama Obat</th>
                                        <th className="px-3 py-2 ">Jumlah</th>
                                        <th className="px-3 py-2 rounded-tr-xl">Keterangan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={index}>
                                            <td className="px-3 py-2 text-center">
                                                <button type="button"
                                                    onClick={() => handleDeleteItem(index)}
                                                    className="bg-red-500 hover:bg-red-600 transition-colors px-3 py-1 rounded-md text-white"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2 ">
                                                <select
                                                    name="obat_id"
                                                    value={item.obat_id}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    className="outline-none border-2 focus:border-custom_green px-3 py-1 rounded-md"
                                                >
                                                    <option value="">Pilih Obat</option>
                                                    {dataObat.map((item) => (
                                                        <option value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                                {errors[index] && errors[index].includes('obat') && <p className="text-red-500 text-xs mt-1">{errors[index]}</p>}
                                            </td>
                                            <td className="px-3 py-2 text-center">
                                                <input
                                                    type="number" name="quantity"
                                                    value={item.quantity}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    className="outline-none border-2 focus:border-custom_green px-3 py-1 rounded-md" />
                                                {errors[index] && errors[index].includes('Quantity') && <p className="text-red-500 text-xs mt-1">{errors[index]}</p>}
                                            </td>
                                            <td className="px-3 py-2 rounded-tr-xl">
                                                <input
                                                    type="text" name="note"
                                                    value={item.note}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    className="w-full outline-none border-2 focus:border-custom_green px-3 py-1 rounded-md" />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="py-3 bg-custom_green" colSpan={5}></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default PengadaanBaru