import { useState } from "react"
import { loginService } from "../../services/auth_service"

import Doctor from '../../assets/images/doctor.png'
import LogoYasinta from '../../assets/icons/logo_yasinta.png'

import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRotateBack, faEye } from "@fortawesome/free-solid-svg-icons"

const Login = () => {
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [showPassword, setShowPssword] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            const response = await loginService(email, password)
            console.log(response);
            localStorage.setItem('user_name', response.data.name)
            localStorage.setItem('user_role', response.data.user_role)
            localStorage.setItem('access_token', `Bearer ${response.data.token}`)
            localStorage.setItem('access_token2', `Bearer ${response.data.token2?.access_token}`)
            localStorage.setItem('expired_token', response.data.expired_at)
            setLoading(false)
            if (response.status === 200) {
                navigate('/dashboard')
            }else if (response.status === 401) {
                alert('login gagal, email atau password salah..!')
            }else{
                alert('Silahkan cek koneksi internet Anda..!')
            }
        } catch (error) {
            setLoading(false)
            alert('silahkan periksa koneksi internet Anda..!')
        }

    }

    const handleShowPassword = () => {
        if (showPassword === false) {
            setShowPssword(true)
        } else {
            setShowPssword(false)
        }
    }

    return (

        <div className="w-full h-screen flex items-center justify-center bg-gradient-to-bl from-custom_green to-custom_orange px-5">
            <div className="w-full flex md:flex-col lg:flex-row items-center justify-center bg-white rounded-xl md:space-y-5 lg:space-y-0 md:py-10 lg:pr-10">
                <div className="w-1/2 hidden md:flex flex-col items-center justify-center space-y-5">
                    <img src={Doctor} alt="Dokter" className='md:w-[70%] lg:w-1/2 ' />
                    <Link to='/' className=" items-center justify-center md:hidden lg:flex text-black hover:text-custom_orange transition-colors font-semibold space-x-3" >
                        <FontAwesomeIcon icon={faArrowRotateBack} />
                        <p>Back Home</p>
                    </Link>
                </div>
                <div className="w-full md:w-[70%] lg:w-1/2 px-5 py-10 space-y-3 md:shadow-md md:border md:rounded-2xl">
                    <div className="border-b-2 border-custom_green mb-6 pb-3 flex items-center justify-center flex-col">
                        <img src={LogoYasinta} alt="asset-klinik-yasinta dokter" className="w-[100px]" />
                        <h1 className='text-custom_green font-bold text-3xl'>Klinik Yasinta</h1>
                    </div>
                    <form onSubmit={handleLogin} className='w-full space-y-3'>
                        <div className="w-full flex flex-col">
                            <label htmlFor="email" className="font-semibold">Email</label>
                            <input
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md"
                            />
                        </div>

                        <div className="w-full flex flex-col">
                            <label htmlFor="password" className="font-semibold">Password</label>
                            <div className="relative flex items-center">
                                <input
                                    id="password"
                                    type={showPassword === false ? 'password' : 'text'}
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    showVisibilityIcon
                                    className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md w-full"
                                />
                                <button type="button" onClick={handleShowPassword} className={`absolute right-5 hover:text-custom_green  ${showPassword === true ? 'text-custom_green' : 'text-black'}`}>
                                    <FontAwesomeIcon icon={faEye} />
                                </button>
                            </div>
                        </div>

                        {/* <Link className='flex items-end justify-end hover:text-custom_blue font-bold'>Lupa Password</Link> */}

                        <div className='mt-4'>
                            <button type='submit' className='flex bg-custom_green rounded-full focus:outline-none w-full h-10 px-3 font-bold text-white hover:bg-custom_orange hover:text-custom_white items-center justify-center transition-colors'>
                                {loading === true ? <>
                                    <div className="spinner-wave ">
                                        <div className="spinner-wave-dot  bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                    </div>
                                </> : 'Masuk'}
                            </button>
                        </div>
                    </form>
                    <Link to='/' className="lg:hidden flex flex-row items-center justify-center text-black hover:text-custom_orange transition-colors font-semibold space-x-3" >
                        <FontAwesomeIcon icon={faArrowRotateBack} />
                        <p>Back Home</p>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Login