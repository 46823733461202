import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { postRoom } from "../../../../services/room_service"
import CustomInputV2 from "../../../../components/custom_input_v2"

const ModalAddRuangan = ({ accessToken, openModal, setOpenModal, setRefresh }) => {
    const accessTokenV2 = localStorage.getItem('access_token2')
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState('')
    const [roomCost, setRoomCost] = useState(0)
    const [capacity, setCapacity] = useState(1)
    const [description, setDescription] = useState('')

    const [errorName, setErrorName] = useState(null)
    const [errorRoomCost, setErrorRoomCost] = useState(null)
    const [errorRoomCapacity, setErrorRoomCapacity] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData()
        formData.append('name', name)
        formData.append('room_cost', roomCost)
        formData.append('capacity', capacity)
        formData.append('description', description)
        formData.append('token_v2', accessTokenV2 )

        const response = await postRoom(accessToken, formData)
        // console.log(response);
        if (response.status === 200) {
            setRefresh(true)
            setOpenModal(false)
        } else {
            alert('ada yang salah')
        }
        setLoading(false)
    }


    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full max-h-[90%] delay-200' : 'w-0 max-h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 flex items-center justify-center w-full text-center h-16 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Ruangan</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full py-[70px] space-y-3 px-3">
                    <CustomInputV2
                        type={'text'}
                        id={'name'} name={`Nama Ruangan`}
                        value={name} setValue={setName}
                        placeholder="nama ruangan"
                        errorMessage={errorName}
                    />

                    <CustomInputV2
                        type={'number'}
                        id={'roomCost'} name={`Biaya Ruangan / Hari`}
                        value={roomCost} setValue={setRoomCost}
                        errorMessage={errorRoomCost}
                    />

                    <CustomInputV2
                        type={'number'}
                        id={'capacity'} name={`Kapasitas Ruangan`}
                        value={capacity} setValue={setCapacity}
                        errorMessage={errorRoomCapacity}
                    />

                    <div className="w-full">
                        <label htmlFor="description" className="block">Deskripsi</label>
                        <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>

                    <div className="w-full flex space-x-3">

                        <button type="button"
                            onClick={() => { setOpenModal(false) }}
                            className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white transition-colors">
                            Kembali
                        </button>
                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 hover:bg-green-600 py-2 rounded-md font-semibold text-white transition-colors">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="absolute bottom-0 h-16 flex items-center justify-center w-full text-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalAddRuangan