const LoadingBarang = () => {
    return (
        <tr className="animate-pulse">
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
            <td className="px-3 py-2">
                <div className="py-2 w-full rounded-full bg-gray-300"></div>
            </td>
        </tr>
    )
}

export default LoadingBarang