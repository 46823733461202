import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Layout from "../../../../../components/layouts"
import Header from "../../../../../components/header"
import Pagination from "../../../../../components/pagination"

const Penggajihan = () => {
    const [openModal, setOpenModal] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)
    return (
        <>
            <Helmet>
                <title>Penggajihan - KLINIK YASINTA</title>
            </Helmet>

            <Layout>
                <Header>
                    <p>Penggajihan</p>
                </Header>

                <div className="w-full px-5 pb-10">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl w-20">No</th>
                                <th className="px-3 py-2 text-center">Jumlah Staf</th>
                                <th className="px-3 py-2 text-center">Sudah Digaji</th>
                                <th className="px-3 py-2 text-center">Belum Digaji</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="hover:bg-gray-200 transition-colors">
                                <td className="px-3 py-2 text-center">1</td>
                                <td className="px-3 py-2 text-center">80</td>
                                <td className="px-3 py-2 text-center">80</td>
                                <td className="px-3 py-2 text-center">80</td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                </td>
                                <td className="  px-1 py-2 h-full w-16">
                                    <button type="button" className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>

                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={11} />
                        </tbody>
                    </table>
                </div>
            </Layout>
        </>
    )
}

export default Penggajihan