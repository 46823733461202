import LogoutButton from "../../logout"
import NavLinkItem from "../../nav_link_item"
import CustomeAccording from "../../according"

const ContentMenuSuperAdmin = () => {
    return (
        <>

            <NavLinkItem link="/dashboard/index" name="Dashboard" />
            <NavLinkItem link="/dashboard/staf" name="Data Staf" />

            <NavLinkItem link="/dashboard/kunjungan" name="Kunjungan Pasien" />

            {/* <CustomeAccording link="/dashboard/kunjungan" name="Kunjungan Pasien" height="h-24">
                <NavLinkItem link="/dashboard/kunjungan/rawat-jalan" name="Rawat Jalan" />
                <NavLinkItem link="/dashboard/kunjungan/rawat-inap" name="Rawat inap" />
            </CustomeAccording> */}

            <NavLinkItem link="/dashboard/pasien" name="Pasien" />
            <NavLinkItem link="/dashboard/ruangan" name="Ruangan" />


            <CustomeAccording link="/dashboard/obat" name="Data Obat" height="h-[180px]">
                <NavLinkItem link="/dashboard/obat/pengadaan" name="Pengadaan Obat" />
                <NavLinkItem link="/dashboard/obat/suplayer" name="Suplayer Obat" />
                <NavLinkItem link="/dashboard/obat/monitoring" name="Monitoring Obat" />
                {/* <NavLinkItem link="/dashboard/obat/stok-opname" name="Stok Opname Obat" /> */}
                <NavLinkItem link="/dashboard/obat/resep" name="Resep / Penjualan" />
            </CustomeAccording>

            <CustomeAccording
                link="/dashboard/inventory" name="Inventory"
                height="h-24"
                children={
                    <>
                        <NavLinkItem link="/dashboard/inventory/pengadaan-barang" name="Pengadaan Barang" />
                        <NavLinkItem link="/dashboard/inventory" name="Monitoring Barang" />
                    </>
                }
            />

            <CustomeAccording link="/dashboard/keuangan" name="Keuangan" height="h-[135px]">
                {/* <NavLinkItem link="/dashboard/keuangan/salary-staff" name="Gaji Staf" />
                <NavLinkItem link="/dashboard/keuangan/penggajihan" name="Penggajihan" /> */}
                <NavLinkItem link="/dashboard/keuangan/tarif-layanan" name="Tarif Layanan" />
                <NavLinkItem link="/dashboard/keuangan/pemasukan" name="Pemasukan" />
                <NavLinkItem link="/dashboard/keuangan/pengeluaran" name="Pengeluaran" />
            </CustomeAccording>

            <LogoutButton />
        </>
    )
}

export default ContentMenuSuperAdmin