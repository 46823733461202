import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import ModalAddSuplayer from "./modals/add"
import Header from "../../../../components/header"
import { getDrugSupliers } from "../../../../services/drug_suplier_service"
import Pagination from "../../../../components/pagination"

const SuplayerObat = () => {
    const [openModal, setOpenModal] = useState(false)
    const [refresh, setRefresh] = useState(false)


    let [number, setNumber] = useState(1)
    // let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)

    const fetchDrugSupliers = async () => {
        const response = await getDrugSupliers(localStorage.getItem('access_token'))
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setNumber(response.data.meta.from)
            // setNumberV2(response.data.meta.from)

        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
    }
    useEffect(() => {

    }, [refresh])

    useEffect(() => {
        fetchDrugSupliers()
    }, [refresh])


    return (
        <>
            <Helmet>
                <title>Suplayer Obat</title>
            </Helmet>
            <Layout
                modal={
                    <>
                        <ModalAddSuplayer openModal={openModal} setOpenModal={setOpenModal} setRefresh={setRefresh} />
                    </>
                }
            >
                <Header>
                    <p className="uppercase">Suplayer Obat - Klinik Yasinta</p>
                    <p className="space-x-3">
                        <span className="text-xl">{totalData}</span>
                        <span>Suplayer</span>
                    </p>
                </Header>

                <div className="w-full flex items-center justify-end px-5 py-3">
                    <button type="button" onClick={() => setOpenModal(true)} className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                        <FontAwesomeIcon icon={faPlus} />
                        <p>Tambah Suplayer</p>
                    </button>
                </div>

                <div className="w-full px-5 pb-10">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                <th className="px-3 py-2 text-start">Nama Perusahaan</th>
                                <th className="px-3 py-2 text-start">Nama Penanggung Jawab</th>
                                <th className="px-3 py-2 text-start">No Telepon</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr className="hover:bg-gray-200 transition-colors">
                                    <td className="px-3 py-2 text-center">{number++}</td>
                                    <td className="px-3 py-2 ">{item.company_name}</td>
                                    <td className="px-3 py-2 ">{item.person_responsible}</td>
                                    <td className="px-3 py-2 ">{item.phone_number}</td>
                                    <td className="px-1">
                                        <button type="button" className="px-3 py-1 mx-auto w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                            <FontAwesomeIcon icon={faEye} />
                                        </button>
                                    </td>
                                    <td className="px-1">
                                        <button type="button" className="px-3 py-1 w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                    </td>
                                    <td className="px-1">
                                        <button type="button" className="px-3 py-1 w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </td>
                                </tr>
                            ))}

                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={11} />
                        </tbody>
                    </table>
                </div>
            </Layout>
        </>
    )
}

export default SuplayerObat