import { text } from "@fortawesome/fontawesome-svg-core"

const CustomInput = ({ type, id, name, subName, value, setValue, errorMessage, customStyle }) => {
    return (
        <div className="w-full flex flex-col">
            <label htmlFor={id} className={`uppercase font-semibold text-xs md:text-sm  space-x-3`}>
                <span>{name}</span>
                <span className="lowercase">{subName}</span>
                {errorMessage === null || errorMessage === '' ? '' : <span className="text-red-500 text-xs lowercase">{errorMessage}</span>}
            </label>
            <input id={id} type={type ?? "text"} value={value} onChange={(e) => setValue(e.target.value)} className={`${customStyle} w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md`} />
        </div>
    )
}

export default CustomInput