import apiService from "./api_service"

export const showDiagnosa = async (accessToken, diagnosaId) => {
    try {

        const response = await apiService.get(`/diagnosa/show/${diagnosaId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postDiagnosa = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/diagnosa/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}
