const NotFoundPage = () => {
    return (
        <div className="w-full h-screen flex items-center justify-center">
            <div className="text-center">
                <p>NOT FOUND..!</p>
                <p>Halaman tidak ditemukan..!</p>
            </div>
        </div>
    )
}

export default NotFoundPage