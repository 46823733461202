import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"

const RawatInap = () => {
    return (
        <>
            <Helmet>
                <title>Rawat Inap - KLINIK YASINTA</title>
            </Helmet>
            <Layout>
                Rawat Inap
            </Layout>
        </>
    )
}

export default RawatInap