import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import Layout from "../../../../components/layouts"
import { Helmet } from "react-helmet"
import Header from "../../../../components/header"
import { getFinances } from "../../../../services/finance_service"
import { useHref, useNavigate } from "react-router-dom"
import { parseISO, isAfter } from "date-fns"
import Pagination from "../../../../components/pagination"
import CustomCurrency from "../../../../components/custom_currency"
import CustomHour from "../../../../components/custom_hour"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"

const Pemasukan = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [orderBy, setOrderBy] = useState('created_at');
    const [sortBy, setSortBy] = useState('asc');

    let [number, setNumber] = useState(1);
    // let [numberV2, setNumberV2] = useState(1);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchData = async () => {
        setLoading(true)
        const response = await getFinances(accessToken, 'pemasukan', currentPage)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'farmasi'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [currentPath, navigate]);

    const calculateTotalPengeluaran = () => {
        return data.reduce((total, item) => total + item.total_price, 0);
    }
    return (
        <>
            <Helmet>
                <title>Pengeluaran - Klinik Yasinta</title>
            </Helmet>
            <Layout>
                <Header>
                    <p className="uppercase">Laporan Pengeluaran - Klinik Yasinta</p>
                </Header>

                <div className="w-full py-3 px-3 md:px-5">
                    <div className="flex items-center space-x-3 font-semibold">
                        <p>Total Pengeluaran</p>
                        <p>:</p>
                        <p className="text-xl">
                            <CustomCurrency number={calculateTotalPengeluaran()} />
                        </p>
                    </div>
                </div>

                <div className="w-full px-5 pb-10">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                <td className="px-3 py-2 " colSpan={2}>Waktu & Tanggal</td>
                                <th className="px-3 py-2 text-start">Kategori</th>
                                <th className="px-3 py-2 text-start">Nama Pengeluaran</th>
                                <th className="px-3 py-2 text-start">Pengeluaran</th>
                                <th className="px-3 py-2 text-start">Keterangan</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading === true ? '' :
                                totalData === 0 ? 
                                <tr>
                                    <td className="text-center px-3 py-1">0</td>
                                    <td className="px-3 py-1">tidak ada pemasukan</td>
                                </tr> :
                                    data.map((item) => (
                                        <tr className="hover:bg-gray-200 transition-colors">
                                            <td className="px-3 py-2 text-center">{number}</td>
                                            <td className="px-3 py-2 ">
                                                <CustomHour data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2 ">
                                                <CustomDayName data={item.created_at} />,&nbsp;
                                                <CustomDay data={item.created_at} />-
                                                <CustomMonth data={item.created_at} />-
                                                <CustomYear data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2 ">{item.category}</td>
                                            <td className="px-3 py-2 ">{item.name}</td>
                                            <td className="px-3 py-2 ">
                                                <CustomCurrency number={item.total_price} />
                                            </td>
                                            <td className="px-3 py-2 ">
                                                {item.note ?? '-'}
                                            </td>
                                            <td className="py-1 px-1">
                                                <button type="button" className="px-3 py-1 mx-auto w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </td>
                                            <td className="py-1 px-1">
                                                <button type="button" className="px-3 py-1 w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>
                                            <td className="py-1 px-1">
                                                <button type="button" className="px-3 py-1 w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                            }

                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />
                        </tbody>
                    </table>
                </div>
            </Layout>
        </>
    )
}

export default Pemasukan