import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useState } from "react"
import CustomInputV2 from "../../../../../components/custom_input_v2"
import CustomCurrency from "../../../../../components/custom_currency"
import { postResepPayment } from "../../../../../services/resep_payment_service"

const ModalPaymentResep = ({ idResep, totalBayar, openModal, setOpenModal, setRefresh, data, setData, index, }) => {
    const accessToken = localStorage.getItem('access_token')
    const [loading, setLoading] = useState(false)
    const [jumlahUang, setJumlahUang] = useState(0)
    const [kembalian, setKembalian] = useState(0)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const formData = new FormData()
        formData.append('resep_id', idResep)
        formData.append('total_bayar', totalBayar)
        formData.append('uang_pembayaran', jumlahUang)

        const response = await postResepPayment(accessToken, formData)
        console.log(response);
        if (response.status === 200) {
            setOpenModal(false)
            setRefresh(true)
        } else {
            alert('ada kesalahan')
        }

        setLoading(true)
    }


    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full max-h-[90%] delay-200' : 'w-0 max-h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 h-16 w-full text-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Pembayaran Resep</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-[70px] space-y-3 px-3">
                    <div className="w-full flex items-center justify-between">
                        <div className="w-[170px] font-semibold">Total Pembayaran</div>
                        <div
                            className="w-full outline-none border-2 focus:border-custom_green rounded-md transition-colors text-end px-3 py-2">
                            <CustomCurrency number={totalBayar} />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-[170px] font-semibold">Uang Pembayaran</div>
                        <div className="w-full">
                            <input type="number"
                                value={jumlahUang}
                                onChange={(e) => {
                                    setJumlahUang(e.target.value)
                                    setKembalian(e.target.value - totalBayar)
                                }}
                                className="w-full outline-none border-2 focus:border-custom_green rounded-md transition-colors text-end px-3 py-2" />
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-between">
                        <div className="w-[170px] font-semibold">Uang Kembalian</div>
                        <div
                            className="w-full outline-none border-2 focus:border-custom_green rounded-md transition-colors text-end px-3 py-2">
                            <CustomCurrency number={kembalian} />
                        </div>
                    </div>

                    <div className="w-full flex items-center space-x-3">
                        <button type="button" onClick={() => setOpenModal(false)} className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white transition-colors">
                            Kembali
                        </button>

                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 hover:bg-green-600 py-2 rounded-md font-semibold text-white transition-colors">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="absolute bottom-0 w-full text-center h-16 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalPaymentResep