const CustomDayName = ({ data }) => {
    const getDayName = (data) => {
        const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'kamis', "Jum'at", 'Sabtu'];
        const dayIndex = new Date(data).getDay();
        return days[dayIndex];
    };

    return <span>{data === undefined || data === null ? '' : getDayName(data)}</span>;
};

export default CustomDayName;
