const CustomSprinner = () => {
    return (
        <div className="spinner-wave ">
            <div className="spinner-wave-dot  bg-white"></div>
            <div className="spinner-wave-dot bg-white"></div>
            <div className="spinner-wave-dot bg-white"></div>
            <div className="spinner-wave-dot bg-white"></div>
        </div>
    )
}

export default CustomSprinner