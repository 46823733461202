import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import BackButton from "../../../../components/buttons/back_button"
import { useHref, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { showPengadaanBarang } from "../../../../services/pengadaan_barang_service"
import { parseISO, isAfter } from "date-fns"
import CustomHour from "../../../../components/custom_hour"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomCurrency from "../../../../components/custom_currency"

const PengadaanBarangDetail = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const { id } = useParams()
    const linkBack = currentPath.split('/detail')[0]

    const [loading, setLoading] = useState(true)
    let number = 1

    const [data, setData] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    const fetchData = async () => {
        setLoading(true)
        const response = await showPengadaanBarang(accessToken, id)
        console.log(response.data);
        switch (response.status) {
            case 200:
                setData(response.data.data)
                break;

            case 404:
                setData([])
                setErrorMessage('Data tidak di temukan..!')
                break;

            default:
                alert('Silahkan periksa koneksi internet Anda..!')
                break;
        }
        setLoading(false)
    }

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'admin'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [currentPath, navigate]);

    const Row = ({ name, data }) => {
        return (
            <tr>
                <td className="pr-3 font-semibold">{name}</td>
                <td className="px-3">:</td>
                <td className="">{data}</td>
            </tr>
        )
    }
    return (
        <>
            <Helmet>
                <title>Detail Pengadaan barang</title>
            </Helmet>
            <Layout>
                <Header>
                    <BackButton link={linkBack} />
                    <p>Detail Pengadaan Barang</p>
                </Header>
                {loading === true ?
                    <div className="w-full h-full -mt-16 flex items-center justify-center pt-16">
                        Loading....
                    </div> :
                    errorMessage !== null ?
                        <div className="w-full h-full -mt-16 flex items-center justify-center pt-16">
                            {errorMessage}
                        </div> :
                        <div className="w-full px-3 md:px-5 py-3">
                            <table>
                                <Row name={`Waktu`} data={<CustomHour data={data?.created_at} />} />
                                <Row name={`Tanggal`} data={
                                    <div className="flex">
                                        <CustomDayName data={data?.created_at} />, &nbsp;
                                        <CustomDay data={data?.created_at} />-
                                        <CustomMonth data={data?.created_at} />-
                                        <CustomYear data={data?.created_at} />
                                    </div>
                                } />
                                <Row name={`Status`} data={data?.status} />
                                <Row name={`Nama Admin`} data={data?.admin?.name} />
                            </table>

                            <div className="w-full overflow-auto pt-3">
                                <table className="w-full min-w-max max-w-screen-xl">
                                    <thead>
                                        <tr className="bg-custom_green text-white">
                                            <th className="px-3 py-2 text-center rounded-tl-xl w-fit">No</th>
                                            <th className="px-3 py-2 text-center w-fit">Kode Barang</th>
                                            <th className="px-3 py-2 text-center">Nama Barang</th>
                                            <th className="px-3 py-2 text-center w-fit">Status</th>
                                            <th className="px-3 py-2 text-center rounded-tr-xl">Harga</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.barang?.map((item, index) => (
                                            <tr className={`${index % 2 === 1 ? 'bg-gray-100' : 'bg-white'} font-semibold hover:bg-gray-200 transition-colors text-sm`}>
                                                <td className="text-center px-3 py-2 ">{number++}</td>
                                                <td className="text-center px-3 py-2 ">{item.code}</td>
                                                <td className="text-start px-3 py-2 capitalize">{item.name}</td>
                                                <td className="text-center px-3 py-2 ">
                                                    <div className={`uppercase px-3 py-1 rounded-full text-white ${item.status === 'bagus' ? 'bg-green-500' : item.status === 'perlu perbaikan' ? 'bg-yellow-500' : item.status === 'rusak' ? 'bg-red-500' : ''}`}>
                                                        {item.status}
                                                    </div>
                                                </td>
                                                <td className="px-3 py-2 text-end text-base">
                                                    <CustomCurrency number={item.price} />
                                                </td>
                                            </tr>
                                        ))}
                                        <tr className="bg-custom_green text-white font-bold">
                                            <td className="px-3 py-2 text-end" colSpan={4}>Total</td>
                                            <td className="px-3 py-1 text-end text-xl">
                                                <CustomCurrency number={data.total_price} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </Layout>
        </>
    )
}

export default PengadaanBarangDetail