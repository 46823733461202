import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../../components/layouts"
import Header from "../../../components/header"
import { useHref, useNavigate } from "react-router-dom"
import { parseISO, isAfter } from "date-fns"
import { useEffect } from "react"
import { getBarang } from "../../../services/barang_service"
import Pagination from "../../../components/pagination"
import LoadingBarang from "../../../components/loadings/loading_barang"
import CustomCurrency from "../../../components/custom_currency"

const Inventory = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [orderBy, setOrderBy] = useState('created_at');
    const [sortBy, setSortBy] = useState('asc');

    let [number, setNumber] = useState(1);
    // let [numberV2, setNumberV2] = useState(1);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchData = async () => {
        setLoading(true)
        const response = await getBarang(accessToken, orderBy, sortBy, currentPage)
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (userRole !== 'super admin' && userRole !== 'admin') {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [currentPath, navigate]);

    return (
        <>
            <Helmet>
                <title>Monitoring Barang - Klinik Yasinta</title>
            </Helmet>
            <Layout
            >
                <Header>
                    <p className="uppercase">Monitoring Barang</p>
                    <p className="space-x-3">
                        <span className="text-xl">{totalData}</span>
                        <span>Barang</span>
                    </p>
                </Header>
                <div className="w-full px-5 pb-10 mt-3 overflow-auto scrollbar-thin scrollbar-track-custom_orange scrollbar-thumb-custom_green">
                    <table className="w-full min-w-full max-w-screen-xl">
                        <thead>
                            <tr className="bg-custom_green text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                <th className="px-3 py-2 text-start">Kode</th>
                                <th className="px-3 py-2 text-start w-fit">Nama Barang</th>
                                <th className="px-3 py-2 text-center">Harga</th>
                                <th className="px-3 py-2 text-center">Status</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={2}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading === true ?
                                Array.from({ length: 20 }).map((item) => (
                                    <LoadingBarang />
                                ))
                                :
                                data.length === 0 ?
                                    < tr >
                                        <td className="text-center">0</td>
                                        <td>Tidak ada data..!</td>
                                    </tr> :

                                    data.map((item) => (
                                        <tr className="hover:bg-gray-200 transition-colors">
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2 ">{item.code}</td>
                                            <td className="px-3 py-2 ">{item.name}</td>
                                            <td className="px-3 py-2 text-center">
                                                <CustomCurrency number={item.price} />
                                            </td>
                                            <td className="px-3 py-2 text-center">{item.status}</td>
                                            <td className="px-1">
                                                <button type="button" className="px-2 py-1 w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>
                                            <td className="px-1">
                                                <button type="button" className="px-2 py-1 w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                            }

                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />
                        </tbody>
                    </table>
                </div>
            </Layout >
        </>
    )
}

export default Inventory