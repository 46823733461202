import { useNavigate } from 'react-router-dom'
import Logo from '../../../assets/icons/logo_yasinta.png'
import ContentMenuAdmin from '../contents/admin'
import ContentMenuDokter from '../contents/dokter'
import ContentMenuFarmasi from '../contents/farmasi'
import ContentMenuPerawat from '../contents/perawat'
import ContentMenuSuperAdmin from '../contents/super_admin'
import MobileMenu from './mobile_version'

const DesktopSidebar = () => {
    let contentMenu
    const userRole = localStorage.getItem('user_role')
    const navigate = useNavigate()

    switch (userRole) {
        case 'admin':
            contentMenu = <ContentMenuAdmin />
            break;

        case 'farmasi':
            contentMenu = <ContentMenuFarmasi />
            break;

        case 'asisten farmasi':
            contentMenu = <ContentMenuFarmasi />
            break;

        case 'dokter umum':
            contentMenu = <ContentMenuDokter />
            break;

        case 'dokter gigi':
            contentMenu = <ContentMenuDokter />
            break;

        case 'perawat':
            contentMenu = <ContentMenuPerawat />
            break;

        case 'super admin':
            contentMenu = <ContentMenuSuperAdmin />
            break;

        default:
            navigate('/login')
            break;
    }
    return (
        <>
            <aside className="w-64 h-screen  overflow-auto hide-scrollbar bg-white hidden lg:block shadow-xl pb-10" style={{ scrollbarWidth: 'none' }}>
                <div className='w-full bg-white aspect-video pb-2 py-10 sticky top-0 flex flex-col items-center justify-center border-b-2 border-black z-10'>
                    <img src={Logo} alt="Logo yasinta" className='w-1/2 aspect-square mx-auto' />
                    <p className='text-center font-bold text-xl'>Klinik Yasinta</p>
                </div>

                <div className='flex flex-col font-semibold space-y-1 px-3 py-3'>
                    {contentMenu}
                </div>
            </aside>

            <MobileMenu children={contentMenu} />
        </>
    )
}

export default DesktopSidebar