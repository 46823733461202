import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/landing_page';
import Dashboard from './pages/dashboard';
import Obat from './pages/dashboard/obat';
import Keuangan from './pages/dashboard/keuangan';
import Ruangan from './pages/dashboard/ruangan';
import Login from './pages/auth/login';
import Pengaturan from './pages/dashboard/pengaturan';
import Staf from './pages/dashboard/staff';
import Pasien from './pages/dashboard/pasien';
import TambahPasien from './pages/dashboard/pasien/create';
import Kunjungan from './pages/dashboard/kunjungan';
import PengadaanObat from './pages/dashboard/obat/pengadaan';
import StockOpnameObat from './pages/dashboard/obat/stock_opname.jsx';
import TarifLayanan from './pages/dashboard/keuangan/tarif_layanan/index.jsx';
import Pemasukan from './pages/dashboard/keuangan/pemasukan/index.jsx';
import Pengeluaran from './pages/dashboard/keuangan/pengeluaran/index.jsx';
import Inventory from './pages/dashboard/inventory/index.jsx';
import InventoryPengadaanBarang from './pages/dashboard/inventory/pengadaan_barang/index.jsx';
import SalaryStaff from './pages/dashboard/keuangan/gaji_staff/index.jsx';
import KunjunganDetail from './pages/dashboard/kunjungan/detail/index.jsx';
import SuplayerObat from './pages/dashboard/obat/suplayer/index.jsx';
import Resep from './pages/dashboard/obat/resep/index.jsx';
import BuatResep from './pages/dashboard/obat/resep/buat_resep.jsx';
import InventoryPengadaanBaru from './pages/dashboard/inventory/pengadaan_barang/tambah_data.jsx';
import PengadaanBaru from './pages/dashboard/obat/pengadaan/add.jsx';
import Penggajihan from './pages/dashboard/keuangan/gaji_staff/penggajihan/index.jsx';
import DetailPasien from './pages/dashboard/pasien/detail.jsx';
import DetailResep from './pages/dashboard/obat/resep/detail.jsx';
import ViewDocumentResep from './pages/dashboard/obat/resep/view_document.jsx';
import NotFoundPage from './pages/error_pages/not_found_page.jsx';
import RawatJalan from './pages/dashboard/kunjungan/rawat_jalan/index.jsx';
import RawatInap from './pages/dashboard/kunjungan/rawat_inap/index.jsx';
import PengadaanBarangDetail from './pages/dashboard/inventory/pengadaan_barang/detail.jsx';
import DetailPengadaanObat from './pages/dashboard/obat/pengadaan/detail.jsx';
import PrintSuratPengadaanObat from './pages/dashboard/obat/pengadaan/print/index.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path='/404' Component={NotFoundPage} />
      <Route path='/403' Component={NotFoundPage} />

      <Route path='/' Component={LandingPage} />
      
      <Route path='/login' Component={Login} />

      <Route path='/dashboard' Component={Dashboard} />
      <Route path='/dashboard/index' Component={Dashboard} />
      <Route path='/dashboard/ruangan' Component={Ruangan} />

      <Route path='/dashboard/obat/suplayer' Component={SuplayerObat} />
      <Route path='/dashboard/obat/monitoring' Component={Obat} />
      <Route path='/dashboard/obat/resep/document/:id' Component={ViewDocumentResep} />
      <Route path='/dashboard/obat/resep' Component={Resep} />
      <Route path='/dashboard/obat/resep/detail/:id' Component={DetailResep} />
      <Route path='/dashboard/obat/resep/create' Component={BuatResep} />
      <Route path='/dashboard/obat/stok-opname' Component={StockOpnameObat} />

      <Route path='/dashboard/obat/pengadaan' Component={PengadaanObat} />
      <Route path='/dashboard/obat/pengadaan/create' Component={PengadaanBaru} />
      <Route path='/dashboard/obat/pengadaan/detail/:id' Component={DetailPengadaanObat} />
      <Route path='/dashboard/obat/pengadaan/detail/:id/print-surat-pengadaan' Component={PrintSuratPengadaanObat} />

      <Route path='/dashboard/inventory' Component={Inventory} />
      <Route path='/dashboard/inventory/pengadaan-barang' Component={InventoryPengadaanBarang} />
      <Route path='/dashboard/inventory/pengadaan-barang/create' Component={InventoryPengadaanBaru} />
      <Route path='/dashboard/inventory/pengadaan-barang/detail/:id' Component={PengadaanBarangDetail} />

      <Route path='/dashboard/keuangan' Component={Keuangan} />
      <Route path='/dashboard/keuangan/salary-staff' Component={SalaryStaff} />
      <Route path='/dashboard/keuangan/penggajihan' Component={Penggajihan} />
      <Route path='/dashboard/keuangan/pemasukan' Component={Pemasukan} />
      <Route path='/dashboard/keuangan/pengeluaran' Component={Pengeluaran} />
      <Route path='/dashboard/keuangan/tarif-layanan' Component={TarifLayanan} />

      <Route path='/dashboard/kunjungan' Component={Kunjungan} />
      <Route path='/dashboard/kunjungan/rawat-jalan' Component={RawatJalan} />
      <Route path='/dashboard/kunjungan/rawat-inap' Component={RawatInap} />

      <Route path='/dashboard/kunjungan/detail/:kunjungan_id' Component={KunjunganDetail} />

      <Route path='/dashboard/pasien' Component={Pasien} />
      <Route path='/dashboard/pasien/create' Component={TambahPasien} />
      <Route path='/dashboard/pasien/detail/:id' Component={DetailPasien} />

      <Route path='/dashboard/staf' Component={Staf} />
      
      <Route path='/dashboard/setting' Component={Pengaturan} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
