import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { Link, useHref, useNavigate, useParams } from "react-router-dom"
import { showKunjungan } from "../../../../services/kunjungan_service"
import { useEffect } from "react"
import { useState } from "react"
import ButtonBack from "../../../../components/button_back"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomHour from "../../../../components/custom_hour"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faCheck } from "@fortawesome/free-solid-svg-icons"
import ModalDiagnosa from "./modal/diagnosa"
import ModalKeluhanUtama from "./modal/keluhan_utama"
import ModalRiwayatPenyakit from "./modal/riwayat_penyakit"
import ModalRiwayatAlergi from "./modal/riwayat_alergi"
import { showDiagnosa } from "../../../../services/diagnosa_service"
import { showResep } from "../../../../services/resep_service"
import { parseISO, isAfter } from "date-fns"

const KunjunganDetail = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const expiredToken = localStorage.getItem('expired_token')
    const userRole = localStorage.getItem('user_role')
    const nowDate = new Date()

    const path = useHref()
    const { kunjungan_id } = useParams()
    const [data, setData] = useState()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [openModalDiagnosa, setOpenModalDiagnosa] = useState(false);
    const [openModalKeluhanUtama, setOpenModalKeluhanUtama] = useState(false);
    const [openModalRiwayatPenyakit, setOpenModalRiwayatPenyakit] = useState(false);
    const [openModalRiwayatAlergi, setOpenModalRiwayatAlergi] = useState(false);
    const [dataResep, setDataResep] = useState(null)

    const fetchDataResep = async (idResep) => {
        try {
            const response = await showResep(
                accessToken,
                idResep
            )
            console.log(response);
            if (response.status === 200) {
                setDataResep(response.data.data);
            } else {
                setDataResep(null);
            }
        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    const fetchKunjungan = async () => {
        try {
            const response = await showKunjungan(accessToken, kunjungan_id)
            setLoading(false)
            console.log(response);
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    if (response.data.data.resep !== null) {
                        fetchDataResep(response.data.data.resep)
                    }
                    break;

            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    useEffect(() => {
        if (refresh === true) {
            fetchKunjungan()
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchKunjungan()
            }
        }
        // disable-eslint-next-line
    }, [accessToken])

    const CardList = ({ name, value }) => {
        return (
            <div className="flex items-center space-x-3">
                <p className="w-40">{name}</p>
                <p>:</p>
                <p>{value}</p>
            </div>
        )
    }
    let totalPayment = 0;
    return (
        <>
            <Helmet>
                <title>Detail Kunjungan</title>
            </Helmet>
            <Layout
                modal={
                    <>

                        <ModalKeluhanUtama accessToken={accessToken} kunjunganId={kunjungan_id}
                            openModal={openModalKeluhanUtama} setOpenModal={setOpenModalKeluhanUtama}
                            setRefresh={setRefresh}
                        />

                        <ModalRiwayatPenyakit accessToken={accessToken} kunjunganId={kunjungan_id}
                            openModal={openModalRiwayatPenyakit} setOpenModal={setOpenModalRiwayatPenyakit}
                            setRefresh={setRefresh}
                        />

                        <ModalRiwayatAlergi accessToken={accessToken} kunjunganId={kunjungan_id}
                            openModal={openModalRiwayatAlergi} setOpenModal={setOpenModalRiwayatAlergi}
                            setRefresh={setRefresh}
                        />

                        <ModalDiagnosa
                            accessToken={accessToken} kunjunganId={kunjungan_id}
                            openModal={openModalDiagnosa} setOpenModal={setOpenModalDiagnosa}
                            setRefresh={setRefresh}
                        />
                    </>
                }
            >
                <Header>
                    <ButtonBack link="/dashboard/kunjungan" />
                    <p>Detail Kunjungan</p>
                </Header>
                <div className="w-full px-5 py-3">
                    <div className="w-full pb-3 grid grid-cols-2">
                        <div>
                            <CardList name={`Kategori Pasien`} value={data?.patient?.category} />
                            {data?.patient?.category === 'non_bpjs' ? '' : <CardList name={`No BPJS`} value={data?.patient?.bpjs_number} />}
                            <CardList name={`Nik`} value={data?.patient?.nik} />
                            <CardList name={`Nama Pasien`} value={data?.patient?.name} />
                            <CardList name={`Tempat Lahir`} value={data?.patient?.place_of_birth} />
                            <CardList name={`Tanggal Lahir`} value={data?.patient?.birthday} />
                            <CardList name={`Jenis Kelamin`} value={data?.patient?.gender} />
                            <CardList name={`No Telepon`}
                                value={data?.patient?.phone_number === null ||
                                    data?.patient?.phone_number === 'null' ? '-' :
                                    data?.patient?.phone_number} />
                        </div>
                        <div className="justify-self-end">
                            <CardList name={`Tanggal Kunjungan`} value={
                                data?.created_at ?
                                    <>
                                        <CustomDayName data={data?.created_at} />,&nbsp;
                                        <CustomDay data={data?.created_at} />-
                                        <CustomMonth data={data?.created_at} />-
                                        <CustomYear data={data?.created_at} />
                                    </>
                                    : '-'
                            } />

                            <CardList name={`Jam Kunjungan`} value={
                                data?.created_at ?
                                    <>
                                        <CustomHour data={data?.created_at} /> &nbsp; WIB
                                    </>

                                    : '-'
                            } />
                            <CardList name={`No Kunjungan`} value={data?.no_kunjungan} />
                            <CardList name={`Kategori Kunjungan`} value={data?.category} />
                        </div>

                    </div>

                    <div className="accordion-group accordion-group-hover">
                        {/* Anamnesis */}
                        <div className="accordion ">
                            <input type="checkbox" id="toggle-5" className="accordion-toggle" />
                            <label htmlFor="toggle-5" className="accordion-title h-[50px] text-base">Anamnesis</label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0">
                                    <div className="accordion-group accordion-group-hover">
                                        {/* Keluhan Utama */}
                                        <div className="accordion">
                                            <input type="checkbox" id="keluhanUtama" className="accordion-toggle" />
                                            <label htmlFor="keluhanUtama" className="accordion-title py-3 text-base">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center space-x-3">
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                        <p>Keluhan Utama</p>
                                                        {data?.anamnese_keluhan_utama?.length === 0 ? <p className="text-red-500 text-sm">( belum diperiksa )</p> : ''}
                                                    </div>
                                                    <button onClick={() => setOpenModalKeluhanUtama(true)} className="px-3 text-white py-2 font-semibold bg-custom_green w-36 rounded-md flex items-center justify-center">
                                                        Periksa
                                                    </button>
                                                </div>
                                            </label>
                                            <div className="accordion-content text-content2">
                                                <div className="min-h-0">
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr className="bg-custom_green text-white">
                                                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                                                <th className="px-3 py-2">Code</th>
                                                                <th className="px-3 py-2">Display</th>
                                                                <th className="px-3 py-2">Catatan</th>
                                                                <th className="px-3 py-2 rounded-tr-xl">Pemeriksa</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.anamnese_keluhan_utama?.map((item, index) => (
                                                                <tr className={index % 2 === 1 ? 'bg-gray-200' : ''}>
                                                                    <td className="px-3 py-2 text-center">{index + 1}</td>
                                                                    <td className="px-3 py-2 text-center">{item?.code}</td>
                                                                    <td className="px-3 py-2 text-start">{item?.display}</td>
                                                                    <td className="px-3 py-2 text-start">{item?.note}</td>
                                                                    <td className="px-3 py-2 text-start">{item?.created_by["name"]}</td>
                                                                </tr>
                                                            ))}
                                                            <tr className="bg-custom_green">
                                                                <td className="px-3 py-4" colSpan={5}></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Riwayat Penyakit */}
                                        <div className="accordion">
                                            <input type="checkbox" id="riwayatPenyakit" className="accordion-toggle" />
                                            <label htmlFor="riwayatPenyakit" className="accordion-title py-3 text-base">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center space-x-3">
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                        <p>Riwayat Penyakit</p>
                                                        {data?.anamnese_riwayat_penyakit?.length === 0 ? <p className="text-red-500 text-sm">
                                                            ( belum diperiksa )
                                                        </p> : ''}
                                                    </div>

                                                    <button onClick={() => setOpenModalRiwayatPenyakit(true)} className="px-3 text-white py-2 font-semibold bg-custom_green w-36 rounded-md flex items-center justify-center">
                                                        Periksa
                                                    </button>
                                                </div>
                                            </label>
                                            <div className="accordion-content text-content2">
                                                <div className="min-h-0">
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr className="bg-custom_green text-white">
                                                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                                                <th className="px-3 py-2">Code</th>
                                                                <th className="px-3 py-2">Display</th>
                                                                <th className="px-3 py-2">Catatan</th>
                                                                <th className="px-3 py-2 rounded-tr-xl">Pemeriksa</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.anamnese_riwayat_penyakit.length === 0 ?
                                                                "belum ada data..!" :
                                                                data?.anamnese_riwayat_penyakit?.map((item, index) => (
                                                                    <tr className={index % 2 === 1 ? 'bg-gray-200' : ''}>
                                                                        <td className="px-3 py-2 text-center">{index + 1}</td>
                                                                        <td className="px-3 py-2 text-center">{item?.code}</td>
                                                                        <td className="px-3 py-2 text-start">{item?.display}</td>
                                                                        <td className="px-3 py-2 text-start">{item?.note}</td>
                                                                        <td className="px-3 py-2 text-start">{item?.created_by["name"]}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            <tr className="bg-custom_green">
                                                                <td className="px-3 py-4" colSpan={5}></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Riwayat Alergi */}
                                        <div className="accordion">
                                            <input type="checkbox" id="riwayatAlergi" className="accordion-toggle" />
                                            <label htmlFor="riwayatAlergi" className="accordion-title py-3 text-base">
                                                <div className="flex items-center justify-between">
                                                    <div className="flex items-center space-x-3">
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                        <p>Riwayat Alergi</p>
                                                        {data?.anamnese_riwayat_alergi?.length === 0 ? <p className="text-red-500 text-sm">
                                                            ( belum diperiksa )
                                                        </p> : ''}
                                                    </div>
                                                    <button onClick={() => setOpenModalRiwayatAlergi(true)} className="px-3 text-white py-2 font-semibold bg-custom_green w-36 rounded-md flex items-center justify-center">
                                                        Periksa
                                                    </button>
                                                </div>
                                            </label>
                                            <div className="accordion-content text-content2">
                                                <div className="min-h-0">
                                                    <table className="w-full">
                                                        <thead>
                                                            <tr className="bg-custom_green text-white">
                                                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                                                <th className="px-3 py-2">identifier_code</th>
                                                                <th className="px-3 py-2">Code</th>
                                                                <th className="px-3 py-2">Display</th>
                                                                <th className="px-3 py-2">Catatan</th>
                                                                <th className="px-3 py-2 rounded-tr-xl">Pemeriksa</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data?.anamnese_riwayat_alergi?.length === 0 ? "belum ada data..!" :
                                                                    data?.anamnese_riwayat_alergi?.map((item, index) => (
                                                                        <tr className={index % 2 === 1 ? 'bg-gray-200' : ''}>
                                                                            <td className="px-3 py-2 text-center">{index + 1}</td>
                                                                            <td className="px-3 py-2 text-center">{item?.identifier_code}</td>
                                                                            <td className="px-3 py-2 text-center">{item?.code}</td>
                                                                            <td className="px-3 py-2 text-start">{item?.display}</td>
                                                                            <td className="px-3 py-2 text-start">{item?.note}</td>
                                                                            <td className="px-3 py-2 text-start">{item?.created_by["name"]}</td>
                                                                        </tr>
                                                                    ))}
                                                            <tr className="bg-custom_green">
                                                                <td className="px-3 py-4" colSpan={8}></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Riwayat Pengobatan */}
                                        {/* <div className="accordion">
                                            <input type="checkbox" id="riwayatPengobatan" className="accordion-toggle" />
                                            <label htmlFor="riwayatPengobatan" className="accordion-title py-3 text-base">
                                                <div className="flex items-center space-x-3">
                                                    <FontAwesomeIcon icon={faArrowRight} />
                                                    <p>Riwayat Pengobatan</p>
                                                </div>
                                            </label>
                                            <div className="accordion-content text-content2">
                                                <div className="min-h-0">

                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Hasil Pemeriksaan Fisik */}
                        {/* <div className="accordion">
                            <input type="checkbox" id="hasilPemeriksaanFisik" className="accordion-toggle" />
                            <label htmlFor="hasilPemeriksaanFisik" className="accordion-title py-3 text-base">Hasil Pemeriksaan Fisik</label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0">
                                    Blanditiis aut quis ratione quia magni nulla dolorum doloremque minus tempore suscipit, distinctio reprehenderit a illo iste porro minima dolorem tempora sit.
                                </div>
                            </div>
                        </div> */}

                        {/* Hasil Pemeriksaan Psikologi */}
                        {/* <div className="accordion">
                            <input type="checkbox" id="hasilPemeriksaanPsikologis" className="accordion-toggle" />
                            <label htmlFor="hasilPemeriksaanPsikologis" className="accordion-title py-3 text-base">
                                Hasil Pemeriksaan Psikologis
                            </label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0">
                                    Blanditiis aut quis ratione quia magni nulla dolorum doloremque minus tempore suscipit, distinctio reprehenderit a illo iste porro minima dolorem tempora sit.
                                </div>
                            </div>
                        </div> */}

                        {/* Diagnosa */}
                        <div className="accordion">
                            <input type="checkbox" id="diagnosa" className="accordion-toggle" />
                            <label htmlFor="diagnosa" className="accordion-title py-1 text-base">
                                <div className="flex items-center h-[50px] justify-between">
                                    <div className="flex items-center space-x-3 ">
                                        <p>Diagnosa</p>
                                        {!data?.diagnosa ?
                                            <p className="text-red-500 text-sm">
                                                ( belum diperiksa )
                                            </p> : ''}
                                    </div>
                                    {
                                        !['dokter umum', 'dokter gigi', 'perawat'].includes(userRole) ? '' :
                                            data?.diagnosa ? '' :
                                                <button type="button" onClick={() => setOpenModalDiagnosa(true)} className="px-3 text-white py-2 font-semibold bg-custom_green w-36 rounded-md flex items-center justify-center">
                                                    Diagnosa Baru
                                                </button>
                                    }
                                </div>
                            </label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0 flex items-start justify-between space-x-3">
                                    <div className="flex items-start space-x-3">
                                        <p className="font-semibold">( {data?.diagnosa?.icd?.code} )</p>
                                        <p>{data?.diagnosa?.icd?.display}</p>
                                    </div>
                                    <p className="font-semibold">
                                        ({data?.diagnosa?.dokter?.name})
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Resep */}
                        <div className="accordion">
                            <input type="checkbox" id="resep" className="accordion-toggle" />
                            <label htmlFor="resep" className="accordion-title py-1 text-base">
                                <div className="flex items-center h-[50px]  justify-between">
                                    <div className="flex items-center space-x-3 ">
                                        <p>Resep</p>
                                        {!dataResep ?
                                            <p className="text-red-500 text-sm">
                                                ( belum dibuat )
                                            </p> : ''}
                                    </div>
                                    {
                                        !['dokter umum', 'dokter gigi', 'perawat'].includes(userRole) ? '' :
                                            dataResep ? '' :
                                                <Link
                                                    to={`/dashboard/obat/resep/create?kunjungan_id=${kunjungan_id}&patient_id=${data?.patient?.id}&back_link=${path}`} className="px-3 text-white py-2 font-semibold bg-custom_green w-36 rounded-md flex items-center justify-center">
                                                    Buat Resep
                                                </Link>
                                    }
                                </div>
                            </label>
                            <div className="accordion-content text-content2">
                                <div className="min-h-0">
                                    {!dataResep ? '' :
                                        <>
                                            <table className="w-full">
                                                <thead>
                                                    <tr className="bg-custom_green text-white">
                                                        <th className="px-3 py-2 rounded-tl-md">No</th>
                                                        <th className="px-3 py-2">Nama Obat</th>
                                                        <th className="px-3 py-2">Aturan pake</th>
                                                        <th className="px-3 py-2">Harga</th>
                                                        <th className="px-3 py-2">Jumlah</th>
                                                        <th className="px-3 py-2 rounded-tr-md">Total Harga</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {!data?.resep ? "Belum ada data..!" :
                                                        dataResep?.recipe_item?.map((item, index) => {
                                                            const itemTotalPrice = item?.price * item?.quantity;
                                                            // Tambahkan total harga item ke dalam total pembayaran
                                                            totalPayment += itemTotalPrice;

                                                            return (
                                                                <tr>
                                                                    <td className="px-3 py-2">{index + 1}</td>
                                                                    <td className="px-3 py-2">{item?.name} ({item?.unit === '' || item?.unit === null ? '-' : item?.unit})</td>
                                                                    <td className="px-3 py-2 text-start">{item?.how_to_use ?? '-'}</td>
                                                                    <td className="px-3 py-2 text-end">{item?.price.toLocaleString()}</td>
                                                                    <td className="px-3 py-2 text-center">{item?.quantity}</td>
                                                                    <td className="px-3 py-2 text-end">{item?.total_price.toLocaleString()}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                    <tr className="font-semibold bg-custom_green text-white">
                                                        <td className="px-3 py-2 text-center" colSpan={5}>Total Pembayaran</td>
                                                        <td className="px-3 py-2 text-end">Rp. {totalPayment}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                            <tr>
                                                <td className="w-[100px] font-semibold">Dubuat Oleh</td>
                                                <td className="px-3">:</td>
                                                <td>{!dataResep ? '' : <p>{dataResep?.created_by?.name}</p>}</td>
                                            </tr>
                                            <tr>
                                                <td className="w-[100px] font-semibold">Catatan</td>
                                                <td className="px-3">:</td>
                                                <td>{!dataResep ? '' : <p>{dataResep?.note} </p>}</td>
                                            </tr>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Layout>
        </>
    )
}

export default KunjunganDetail