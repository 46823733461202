import { useState } from "react"
import { logoutService } from "../services/auth_service"
import { useNavigate } from "react-router-dom"

const LogoutButton = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const handleLogout = async () => {
        setLoading(true)
        try {
            const response = await logoutService(localStorage.getItem('access_token'))
            setLoading(false)
            if (response.status === 200 || response.status === 401) {
                localStorage.clear()
                navigate('/')
            }
        } catch (error) {

        }
    }
    return (
        <button type="button" onClick={handleLogout} className="w-full px-3 py-2 bg-red-500 hover:bg-red-600 text-white rounded-full font-semibold flex items-center justify-center">
            {loading === true ? <>
                <div className="spinner-wave ">
                    <div className="spinner-wave-dot  bg-white"></div>
                    <div className="spinner-wave-dot bg-white"></div>
                    <div className="spinner-wave-dot bg-white"></div>
                    <div className="spinner-wave-dot bg-white"></div>
                </div>
            </> : 'Keluar'}
        </button>
    )
}

export default LogoutButton