import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import Header from "../../../../components/header"
import ModalAddResep from "./modals/add"
import { Link } from "react-router-dom"
import { deleteResep, getResep, searchResep } from "../../../../services/resep_service"
import Pagination from "../../../../components/pagination"
import CustomHour from "../../../../components/custom_hour"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomDayName from "../../../../components/custom_day_name"
import CustomRefPopper from "../../../../components/custom_ref_poper"
import CustomSprinner from "../../../../components/custom_spinner"

const Resep = () => {
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModal, setOpenModal] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)

    const popupRef = useState(null)
    const [confirmDelete, setConfirmDelete] = useState(null)
    const [selectIndex, setSelectIndex] = useState('')
    const [keyword, setKeyword] = useState('')

    const fetchResep = async () => {
        setLoading(true)
        const response = await getResep(localStorage.getItem('access_token'))
        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setPerPage(response.data.meta.per_page)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setNumber(response.data.meta.from)
            setNumberV2(response.data.meta.from)

        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
        setLoading(false)
        setRefresh(false)
    }

    const fetchSearchResep = async () => {
        setLoading(true)
        const response = await searchResep(
            localStorage.getItem('access_token'),
            keyword
        )
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setPerPage(response.data.meta.per_page)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setNumber(response.data.meta.from)
            setNumberV2(response.data.meta.from)

        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchResep()
        }
    }, [refresh])

    useEffect(() => {
        fetchResep()
    }, [])


    const totalBayar = (list) => {
        // const totalBayar = list.map(resep => {
        //     // Menggunakan reduce untuk menjumlahkan total_price dari setiap item resep
        //     const totalHargaResep = resep.recipe_item.reduce((total, recipeItem) => total + recipeItem.total_price, 0);
        //     return totalHargaResep
        // });

        const totalHargaResep = list.reduce((total, recipeItem) => total + recipeItem.total_price, 0);
        return totalHargaResep
    }

    const handleDelete = async (id) => {
        setLoading(true)
        const response = await deleteResep(
            localStorage.getItem('access_token'),
            id
        )
        console.log(response);
        setLoading(false)
        setRefresh(true)
    }

    const handleSearch = (e) => {
        e.preventDefault()
        setLoading(true)
        fetchSearchResep()
        setLoading(false)
    }
    return (
        <>
            <Helmet>
                <title>Pengadaan Obat</title>
            </Helmet>
            <Layout
                modal={
                    <>
                        <ModalAddResep openModal={openModal} setOpenModal={setOpenModal} setRefresh={setRefresh} />
                    </>
                }
            >
                <Header>
                    <p className="uppercase">Resep Dokter - Klinik Yasinta</p>
                </Header>

                <div className="w-full flex items-center justify-between px-3 md:px-5 py-3 space-x-3">
                    {/* search */}
                    <form onSubmit={handleSearch} className="flex items-center relative w-full lg:w-fit">
                        <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full lg:w-[220px] h-[40px] outline-none border-2 rounded-l-md px-3 py-2 text-black text-sm" />
                        <button type="submit" className="bg-green-500 px-3 h-[40px] rounded-r-md border-2 border-green-500 text-sm text-white font-semibold">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <Link to="/dashboard/obat/resep/create" className="px-3 md:w-[170px] h-[40px] bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                        <FontAwesomeIcon icon={faPlus} />
                        <p className="hidden md:flex">Buat Resep</p>
                    </Link>
                </div>

                <div className="w-full px-3 md:px-5">
                    <div className="w-full pb-10 overflow-auto">
                        <table className="w-full min-w-max max-w-xl">
                            <thead>
                                <tr className="bg-green-500 text-white">
                                    <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                    <th className="px-3 py-2 text-center" colSpan={2}>Waktu / Tanggal</th>
                                    <th className="px-3 py-2 text-start">Kode Resep</th>
                                    <th className="px-3 py-2 text-start">Nama Patient</th>
                                    <th className="px-3 py-2 text-start">Admin / Dokter</th>
                                    <th className="px-3 py-2 text-start">Status</th>
                                    <th className="px-3 py-2 text-start">Total Bayar</th>
                                    <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr className="hover:bg-gray-200 transition-colors">
                                        <td className="px-3 py-2 text-center">{number++}</td>
                                        <td className="px-3 py-2 ">
                                            <CustomHour data={item.created_at} />
                                        </td>
                                        <td className="px-3 py-2 ">
                                        <CustomDayName data={item.created_at} />, &nbsp;
                                            <CustomDay data={item.created_at} />-
                                            <CustomMonth data={item.created_at} />-
                                            <CustomYear data={item.created_at} />
                                        </td>
                                        <td className="px-3 py-2 text-center font-semibold">{item.recipe_number}</td>
                                        <td className="px-3 py-2 ">{item.patient?.name ?? '-'}</td>
                                        <td className="px-3 py-2 ">{item.created_by?.name ?? '-'}</td>
                                        <td className="px-3 py-2 ">
                                            <div className={`px-3 py-1 ${item.status === 'sudah dibayar' ? 'bg-custom_green' : 'bg-red-500'} rounded-full text-white font-bold text-sm`}>
                                            {item.status}
                                            </div>
                                        </td>
                                        <td className="px-3 py-2 ">Rp. {totalBayar(item.recipe_item).toLocaleString()}</td>
                                        <td className="  px-1 py-2 h-full w-16">
                                            <Link to={`/dashboard/obat/resep/detail/${item.id}`}>
                                                <button className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEye} className="mx-auto" />
                                                </button>
                                            </Link>
                                        </td>
                                        <td className="  px-1 py-2 h-full w-16">
                                            <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </button>
                                        </td>
                                        <td className="px-1 py-2 h-full w-16 relative flex " >
                                            <button type="button" onClick={() => {
                                                setSelectIndex(index)
                                                setConfirmDelete(!confirmDelete)
                                            }} className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                            <div ref={popupRef} className={` text-sm text-back absolute top-5 right-0 px-3 bg-white shadow-md font-semibold rounded-lg  backdrop-blur-sm z-10 transition-all duration-300 overflow-hidden ${confirmDelete === true && selectIndex === index ? 'h-[100px] w-[200px] py-2' : 'h-0 w-0 py-0'}`}>
                                                <CustomRefPopper setOpenPopper={setConfirmDelete} popperRef={popupRef} />
                                                <p className="text-center">Apakah Anda yakin akan menghapus data ini..?</p>
                                                <div className="flex items-center space-x-3 mt-3 text-white">
                                                    <button type="button" onClick={() => setConfirmDelete(false)} className="flex items-center justify-center bg-green-500 hover:bg-green-600 transition-colors w-24 text-center rounded-md py-1">
                                                        Tidak
                                                    </button>
                                                    <button type="button" onClick={() => handleDelete(item.id)} className="flex items-center justify-center bg-red-500 hover:bg-red-600 transition-colors w-24 text-center rounded-md py-1">
                                                        {loading === false ? 'Ya' : <CustomSprinner />}
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                <Pagination
                                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                                    lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />

                            </tbody>
                        </table>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Resep