import { Helmet } from "react-helmet"
import Layout from "../../../components/layouts"
import { Link, useNavigate } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { deletePatient, getPatient, searchPatient } from "../../../services/patient_service"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import ModalFilterPatient from "./modals/filter"
import Pagination from "../../../components/pagination"
import Header from "../../../components/header"

import { parseISO, isAfter } from 'date-fns'
import CustomHour from "../../../components/custom_hour"
import CustomDayName from "../../../components/custom_day_name"
import CustomDay from "../../../components/custom_day"
import CustomMonth from "../../../components/custom_month"
import CustomYear from "../../../components/custom_year"
import { getKunjungan } from "../../../services/kunjungan_service"
import { getAku } from "../../../services/aku_service"

const Pasien = () => {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [category, setCategory] = useState('all')
    const [sortBy, setSortBy] = useState('name')
    const [sortFrom, setSortFrom] = useState('asc')
    const [openModal, setOpenModal] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)


    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [keyword, setKeyword] = useState('')

    // const fetchPatient = async () => {
    //     setLoading(true)
    //     try {
    //         const response = await getPatient(accessToken,  1)
    //         console.log(response);
    //         // if (response.status === 200) {
    //         //     setData(response.data.data)
    //         //     setTotalData(response.data.meta.total)
    //         //     setCurrentPage(response.data.meta.current_page)
    //         //     setLastPage(response.data.meta.last_page)
    //         //     setNumber(response.data.meta.from)
    //         //     setNumberV2(response.data.meta.from)
    //         //     setRefresh(false)
    //         // } else if (response.status === 403) {
    //         //     navigate('/403?back=/dashboard/patient')
    //         // } else {
    //         //     alert('Silahkan cek koneksi internet Anda..!')
    //         // }
    //     } catch (error) {
    //         alert('Silahkan cek koneksi internet Anda..!')
    //     }
    //     setLoading(false)
    // }

    const fetchKunjungan = async () => {
        try {
            const response = await getKunjungan(accessToken, category, sortBy, sortFrom, currentPage)
            setLoading(false)
            console.log(response);
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setNumber(response.data.meta.from)
                setNumberV2(response.data.meta.from)
                setRefresh(false)
            } else if (response.status === 403) {
                navigate('/403?back=/dashboard/patient')
            } else {
                alert('Silahkan cek koneksi internet Anda..!')
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    const searchDataPatient = async () => {
        setLoading(true)
        try {
            const response = await searchPatient(accessToken, keyword)
            console.log(response);
            if (response.status === 200) {
                setData(response.data.data)
                setTotalData(response.data.meta.total)
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
                setNumber(response.data.meta.from)
                setNumberV2(response.data.meta.from)
                setRefresh(false)
            } else if (response.status === 403) {
                // navigate('/403?back=/dashboard/patient')
            } else {
                alert('Silahkan cek koneksi internet Anda..!')
            }
        } catch (error) {
            alert('Silahkan cek koneksi internet Anda..!')
        }
        setLoading(false)
    }

    // eslint-disable-next-line
    useEffect(() => {
        if (refresh === true) {
            // fetchPatient()
        }
    }, [refresh]);

    // eslint-disable-next-line
    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            // if (!['super admin', 'admin', 'dokter umum', 'dokter gigi', 'perawat'].includes(userRole)) {
            //     navigate('/403?back=/dashboard/patient')
            // } else
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchKunjungan()
            }
        }

    }, [accessToken, navigate])

    const handleSearch = (e) => {
        e.preventDefault()

        searchDataPatient()
    }

    const handleDelete = async (id) => {
        const response = await deletePatient(accessToken, id)
        if (response.status === 200) {
            // fetchPatient(accessToken)
        }
    }

    return (
        <>
            <Helmet>
                <title>Pasien - KLINIK YASINTA</title>
            </Helmet>

            <Layout

                modal={
                    <>
                        <ModalFilterPatient
                            loading={loading} setLoading={setLoading}
                            category={category} setCategory={setCategory}
                            sortBy={sortBy} setSortBy={setSortBy}
                            sortFrom={sortFrom} setSortFrom={setSortFrom}
                            openModal={openModal} setOpenModal={setOpenModal}
                            setRefresh={setRefresh}
                        />
                    </>
                }
            >
                <Header>
                    <p className="uppercase flex md:space-x-1">
                        <span>Data Pasien</span>
                        <span className="hidden md:flex">- Klinik Yasinta</span>
                    </p>

                    <p className="flex items-end space-x-2 justify-self-end">
                        <span className="text-2xl font-semibold">{totalData}</span>
                        <span>Pasien</span>
                    </p>
                </Header>

                <div className="px-3 md:px-5 py-3 border-b-2 md:border-0 border-custom_green space-y-3 sticky top-16 bg-white z-10">
                    <form onSubmit={handleSearch} className="flex md:hidden items-center relative w-full">
                        <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full outline-none border-2 rounded-l-md px-3 py-2 text-black" />
                        <button type="submit" className=" bg-green-500 px-3 py-2 rounded-r-md border-2 border-green-500">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                    <div className="w-full flex items-center justify-between ">
                        <button type="button" onClick={() => setOpenModal(true)} className="flex items-center px-3 py-2 space-x-3 bg-custom_green text-white font-semibold rounded-md">
                            <FontAwesomeIcon icon={faFilter} />
                            <p>Filter</p>
                        </button>

                        <form onSubmit={handleSearch} className="hidden md:flex items-center relative w-[300px]">
                            <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full outline-none border-2 rounded-l-md px-3 py-2 text-black focus:border-custom_green" />
                            <button type="submit" className=" bg-custom_green px-3 py-2 rounded-r-md border-2 border-custom_green text-white font-semibold">
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </form>

                        {
                            !['super admin', 'admin'].includes(userRole) ? '' :
                                <Link to="/dashboard/pasien/create" className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p className="flex md:space-x-1 text-sm lg:text-base">
                                        <span className="hidden md:flex">Tambah Data</span>
                                        <span>Pasien</span>
                                    </p>
                                </Link>
                        }
                    </div>
                </div>

                <div className="w-full px-5 text-xs md:text-sm">
                    <div className="w-full hidden lg:block overflow-auto">
                        <table className="w-full min-w-max max-w-screen-2xl">
                            <thead>
                                <tr className="bg-custom_green text-white">
                                    <th className="px-3 py-2 rounded-tl-xl">No</th>
                                    <th className="px-3 py-2" colSpan={2}>Waktu / Tanggal</th>
                                    <th className="px-3 py-2">Kategori</th>
                                    <th className="px-3 py-2">Nomor BPJS</th>
                                    <th className="px-3 py-2">Kode Pasien</th>
                                    <th className="px-3 py-2">Nama</th>
                                    <th className="px-3 py-2">NIK</th>
                                    <th className="px-3 py-2 rounded-tr-xl" colSpan={3}>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading === true ?
                                    Array.from({ length: 20 }).map(() => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 10 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                    :
                                    <>
                                        {data?.map((item) => (
                                            <tr className="hover:bg-gray-200">
                                                <td className="px-3 py-2 text-center">{number++}</td>
                                                <td className="px-3 py-2 ">
                                                    <CustomHour data={item.created_at} />
                                                </td>
                                                <td className="px-3 py-2 ">
                                                    <CustomDayName data={item.created_at} />,&nbsp;
                                                    <CustomDay data={item.created_at} />-
                                                    <CustomMonth data={item.created_at} />-
                                                    <CustomYear data={item.created_at} />
                                                </td>
                                                <td className="px-3 py-2 text-center">{item.category}</td>
                                                <td className="px-3 py-2">
                                                    {item.bpjs_number === null || item.bpjs_number === 'null' ? '-' : item.bpjs_number}
                                                </td>
                                                <td className="px-3 py-2">{item.code}</td>
                                                <td className="px-3 py-2">{item.name}</td>
                                                <td className="px-3 py-2">{item.nik}</td>
                                                <td className="px-1 py-2">
                                                    <Link to={`/dashboard/pasien/detail/${item.id}`} className="w-full h-full flex items-center justify-center px-3 py-1 bg-green-400 hover:bg-green-600 transition-colors  text-white rounded-md">
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Link>
                                                </td>
                                                <td className="px-1 py-2">
                                                    <button
                                                        type="button"
                                                        onClick={() => {

                                                        }}
                                                        className="w-full h-full flex items-center justify-center px-3 py-1 bg-yellow-400 hover:bg-yellow-600 transition-colors  text-white rounded-md">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        <Pagination
                                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                                            lastPage={lastPage} setRefresh={setRefresh} colSpan={11} />
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Mobile version */}
                <div className="w-full space-y-3 p-3 lg:hidden">
                    {loading === true ? "Loading..." :
                        data?.map((item) => (
                            <Link to={`/dashboard/pasien/detail/${item.id}`} className="rounded-xl border shadow-md w-full px-3 py-2 flex items-center space-x-2 hover:bg-gray-100 transition-colors">
                                <div className="h-10 aspect-square bg-white shadow-sm flex items-center justify-center rounded-full border">
                                    {numberV2++}
                                </div>
                                <div>
                                    <p>{item.category}</p>
                                    <p>{item.bpjs_number === null ? '-' : item.bpjs_number}</p>
                                </div>
                                <div>
                                    <p>{item.name}</p>
                                    <p>{item.nik}</p>
                                </div>
                            </Link>
                        ))
                    }
                </div>
            </Layout>
        </>
    )
}

export default Pasien