import LogoutButton from "../../logout"
import NavLinkItem from "../../nav_link_item"
import CustomeAccording from "../../according"

const ContentMenuAdmin = () => {
    return (
        <>
            <NavLinkItem link="/dashboard/index" name="Dashboard" />
            <NavLinkItem link="/dashboard/staf" name="Data Staf" />
            <NavLinkItem link="/dashboard/kunjungan" name="Kunjungan" />
            <NavLinkItem link="/dashboard/pasien" name="Pasien" />
            <NavLinkItem link="/dashboard/ruangan" name="Ruangan" />

            <LogoutButton />
        </>
    )
}

export default ContentMenuAdmin