import { Helmet } from "react-helmet"
import Layout from '../../../components/layouts'
import Header from "../../../components/header"
import ModalOfficeAddress from "./modal/update_office_address"
import { useState } from "react"
import { getOfficeAddress } from "../../../services/office_address_service"
import { useEffect } from "react"
import { useHref, useNavigate } from "react-router-dom"
import { parseISO, isAfter, set } from "date-fns"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../services/location_service"

const Pengaturan = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const accessToken2 = localStorage.getItem('access_token2')
    const userRole = localStorage.getItem('user_role')
    const userName = localStorage.getItem('user_name')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()


    const [loading, seLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModalOffice, setOpenModalOffice] = useState(false)

    const [officeAddress, setOfficeAddress] = useState(null)
    const [province, setProvince] = useState(null)
    const [regency, setRegency] = useState(null)
    const [district, setDistrict] = useState(null)
    const [village, setVillage] = useState(null)

    const fetchProvince = async (provinceId) => {
        const response = await getProvince(accessToken2, provinceId)
        console.log(response);
        if (response.status === 200) {
            setProvince(response.data?.data[0].name)
        } else {
            setProvince(null)
        }
    }

    const fetchRegency = async (provinceId, regencyId) => {
        const response = await getRegency(accessToken2, provinceId, regencyId)
        console.log(response);
        if (response.status === 200) {
            setRegency(response.data?.data[0].name)
        } else {
            setRegency(null)
        }
    }

    const fetchDistrict = async (regencyId, districtId) => {
        const response = await getDistrict(accessToken2, regencyId, districtId)
        console.log(response);
        if (response.status === 200) {
            setDistrict(response.data?.data[0].name)
        } else {
            setDistrict(null)
        }
    }
    const fetchVillage = async (districtId, villageId) => {
        const response = await getVillage(accessToken2, districtId, villageId)
        console.log(response);
        if (response.status === 200) {
            setVillage(response.data?.data[0].name)
        } else {
            setVillage(null)
        }
    }

    const fetchOfficeAddress = async () => {
        try {
            const response = await getOfficeAddress(accessToken)
            console.log(response);
            if (response.status === 200) {
                setOfficeAddress(response.data)
                if (response.data != null) {
                    fetchProvince(response.data.province_id)
                    fetchRegency(response.data.province_id, response.data.regency_id)
                    fetchDistrict(response.data.regency_id, response.data.district_id)
                    fetchVillage(response.data.district_id, response.data.village_id)
                }
            } else {
                setOfficeAddress(null)
            }
        } catch (error) {
            alert("Silahkan periksa koneksi internet Anda..!")
        }
    }

    useEffect(() => {
        if (refresh === true) {
            fetchOfficeAddress()
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchOfficeAddress()
            }
        }
    }, [accessToken])

    const Row = ({ name, value }) => {
        return (
            <tr>
                <td>{name}</td>
                <td className="px-3">:</td>
                <td>
                    {value}
                </td>
            </tr>
        )
    }
    return (
        <>
            <Helmet>
                <title>Pengaturan</title>
            </Helmet>

            <Layout
                modal={
                    <>
                        <ModalOfficeAddress
                            accessToken={accessToken} officeAddress={officeAddress}
                            openModal={openModalOffice} setOpenModal={setOpenModalOffice}
                            setRefresh={setRefresh} />
                    </>
                }>
                <Header>
                    <p className="uppercase">Pengaturan Akun</p>
                </Header>

                <div className="p-3">
                    <Row name={"Provinsi"} value={province} />
                    <Row name={"Kabupaten / Kota"} value={regency} />
                    <Row name={"Kecamatan"} value={district} />
                    <Row name={"Desa /Kelurahan"} value={village} />
                    <Row name={"Kode Pos"} value={officeAddress?.postal_code } />
                    <Row name={"RT"} value={officeAddress?.rt} />
                    <Row name={"RW"} value={officeAddress?.rw} />
                    <Row name={"detail Alamat"} value={officeAddress?.detail} />

                    {!['super admin', 'admin'].includes(userRole) ? "" :
                        <button type="button" onClick={() => setOpenModalOffice(true)} className="bg-custom_green px-3 py-2 rounded-md hover:bg-green-600 transition-colors font-semibold text-white">
                            Update Alamat Klinik
                        </button>
                    }
                </div>
            </Layout>
        </>
    )
}

export default Pengaturan