import apiService from "./api_service"

export const getKeluhanUtama = async (token, page) => {
    try {

        const response = await apiService.get(`/keluhan-utama?&page=${page}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postKeluhanUtama = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/keluhan-utama/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const getRiwayatAlergi = async (token) => {
    try {

        const response = await apiService.get(`/riwayat-alergi`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postRiwayatAlergi = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/riwayat-alergi/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postRiwayatPenyakit = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/riwayat-penyakit/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}