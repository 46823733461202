import { useEffect } from "react"
import { useState } from "react"
import CustomSelect from "../../../../components/custom_select"
import CustomInput from "../../../../components/custom_input"
import { getDrugSupliers } from "../../../../services/drug_suplier_service"
import { postDrug } from "../../../../services/drug_service"

const ModalAddObat = ({ openModal, setOpenModal, setRefresh }) => {
    const [loading, setLoading] = useState(false)

    const [dataSuplier, setDataSuplier] = useState([])
    const [suplayerId, setSuplayerId] = useState('')

    const [name, setName] = useState('')
    const [unit, setUnit] = useState('')
    const [invoicePrice, setInvoicePrice] = useState(0)
    const [fixedPrice, setFixdPrice] = useState(0)
    const [stock, setStock] = useState(0)

    const fetchDrugSupliers = async () => {
        const response = await getDrugSupliers(localStorage.getItem('access_token'))
        if (response.status === 200) {
            setDataSuplier(response.data.data)
        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
    }

    useEffect(() => {
        fetchDrugSupliers()
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const response = await postDrug(
            localStorage.getItem('access_token'),
            suplayerId,
            name,
            unit,
            invoicePrice,
            fixedPrice,
            stock
        )

        console.log(response);

        setLoading(false)
        setOpenModal(false)
        setRefresh(true)
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-300'}`}>

            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[500px] md:h-[540px] lg:h-[585px] max-h-[90%] delay-300' : 'w-0 h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data obat Baru</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full py-[70px] overflow-auto">
                    <div className=" flex flex-col items-center justify-center space-y-3 px-3">
                        <CustomSelect 
                        name="Suplayer Obat" 
                        value={suplayerId} setValue={setSuplayerId} 
                        list={dataSuplier} displayName="company_name" 
                        CustomOption={<><option value="">Tidak Ada Suplier</option></>} />

                        <CustomInput type="text" id="name" name="Nama Obat" value={name} setValue={setName} customStyle="uppercase" />

                        <CustomInput
                            type="text" id="satuan"
                            name={`Satuan Obat`}
                            subName={`(ml/tablet dll)`}
                            value={unit} setValue={setUnit}
                            customStyle="uppercase" />

                        <CustomInput type="number" id="invoice_price" name="Harga Faktur" value={invoicePrice} setValue={setInvoicePrice} />
                        <CustomInput type="number" id="fixed_price" name="Harga Jual" value={fixedPrice} setValue={setFixdPrice} />
                        <CustomInput type="number" id="stock" name="Stok Obat" value={stock} setValue={setStock} />

                        <div className="w-full flex items-center space-x-3">
                            <button
                                type="button"
                                onClick={() => { setOpenModal(false) }}
                                className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 py-2 rounded-md font-semibold text-white">
                                Kembali
                            </button>


                            <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                                {loading === true ? <>
                                    <div className="spinner-wave ">
                                        <div className="spinner-wave-dot  bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                    </div>
                                </> : 'SIMPAN'}
                            </button>
                        </div>
                    </div>
                </form>
                <div className="absolute bottom-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div >
    )
}

export default ModalAddObat