import apiService from "./api_service";

export const getKunjungan = async (accessToken, category, sortBy, sortFrom, page) => {
    try {
        const response = await apiService.get(`patients?category=${category}&sort_by=${sortBy}&sort_from=${sortFrom}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}

export const getKunjungan2 = async (accessToken, category, sortBy, sortFrom, page) => {
    try {
        const response = await apiService.get(`kunjungan?category=${category}&sort_by=${sortBy}&sort_from=${sortFrom}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}

export const searchKunjungan = async (accessToken, keyword) => {
    try {
        const response = await apiService.get(`kunjungan/search?keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}
export const showKunjungan = async (accessToken, kunjunganId) => {
    try {
        const response = await apiService.get(`kunjungan/show/${kunjunganId}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}

export const postKunjungan = async (accessToken, formData) => {
    try {
        const response = await apiService.post(`kunjungan/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}

export const updateKunjungan = async (
    accessToken,
    kunjunganId,
    status
) => {
    try {
        const formData = new FormData()
        formData.append('status', status)

        const response = await apiService.post(`kunjungan/update/${kunjunganId}`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}

export const deleteKunjungan = async (accessToken, id) => {
    try {
        const response = await apiService.post(`kunjungan/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response
    } catch (error) {
        return error.response
    }
}