import apiService from "./api_service";

export const getDrugSupliers = async (accessToken) => {
    try {
        const response = await apiService.get(`drug-supliers`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}

export const postDrugSuplier = async (
    accessToken, 
    company_name,
    person_responsible,
    phone_number,
    province_id,
    regency_id,
    district_id,
    village_id,
    detail
) => {
    try {
        const formData = new FormData()
        formData.append('company_name', company_name)
        formData.append('person_responsible', person_responsible)
        formData.append('phone_number', phone_number)
        formData.append('province_id', province_id)
        formData.append('regency_id', regency_id)
        formData.append('district_id', district_id)
        formData.append('village_id', village_id)
        formData.append('detail', detail)

        const response = await apiService.post(`drug-suplier/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        });

        return response;

    } catch (error) {
        return error.response
    }
}