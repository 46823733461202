import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../../components/layouts"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import ModalAddRuangan from "./modals/add"
import { useEffect, useState } from "react"
import { deleteRoom, getRoom } from "../../../services/room_service"
import { useNavigate } from "react-router-dom"
import Pagination from "../../../components/pagination"
import Header from "../../../components/header"

const Ruangan = () => {
    const navigate = useNavigate()
    const [accessToken, setAccessToken] = useState('')
    const [openModal, setOpenModal] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [perPage, setPerPage] = useState(0)

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const fetchData = async (accessToken) => {
        setLoading(true)
        const response = await getRoom(accessToken)

        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setPerPage(response.data.meta.per_page)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setNumber(response.data.meta.from)
            setNumberV2(response.data.meta.from)
        }

        setLoading(false)
        setRefresh(false)

    }

    useEffect(() => {
        if (refresh === true) {
            fetchData(accessToken)
        }
    }, [refresh]);

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token')
        if (accessToken === null || accessToken === '') {
            navigate('login')
        }

        setAccessToken(accessToken)

        fetchData(accessToken)

    }, [])

    const handleDelete = async (id) => {
        const response = await deleteRoom(accessToken, id)

        if (response.status === 200) {
            setRefresh(true)
        } else {
            alert('gagal hapus data..!')
        }
    }
    return (
        <>

            <Layout

                modal={
                    <>
                        <ModalAddRuangan accessToken={accessToken} openModal={openModal} setOpenModal={setOpenModal} setRefresh={setRefresh} />
                    </>
                }

                children={
                    <>
                        <Header children={
                            <>
                                <p className="uppercase">Data Ruangan - Klinik Yasinta</p>
                                <div className="flex items-center relative">
                                    <input type="text" className="outline-none border-2 rounded-l-md px-3 py-2 text-black" />
                                    <button type="submit" className="bg-green-500 px-3 py-2 rounded-r-md border-2 border-green-500">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                                <p className="flex items-end space-x-2">
                                    <span className="text-2xl font-semibold">100</span>
                                    <span>Ruangan</span>
                                </p>
                            </>
                        } />

                        <div className="w-full flex items-center justify-end px-5 py-3">
                            <button type="button" onClick={() => setOpenModal(true)} className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                                <FontAwesomeIcon icon={faPlus} />
                                <p>Tambah Ruangan</p>
                            </button>
                        </div>

                        <div className="w-full px-5 pb-10">
                            <table className="w-full">
                                <thead>
                                    <tr className="bg-green-500 text-white">
                                        <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                        <th className="px-3 py-2 text-start">Nama Ruangan</th>
                                        <th className="px-3 py-2 text-start">Kode Ruangan</th>
                                        <th className="px-3 py-2 text-center">Biaya Ruangan</th>
                                        <th className="px-3 py-2 text-center">Kapasitas</th>
                                        <th className="px-3 py-2 text-center">Ruangan Digunakan</th>
                                        <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr className="hover:bg-gray-200 transition-colors">
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2 ">{item.name}</td>
                                            <td className="px-3 py-2 ">{item.code}</td>
                                            <td className="px-3 py-2 text-end">Rp. {item.room_cost} / Hari</td>
                                            <td className="px-3 py-2 text-end">{item.capacity} Pasien</td>
                                            <td className="px-3 py-2 text-end">{item.room_used} Pasien</td>
                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </td>
                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>
                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                    <Pagination
                                        currentPage={currentPage} setCurrentPage={setCurrentPage}
                                        lastPage={lastPage} setRefresh={setRefresh} colSpan={11} />

                                </tbody>
                            </table>
                        </div>
                    </>
                }
            />

        </>
    )
}

export default Ruangan