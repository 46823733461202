import apiService from "./api_service"

export const getRoom = async (accessToken) => {
    try {
        const response = await apiService.get(`/room`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const searchRoom = async (token, keyword) => {
    try {

        const response = await apiService.get(`/room/search/${keyword}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const showRoom = async (token, id) => {
    try {

        const response = await apiService.get(`/room/show/${id}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postRoom = async (accessToken, body) => {
    try {
        const response = await apiService.post(`/room/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response
    }
}

export const deleteRoom = async (token, id) => {
    try {

        const response = await apiService.post(`/room/delete/${id}`, {}, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}