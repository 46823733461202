import apiService from "./api_service"

export const getProvince = async (accessToken2, provinceId) => {
    try {
        const response = await apiService.get(`/province?token=${accessToken2}${provinceId === null ? '' : `&province_id=${provinceId}`}`, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const getRegency = async (accessToken2, provinceId, regencyId) => {
    try {

        const response = await apiService.get(`/regency/${provinceId}?token=${accessToken2}${regencyId === null ? '' : `&regency_id=${regencyId}`}`, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const getDistrict = async (accessToken2, regencyId, districtId) => {
    try {

        const response = await apiService.get(`/district/${regencyId}?token=${accessToken2}${districtId === null ? '' : `&district_id=${districtId}`}`, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const getVillage = async (accessToken2, districtId, villageId) => {
    try {

        const response = await apiService.get(`/village/${districtId}?token=${accessToken2}${villageId === null ? '' : `&village_id=${villageId}`}`, {
            headers: {
                // Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}