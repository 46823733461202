import Sidebar from "../sidebars"

const Layout = ({ modal, children }) => {
    return (
        <>
            <div className="w-full h-screen flex items-center justify-center fixed ">
                <div className="w-full h-screen bg-white flex z-10 text-black">
                    <Sidebar></Sidebar>
                    <div className="w-full h-full overflow-auto scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-custom_green">
                        {children}
                    </div>
                </div>
                {modal}
            </div>
        </>
    )
}

export default Layout