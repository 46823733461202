import { useEffect, useState } from "react"
import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import CustomInputV2 from "../../../../../components/custom_input_v2"
import { Link } from "react-router-dom"
import { postKeluhanUtama } from "../../../../../services/anamnese_service"

const ModalKeluhanUtama = ({ kunjunganId, accessToken, openModal, setOpenModal, setRefresh }) => {
    const accessToken2 = localStorage.getItem('access_token2')
    const [loading, setLoading] = useState(false)
    const [code, setCode] = useState('')
    const [display, setDisplay] = useState('')
    const [note, setNote] = useState('')

    const [errorCode, setErrorCode] = useState(null)
    const [errorDisplay, setErrorDisplay] = useState(null)
    const [errorNote, setErrorNote] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorCode(null)
            setErrorDisplay(null)
            setErrorNote(null)

            if (code.length === 0) {
                setErrorCode("Code Kondisi harus diisi..!")
            } else if (display.length === 0) {
                setErrorDisplay("Display Kondisi harus diisi..!")
            } else if (note.length === 0) {
                setErrorNote("Keterangan harus diisi..!")
            } else {
                const formData = new FormData()
                formData.append('kunjungan_id', kunjunganId)
                formData.append('token_v2', accessToken2)
                formData.append('code', code)
                formData.append('display', display)
                formData.append('note', note)

                const response = await postKeluhanUtama(accessToken, formData)
                console.log(response);
                if (response.status === 200) {
                    setOpenModal(false)
                    setRefresh(true)
                } else {
                    alert('ada yang salah..!')
                }
            }

        } catch (error) {
            alert('Silahkan periksa Koneksi internet Anda..!')
        }

        setLoading(false)
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? `w-full delay-200 py-[70px]` : 'w-0 h-0 overflow-hidden delay-0'} `}>

                <div className="w-full absolute z-10 top-0 text-center  h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Keluhan Utama Pasien</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full space-y-3 px-3 overflow-auto scrollbar-thin scrollbar-track-custom_green scrollbar-thumb-custom_orange">
                    <a href="https://browser.ihtsdotools.org/?perspective=full&conceptId1=472969004&edition=MAIN/2024-06-01&release=&languages=en" target="_blank" className="text-custom_green hover:text-green-600 font-semibold text-center transition-colors">
                        Klik link ini untuk mencari data snomed / kondisi pasien
                    </a>
                    <CustomInputV2 id={"code"}
                        value={code} setValue={setCode}
                        name={"Code Kondisi Pasien"} errorMessage={errorCode} />

                    <CustomInputV2 id={"display"}
                        value={display} setValue={setDisplay}
                        name={"Display Kondisi Pasien"} errorMessage={errorDisplay} />

                    <div className="flex flex-col">
                        <label htmlFor="note" className="font-semibold">Keterangan</label>
                        <textarea id="note" cols={5}
                            value={note} onChange={(e) => setNote(e.target.value)}
                            className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md"></textarea>
                    </div>


                    <div className="flex items-center space-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false)
                            }}
                            className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white">
                            Kembali
                        </button>
                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 hover:bg-green-600 py-2 rounded-md font-semibold text-white">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="w-full absolute bottom-0 text-center border-t-2 h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalKeluhanUtama