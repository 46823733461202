import { faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { postStaff } from "../../../../services/staff_service"
import CustomSelect from "../../../../components/custom_select"
import CustomInput from "../../../../components/custom_input"
import CustomInputV2 from "../../../../components/custom_input_v2"

const ModalCreateStaff = ({ openModal, setOpenModal, setRefresh, jabatan }) => {

    const [position, setPosition] = useState('admin')
    const [nik, setNik] = useState('')
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const dataJabatan = jabatan ?? []

    const [errorNik, setErrorNik] = useState(null)
    const [errorName, setErrorName] = useState(null)
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)
    const [errorPassword, setErrorPassword] = useState(null)

    const handleShowPassword = () => {
        if (showPassword === true) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const token = localStorage.getItem('access_token');
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorNik(null)
            setErrorName(null)
            setErrorPhoneNumber(null)
            setErrorEmail(null)
            setErrorPassword(null)

            const validPositions = ['super admin', 'admin', 'dokter umum', 'dokter gigi', 'farmasi', 'asisten farmasi'];
            let validatedEmail = email;
            let validatedPassword = password;

            if (!validPositions.includes(position)) {
                validatedEmail = null;
                validatedPassword = null;
            }
            // else {
            //     if (!email) {
            //         setErrorEmail('email harus diisi..!')
            //     } else if (!password) {
            //         setErrorPassword('password harus diisi..!')
            //     }
            // }

            if (nik.length === 0) {
                setErrorNik('nik tidak boleh kosong..!')
            } else if (name.length === 0) {
                setErrorName('nama tidak boleh kosong..!')
            } else if (name.length < 3) {
                setErrorName('nama minimal harus terdiri dari 3 huruf..!')
            } else if (phoneNumber.length === 0) {
                setErrorPhoneNumber('no telepon tidak boleh kosong..!')
            } else if (phoneNumber.length < 12 || phoneNumber.length > 13) {
                setErrorPhoneNumber('no telepon harus terdiri dari 12-13 angka..!')
            } else if (['super admin', 'admin', 'dokter umum', 'dokter gigi', 'farmasi', 'asisten farmasi'].includes(position) && email.length === 0) {
                setErrorEmail('email harus diisi..!')
            } else if (['super admin', 'admin', 'dokter umum', 'dokter gigi', 'farmasi', 'asisten farmasi'].includes(position) && password.length === 0) {
                setErrorPassword('password harus diisi..!')
            } else if (['super admin', 'admin', 'dokter umum', 'dokter gigi', 'farmasi', 'asisten farmasi'].includes(position) && password.length < 8) {
                setErrorPassword('password minimal harus memliki 8 karakter..!')
            } else {
                const body = new FormData();
                body.append('position', position)
                body.append('nik', nik)
                body.append('name', name)
                body.append('phone_number', phoneNumber)
                body.append('email', validatedEmail)
                body.append('password', validatedPassword)

                const response = await postStaff(token, body)
                setOpenModal(false)
                setRefresh(true)
                if (response.status === 200) {
                } else {
                    alert('error')
                }
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const handleChangePosition = (e) => {
        setPosition(e.target.value)
        if (e.target.value !== 'super admin' || e.target.value !== 'admin' || e.target.value !== 'farmasi' || e.target.value !== 'dokter umum' || e.target.value !== 'dokter gigi' || e.target.value !== 'perawat') {
            setEmail(null)
            setPassword(null)
        }
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div
                className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? `w-full max-h-[90%] delay-200 ` : 'w-0 max-h-0 overflow-hidden delay-0'}`}>
                <div className="absolute  w-full h-12 text-center border-b-2 font-semibold bg-orange-600 text-white flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data Staf</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full py-14 space-y-3 px-3 overflow-auto text-sm">

                    <CustomSelect name={`Jabatan`} value={position} onChange={handleChangePosition} list={dataJabatan} />

                    <CustomInputV2
                        type={'number'}
                        id={'nik'} name={`Nik`}
                        value={nik} onchangeValue={(e) => {
                            if (e.target.value.length < 17) {
                                setNik(e.target.value)
                            }
                        }}
                        placeholder="1234567898765432"
                        errorMessage={errorNik}
                    />

                    <CustomInputV2
                        type={'text'}
                        id={'name'} name={`Nama Lengkap`}
                        value={name} setValue={setName}
                        placeholder="nama"
                        errorMessage={errorName}
                    />

                    <CustomInputV2
                        type={'number'}
                        id={'phoneNumber'} name={`No Telepon`}
                        value={phoneNumber} onchangeValue={(e) => {
                            if (e.target.value.length < 14) {
                                setPhoneNumber(e.target.value)
                            }
                        }}
                        placeholder="083812341234"
                        errorMessage={errorPhoneNumber}
                    />

                    {['admin', 'super admin', 'farmasi', 'asisten farmasi', 'dokter umum', 'dokter gigi', 'perawat'].includes(position) ?
                        <>
                            <CustomInputV2
                                type={'email'}
                                id={'email'} name={`Email`}
                                value={email} setValue={setEmail}
                                placeholder="contohemail@gmail.com"
                                errorMessage={errorEmail}
                            />

                            <div className="flex flex-col">
                                <label htmlFor="password" className="font-semibold">Password</label>
                                <div className="w-full flex items-center relative">
                                    <input id="password" type={showPassword === true ? 'text' : 'password'} value={password} onChange={(e) => { setPassword(e.target.value) }} className="w-full px-3 py-2 rounded-md outline-none border-2 transition-colors focus:border-green-500" />
                                    <button type="button" onClick={handleShowPassword} className={`px-3 py-2 absolute right-0 mt-1 hover:text-green-600 ${showPassword === true ? 'text-green-500' : 'text-black'}`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </div>
                            </div>
                        </> : ''
                    }

                    <div className="flex items-center space-x-3">
                        <button type="button" onClick={() => setOpenModal(false)} className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white transition-colors">
                            KEMBALI
                        </button>
                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-custom_green hover:bg-green-600 py-2 rounded-md font-semibold text-white transition-colors">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="absolute h-12 bottom-0 w-full text-center font-semibold bg-orange-600 text-white flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalCreateStaff