import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../../components/layouts"
import { faEdit, faEye, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useRef, useState } from "react"
import { deleteStaff, getStaff, searchStaff } from "../../../services/staff_service"
import LoadingStaf from "../../../components/loadings/loading_staf"
import { useNavigate } from "react-router-dom"
import ModalCreateStaff from "./modals/create_staff"
import ModalUpdateStaff from "./modals/update_staff"
import Header from "../../../components/header"
import CardStaff from "../../../components/card/card_staff"
import Pagination from "../../../components/pagination"
import CustomRefPopper from "../../../components/custom_ref_poper"
import { getUserRole } from "../../../services/user_role_service"
import MobilePagination from "../../../components/mobile_pagination"

const Staff = () => {
    const [openDropdown, setOpenDropdown] = useState(false)
    const [openModalCreate, setOpenModalCreate] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [category, setCategory] = useState('semua')
    const popupRef = useRef(null);
    const [userRole, setUserRole] = useState('')

    const [staffId, setStaffId] = useState(null)

    const navigate = useNavigate()

    const [accessToken, setAccessToken] = useState('')

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)

    let [number, setNumnber] = useState(1)
    let [numberV2, setNumnberV2] = useState(1)

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)


    const fetchData = async (token, currentPage) => {
        setLoading(true);
        try {
            const response = await getStaff(token, category, currentPage);
            console.log(response);
            switch (response.status) {
                case 401:
                    navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    setTotalData(response.data.meta.total);
                    setCurrentPage(response.data.meta.current_page);
                    setLastPage(response.data.meta.last_page);

                    setNumnber(response.data.meta.current_page);
                    setNumnberV2(response.data.meta.current_page);
                    break;
            }
        } catch (error) {
            alert('silahkan cek koneksi internet Anda..!')
        }
        setLoading(false);
        setRefresh(false)
    };

    const [keyword, setKeyword] = useState('')

    const searchData = async (token) => {
        setLoading(true);
        try {
            const response = await searchStaff(token, keyword);
            switch (response.status) {
                case 401:
                    navigate('/login')
                    break;

                case 403:
                    navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    setTotalData(response.data.meta.total);
                    setCurrentPage(response.data.meta.current_page);
                    setLastPage(response.data.meta.last_page);

                    setNumnber(response.data.meta.current_page);
                    setNumnberV2(response.data.meta.current_page);
                    break;

                default:
                    navigate('/404')
                    break;
            }
        } catch (error) {
            alert('silahkan cek koneksi internet Anda..!')
        }
        setLoading(false);
        setRefresh(false)
    };

    const [categories, setCategories] = useState([])
    const fetchUserRole = async () => {
        setLoading(true);
        try {
            const response = await getUserRole(localStorage.getItem('access_token'))
            if (response.status === 200) {
                setCategories(response.data.data);
            }
        } catch (error) {
            alert('silahkan cek koneksi internet Anda..!')
        }
        setLoading(false);
        setRefresh(false)
    };

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, currentPage);
        }
        // eslint-disable-next-line
    }, [refresh]);


    useEffect(() => {
        const accessToken = localStorage.getItem('access_token')
        const userRole = localStorage.getItem('user_role')
        setUserRole(userRole)

        setAccessToken(accessToken)
        fetchData(accessToken, currentPage)
        fetchUserRole()

        // eslint-disable-next-line
    }, [accessToken, currentPage])

    const handleSearch = (e) => {
        e.preventDefault()
        setLoading(true)
        searchData(accessToken)
        setLoading(false)
    }

    const handleDelete = async (stafId) => {
        try {
            const response = await deleteStaff(accessToken, stafId);
            if (response.status !== 200) {
                alert('gagal hapus data..!')
            } else {
                setRefresh(true)
            }
        } catch (error) {
            alert('silahkan periksa koneksi internet anda..!')
        }
    };

    return (
        <Layout
            modal={
                <>
                    <ModalCreateStaff
                        jabatan={categories}
                        category={category}
                        openModal={openModalCreate}
                        setOpenModal={setOpenModalCreate}
                        setRefresh={setRefresh} />

                    <ModalUpdateStaff
                        category={category}
                        staffId={staffId}
                        openModal={openModalUpdate}
                        setOpenModal={setOpenModalUpdate}
                        setRefresh={setRefresh} />
                </>
            }>

            <Header>
                <p className="font-semibold text-base lg:text-xl uppercase">DATA Staf {category === 'semua' ? '' : category}</p>
                {/* <div className="hidden lg:flex items-center relative">
                            <input type="text" className="outline-none border-2 rounded-l-md px-3 py-2 text-black" />
                            <button type="submit" className="bg-green-500 px-3 py-2 rounded-r-md border-2 border-green-500">
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </div> */}
                <div></div>
                <div className="flex items-center space-x-3">
                    <p>{totalData}</p>
                    <p>{category}</p>
                </div>
            </Header>

            {/* Sub Header */}
            <div className="w-full py-2 flex items-end justify-between px-3 md:px-5 text-sm space-x-3 sticky top-16 bg-white border-b-2 md:border-b-0 border-custom_green z-20">

                {/* search */}
                <form onSubmit={handleSearch} className="flex items-center relative w-full lg:w-fit">
                    <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full lg:w-[220px] h-[40px] outline-none border-2 rounded-l-md px-3 py-2 text-black text-sm" />
                    <button type="submit" className="bg-green-500 px-3 h-[40px] rounded-r-md border-2 border-green-500 text-sm text-white font-semibold">
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </form>

                <CustomRefPopper setOpenPopper={setOpenDropdown} popperRef={popupRef} />

                {/* Button Filter & Add */}
                <div className="flex items-center space-x-3 " ref={popupRef}>
                    <div className="w-fit flex flex-col items-end relative">
                        <button type="button" onClick={() => setOpenDropdown(!openDropdown)} className=" w-fit md:w-[150px] flex items-center justify-center bg-custom_green hover:bg-custom_green text-white font-semibold space-x-3 px-3 h-[40px] rounded-md border-2 border-custom_green">
                            <FontAwesomeIcon icon={faFilter} />
                            <p className="hidden md:flex capitalize">Filter ({category}) </p>
                        </button>
                        <div className={`absolute top-10 bg-black/30 mt-3 w-[200px] z-10 rounded-xl justify-self-end backdrop-blur-sm inline-flex flex-col justify-center space-y-3 transition-all duration-500 overflow-hidden ${openDropdown === true ? `${userRole === 'super admin' ? 'max-h-screen' : 'max-h-screen'} p-3` : 'max-h-0 mt-0 p-0'}`}>
                            <button
                                type="button"
                                onClick={() => {
                                    setCategory('semua')
                                    setOpenDropdown(false)
                                    setRefresh(true)
                                }}
                                className={`text-black font-semibold bg-gray-100 rounded-full hover:bg-green-500 hover:text-white transition-all ${category === 'semua' ? 'bg-green-500 text-white' : ''}`}>
                                Semua
                            </button>
                            {categories.map((item) => (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCategory(item.name)
                                        setOpenDropdown(false)
                                        setRefresh(true)
                                    }}
                                    className={`text-black font-semibold bg-gray-100 rounded-full hover:bg-green-500 hover:text-white transition-all ${category === item.name ? 'bg-green-500 text-white' : ''}`}>
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Button add */}
                    <button type="button" onClick={() => setOpenModalCreate(true)} className="flex items-center bg-custom_green hover:bg-custom_green text-white font-semibold space-x-3 px-3 w-fit md:w-[150px] h-[40px] rounded-md border-2 border-custom_green">
                        <FontAwesomeIcon icon={faPlus} />
                        <p className="hidden md:flex capitalize">Tambah Staf</p>
                    </button>
                </div>
            </div>

            {/* Content */}
            <div className="px-3 md:px-5 hidden md:block">
                <div className="w-full overflow-auto">
                    <table className="w-full min-w-max max-w-screen-xl">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 rounded-tl-xl w-fit">No</th>
                                <th className="px-3 py-2">Foto Profil</th>
                                <th className="px-3 py-2">Nama</th>
                                <th className="px-3 py-2">Email</th>
                                <th className="px-3 py-2">Jabatan</th>
                                <th className="px-3 py-2 rounded-tr-xl" colSpan={3}>Aksi</th>
                            </tr>
                        </thead>
                        <tbody>

                            {loading === true || refresh === true ?
                                <>
                                    {Array.from({ length: 20 }).map(() => (
                                        <tr className=" animate-pulse">
                                            <td className="px-3 py-2">
                                                <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                            </td>
                                            <td className="px-3 py-2">
                                                <div className="h-16 aspect-square rounded-full bg-gray-300 mx-auto"></div>
                                            </td>
                                            {Array.from({ length: 6 }).map(() => (
                                                <td className="px-3 py-2">
                                                    <div className="w-full h-5 rounded-full bg-gray-300"></div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </> :
                                <>
                                    {data?.map((item) => (
                                        <tr className="hover:bg-slate-200">
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2">
                                                <div className="h-16 aspect-square rounded-full bg-gray-500 mx-auto"></div>
                                            </td>
                                            <td className="px-3 py-2 capitalize">{item.name}</td>
                                            <td className="px-3 py-2">{item.email ?? "-"}</td>
                                            <td className="px-3 py-2 uppercase">{item.user_role}</td>

                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </button>
                                            </td>
                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" onClick={() => {
                                                    setOpenModalUpdate(true)
                                                    setStaffId(item.id)
                                                }} className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td>
                                            <td className="  px-1 py-2 h-full w-16">
                                                <button type="button" onClick={() => handleDelete(item.id)} className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            }
                            <Pagination
                                currentPage={currentPage} setCurrentPage={setCurrentPage}
                                lastPage={lastPage} setRefresh={setRefresh} colSpan={8} />
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-full py-3 px-3 space-y-3 md:hidden">
                {loading === true || refresh === true ? <>
                    <LoadingStaf />
                </> :
                    <>
                        {data?.map((item) => (
                            <CardStaff
                                id={item.id}
                                name={item.name}
                                email={item.email}
                                position={item.user_role}
                                number={numberV2++}
                                profile={item.image}
                                setStaffId={setStaffId}
                                setOpenModalUpdate={setOpenModalUpdate} />
                        ))}

                        <MobilePagination
                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                            lastPage={lastPage} setRefresh={setRefresh} />
                    </>
                }
            </div>
        </Layout>
    )
}

export default Staff