import { PDFViewer } from "@react-pdf/renderer"
import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import DocumentResep from "./document"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { showResep } from "../../../../services/resep_service"
import Header from "../../../../components/header"
import BackButton from "../../../../components/buttons/back_button"

const ViewDocumentResep = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const backLink = searchParams.get('back_link');

    let number = 1;


    const fetchData = async () => {
        try {
            const response = await showResep(
                localStorage.getItem('access_token'),
                id
            )
            console.log(response);
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    break;

                default:
                    navigate('/404')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <>
            <Helmet>
                <title>Dokument resep</title>
            </Helmet>
            <Layout>
                <Header>
                    <BackButton link={backLink} />
                    <p>STRUK RESEP</p>
                </Header>
                <div className="w-full h-full flex items-center justify-center -mt-16 pt-16">
                    {data ? <PDFViewer className="w-full h-full">
                        <DocumentResep data={data} />
                    </PDFViewer> : <p>Loading....</p>}
                </div>
            </Layout>
        </>
    )
}

export default ViewDocumentResep