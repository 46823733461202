import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import Header from "../../../../components/header"
import { Link, useHref, useNavigate } from "react-router-dom"
import { getPengadaanObat } from "../../../../services/pengadaan_obat_service"
import { useEffect } from "react"
import { parseISO, isAfter } from 'date-fns'
import CustomHour from "../../../../components/custom_hour"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomCurrency from "../../../../components/custom_currency"

const PengadaanObat = () => {
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const navigate = useNavigate()
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const currentPath = useHref()


    let [number, setNumber] = useState(1);
    // let [numberV2, setNumberV2] = useState(1);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchData = async () => {
        setLoading(true)
        const response = await getPengadaanObat(accessToken, currentPage)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'admin', 'farmasi'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [currentPath, navigate]);
    return (
        <>
            <Helmet>
                <title>Pengadaan Obat</title>
            </Helmet>
            <Layout>
                <Header>
                    <p className="uppercase">Pengadaan Obat - Klinik Yasinta</p>
                </Header>

                <div className="w-full flex items-center justify-end px-5 py-3">
                    <Link to={`/dashboard/obat/pengadaan/create`} className="px-3 py-2 bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                        <FontAwesomeIcon icon={faPlus} />
                        <p className="capitalize">pengadaan Baru</p>
                    </Link>
                </div>

                <div className="w-full px-5 pb-10 overflow-auto ">
                    <table className="w-full min-w-max max-w-screen-xl text-sm">
                        <thead>
                            <tr className="bg-green-500 text-white">
                                <th className="px-3 py-2 text-center rounded-tl-xl">Aksi</th>
                                <th className="px-3 py-2 text-center ">No</th>
                                <th className="px-3 py-2 text-center" colSpan={2}>Waktu / Tanggal</th>
                                <th className="px-3 py-2 text-center">Kode</th>
                                <th className="px-3 py-2 text-center">Jumlah Pengadaann</th>
                                <th className="px-3 py-2 text-center">Total Pengadaan</th>
                                <th className="px-3 py-2 text-center rounded-tr-xl">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading === true ? 'Loading...' :
                                totalData === 0 ? 'Tidak ada data..!' :
                                    data.map((item) => (
                                        <tr className="hover:bg-gray-200 transition-colors">
                                            <td className="  px-1 py-2 h-full w-16">
                                                <Link to={`detail/${item?.id}`} className=" w-full flex items-end justify-center bg-green-300 hover:bg-green-400 transition-colors rounded-md py-1">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Link>
                                            </td>
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2 ">
                                                <CustomHour data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2 ">
                                                <CustomDayName data={item.created_at} />,&nbsp;
                                                <CustomDay data={item.created_at} />-
                                                <CustomMonth data={item.created_at} />-
                                                <CustomYear data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2 text-center font-semibold">{item?.code}</td>
                                            <td className="px-3 py-2 text-center">{item?.item_pengadaan?.length}</td>
                                            <td className="px-3 py-2 text-end">
                                                <CustomCurrency number={item?.total_price} />
                                            </td>
                                            <td className="px-3 py-2 text-end ">
                                                <div className={`font-semibold text-white px-3 rounded-full text-center ${item?.status === 'dalam proses' ? 'bg-yellow-500' : item?.status === 'selesai' ? 'bg-custom_green' : item?.status === 'dibatalkan' ? 'bg-red-500' : ''}`}>
                                                    {item?.status}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                    <div className="w-full h-10 flex items-center justify-end bg-green-500">

                    </div>
                </div>

            </Layout>
        </>
    )
}

export default PengadaanObat