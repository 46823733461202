import { Document, Image, Page, StyleSheet, Text, View, Ta } from "@react-pdf/renderer"
import Logo from '../../../../assets/icons/logo_yasinta.png'
import { TD, TH, TR, Table, TableRow } from "@ag-media/react-pdf-table";

const SalaryInvoice = () => {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white',
            padding: '20px'
        },

        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            textAlign: 'center',
            borderBottomWidth: 2,
            borderBottomColor: 'black'

        },

        heading: {
            textAlign: 'center',
            fontWeight: 'bold',
            borderBottomWidth: 2,
            borderBottomColor: 'black',
            paddingBottom: '10px'
        },

        contentTab: {
            fontSize: '10pt',
            display: 'flex',
            flexDirection: 'row',
            margin: '5px 0px'
        },

        rowHeader: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: 1,
            borderBottom: 1,
            borderColor: '#AAAAAA',
        },

        row: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        rowBottom: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: '#AAAAAA',
        },

        cell: {
            padding: 5,
            fontSize: '10pt',
            textAlign: 'center'
        },

        cell1: {
            width: '20px',
            padding: 5,
            fontSize: '10pt',
            textAlign: 'center'
        },

        cell2: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'start'
        },

        cell3: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'right'
        },

        cell4: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'right'
        }
    });
    return (
        <Document >
            <Page size="A5" style={styles.page}>
                <View style={{ marginBottom: '5px', flexDirection: 'row', alignItems: 'center' }}>
                    <Image src={Logo} style={{ height: '60px', width: '60', objectFit: 'content' }} />
                    <Text style={styles.heading}>INVOICE GAJI - KLINIK YASINTA</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-5px',  }}>
                    <Text style={{ fontSize: '10pt' }}>12 April 2024</Text>
                </View>
                <View style={styles.contentTab}>
                    <Text style={{ width: '100px' }}>Nama Lengkap</Text>
                    <Text style={{ width: '10px' }}>:</Text>
                    <Text style={{}}>Nama Staf</Text>
                </View>
                <View style={styles.contentTab}>
                    <Text style={{ width: '100px' }}>Jabatan</Text>
                    <Text style={{ width: '10px' }}>:</Text>
                    <Text style={{}}>Nama Staf</Text>
                </View>

                <View style={styles.rowHeader}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>No</Text>
                        <Text style={styles.cell2}>Keterangan</Text>
                    </View>xa
                    <Text style={styles.cell}>Jumlah</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>1</Text>
                        <Text style={styles.cell2}>Gaji Poko</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>2</Text>
                        <Text style={styles.cell2}>Penanggung Jawab</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>3</Text>
                        <Text style={styles.cell2}>Pelayanan</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>4</Text>
                        <Text style={styles.cell2}>Transport</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>5</Text>
                        <Text style={styles.cell2}>Piket Malam</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>6</Text>
                        <Text style={styles.cell2}>Jumsih</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>7</Text>
                        <Text style={styles.cell2}>Sosmed</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>8</Text>
                        <Text style={styles.cell2}>Tratment</Text>
                    </View>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>-</Text>
                        <Text style={styles.cell2}>Skincare</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>-</Text>
                        <Text style={styles.cell2}>Lainnya</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>9</Text>
                        <Text style={styles.cell2}>Cicilan</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>10</Text>
                        <Text style={styles.cell2}>Izin</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.rowBottom}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>11</Text>
                        <Text style={styles.cell2}>Potongan / Pajak</Text>
                        
                    </View>
                    <Text style={styles.cell4}>Rp. 2.500.000</Text>
                </View>
                <View style={styles.row}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell3}>Total Gaji</Text>
                    </View>
                    <Text style={styles.cell}>Rp. 2.500.000</Text>
                </View>
                <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px'
                 }}>
                    <View style={{width : '180px'}}>
                        <Text style={styles.cell}>KLINIK KESEHATAN DAN SKINCARE</Text>
                        <Text style={{textAlign: 'center', marginTop: '50px', fontSize: '10pt', borderBottom:1, borderBottomColor: '#AAAAAA'}}>( Dr. Sinatryana Bahran )</Text>
                        <Text style={styles.cell}>DIREKTUR </Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default SalaryInvoice