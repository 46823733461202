import { faAnglesLeft, faAnglesRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const MobilePagination = ({ currentPage, setCurrentPage, lastPage, setRefresh}) => {
    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            setRefresh(true)
        }
    }

    const handleNext = () => {
        if (currentPage < lastPage) {
            setCurrentPage(currentPage + 1)
            setRefresh(true)
        }
    }
    return (
        <div className="md:hidden w-full bg-custom_green flex items-center justify-between text-white font-semibold text-sm">
            <button type="button" onClick={handlePrevious} className="flex items-center space-x-3 px-3 py-2 hover:text-custom_orange transition-colors">
                <FontAwesomeIcon icon={faAnglesLeft} />
                <p>Sebelumnya</p>
            </button>
            <div className="flex items-center space-x-2">
                {currentPage > 2 ? <>
                    <button
                        type="button"
                        onClick={() => {
                            setCurrentPage(currentPage - 2)
                            setRefresh(true)
                        }}
                        className="px-3 py-1 bg-white text-black rounded-md hover:bg-custom_orange hover:text-white transition-colors">
                        {currentPage - 2}
                    </button>
                </> : ''
                }
                {currentPage > 1 ? <>
                    <button
                        type="button"
                        onClick={() => {
                            setCurrentPage(currentPage - 1)
                            setRefresh(true)
                        }}
                        className="px-3 py-1 bg-white text-black rounded-md hover:bg-custom_orange hover:text-white transition-colors">
                        {currentPage - 1}
                    </button>
                </> : ''}

                <button type="button" className="px-3 py-1 bg-custom_orange rounded-md">
                    {currentPage}
                </button>

                {currentPage < lastPage ? <>
                    <button
                        type="button"
                        onClick={() => {
                            setCurrentPage(currentPage + 1)
                            setRefresh(true)
                        }}
                        className="px-3 py-1 bg-white text-black rounded-md hover:bg-custom_orange hover:text-white transition-colors">
                        {currentPage + 1}
                    </button>
                </> : ''}

                {currentPage < lastPage ? currentPage + 2 > lastPage ? '' :
                    <>
                        <button
                            type="button"
                            onClick={() => {
                                setCurrentPage(currentPage + 2)
                                setRefresh(true)
                            }}
                            className="px-3 py-1 bg-white text-black rounded-md hover:bg-custom_orange hover:text-white transition-colors">
                            {currentPage + 2}
                        </button>
                    </> : ''}
            </div>
            <button type="button" onClick={handleNext} className="flex items-center space-x-3 px-3 py-2 hover:text-custom_orange transition-colors">
                <p>Selanjutnya</p>
                <FontAwesomeIcon icon={faAnglesRight} />
            </button>
        </div>


    )
}

export default MobilePagination