import apiService from "./api_service"


export const postResepPayment = async (accessToken, formData) => {
    try {
        const response = await apiService.post(`/resep-payment/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

