import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Link, useHref, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { postPengadaanBarang } from "../../../../services/pengadaan_barang_service"
import { parseISO, isAfter } from "date-fns"
import CustomCurrency from "../../../../components/custom_currency"
import CustomSprinner from "../../../../components/custom_spinner"

const InventoryPengadaanBaru = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)

    let number = 1

    const [items, setItems] = useState([
        {
            name: "",
            price: 0,
            quantity: 1
        }
    ]);

    const handleAddItem = () => {
        setItems([...items, { name: "", price: 0, quantity: 1 }]);
    };

    const handleRemoveItem = (index) => {
        if (items.length > 1) {
            const values = [...items]
            values.splice(index, 1)
            setItems(values)
        }
    }

    const formatRupiah = (number) => {
        return new Intl.NumberFormat("id", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0
        }).format(number)
    }

    const handleChange = (index, event) => {
        const { name, value } = event.target
        const values = [...items]
        if (name === 'price') {
            values[index][name] = formatRupiah(value)
        } else {
            values[index][name] = value
        }
        values[index][name] = value
        setItems(values)
    }



    const handleSubmit = async (e) => {
        e.preventDefault()
        const body = items.map(item => ({
            name: item.name,
            price: item.price,
            quantity: item.quantity
        }))
        const response = await postPengadaanBarang(accessToken, body)
        console.log(response);
        if (response.status === 200) {
            navigate('/dashboard/inventory/pengadaan-barang')
        } else if (response.status === 403) {
            navigate(`/403?back=${currentPath}`)
        } else {
            alert('silahkan perikas koneksi internet Anda..!')
        }
    }

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (userRole !== 'super admin' && userRole !== 'admin') {
                navigate('/403')
            } else {

            }
        }
    }, [currentPath, navigate]);

    const getTotalHargaKeseluruhan = () => {
        return items.reduce((total, item) => {
            const price = item.price ?? 0
            const quantity = item.quantity ?? 0
            return total + (price * quantity)
        }, 0)
    }

    return (
        <>
            <Helmet>
                <title>Pengadaan Baru - KLINIK YASINTA</title>
            </Helmet>
            <Layout>
                <form onSubmit={handleSubmit}>
                    <Header>
                        <Link to="/dashboard/inventory/pengadaan-barang" className="flex items-center space-x-3">
                            <FontAwesomeIcon icon={faChevronCircleLeft} />
                            <p>Kembali</p>
                        </Link>
                        <p>Pengadaan Baru</p>
                        <button type="submit" className="px-3 py-2 bg-custom_green flex items-center space-x-3 rounded-md">
                            {loading === true ? <CustomSprinner /> :
                                <>
                                    <FontAwesomeIcon icon={faSave} />
                                    <p>Simpan</p>
                                </>}
                        </button>
                    </Header>
                    <div className="w-full px-5 space-y-3 pt-3 overflow-auto">
                        <button onClick={handleAddItem} type="button" className="px-3 py-2 bg-custom_green flex items-center space-x-3 rounded-md font-semibold text-white">
                            <FontAwesomeIcon icon={faPlus} />
                            <p>Tambah Item</p>
                        </button>

                        <table className=" w-full min-w-max max-w-screen-xl">
                            <thead>
                                <tr className="border-b-2 border-custom_green  text-white">
                                    <th className="px-3 py-2 text-center bg-custom_green rounded-tl-xl">Aksi</th>
                                    <th className="px-3 py-2 text-center bg-custom_green">No</th>
                                    <th className="px-3 py-2 text-center bg-custom_green">Nama Barang</th>
                                    <th className="px-3 py-2 text-center bg-custom_green">Harga</th>
                                    <th className="px-3 py-2 text-center bg-custom_green">Jumlah</th>
                                    <th className="px-3 py-2 text-end bg-custom_green rounded-tr-xl">Total Harga</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr>
                                        <td className="px-1 py-1 text-center">
                                            <button
                                                type="button"
                                                onClick={() => handleRemoveItem(index)}
                                                className="bg-red-500 hover:bg-red-600 transition-colors px-3 py-2 text-white rounded-md">
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                        <td className="px-3 py-2">
                                            {number++}
                                        </td>
                                        <td className="px-3 py-1">
                                            <input
                                                type="text"
                                                name="name"
                                                value={item.name}
                                                placeholder="Nama barang"
                                                onChange={event => handleChange(index, event)}
                                                className="outline-none uppercase border-2 focus:border-custom_green rounded-md px-3 py-2" />
                                        </td>
                                        <td className="px-3 py-1">
                                            <input
                                                type="number"
                                                name="price"
                                                value={item.price}
                                                onChange={event => handleChange(index, event)}
                                                className="outline-none border-2 focus:border-custom_green rounded-md px-3 py-2" />
                                        </td>
                                        <td className="px-3 py-1">
                                            <input
                                                type="number"
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={event => handleChange(index, event)}
                                                className="outline-none border-2 focus:border-custom_green rounded-md px-3 py-2" />
                                        </td>
                                        <td>
                                            <CustomCurrency number={item.price * item.quantity} />
                                        </td>
                                    </tr>
                                ))}
                                <tr className="bg-custom_green">
                                    <td className="text-end font-semibold text-white px-3 py-2" colSpan={5}>
                                        Total Harga Keseuruhan :
                                    </td>
                                    <td className="text-end font-semibold text-white px-3 py-2">
                                        <CustomCurrency number={getTotalHargaKeseluruhan()} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default InventoryPengadaanBaru