const CustomLoading = ({ counter, length }) => {
    return (
        <>
            {Array.from({ length: counter ?? 1 }).map(() => (
                <tr className="hover:bg-gray-200 transition-colors animate-pulse">
                    {Array.from({ length: length ?? 10 }).map(() => (
                        <td className="px-3 py-2 text-center ">
                            <div className="w-full px-3 py-2 rounded-full bg-gray-300"></div>
                        </td>
                    ))}
                </tr>
            ))}
        </>
    )
}
export default CustomLoading