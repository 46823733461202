import Header from "../../../components/header"
import Layout from "../../../components/layouts"

const Keuangan = () => {

    return (
        <Layout

            children={
                <>
                      <Header children={
                            <>
                                <p className="uppercase">Keuangan - Klinik Yasinta</p>
                            </>
                        } />
                    <div className="w-full">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-3 py-2">No</th>
                                    <th className="px-3 py-2">Tanggal</th>
                                    <th className="px-3 py-2">Pemasukan</th>
                                    <th className="px-3 py-2">Pangeluaran</th>
                                    <th className="px-3 py-2">Keterangan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="hover:bg-gray-200">
                                    <td className="px-3 py-2 text-center">1</td>
                                    <td className="px-3 py-2">12/12/2024</td>
                                    <td className="px-3 py-2">Rp.2500.000</td>
                                    <td className="px-3 py-2">Rp.500.000</td>
                                    <td>-</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </>
            }
        />
    )
}

export default Keuangan