import apiService from "./api_service"

export const getStaff = async (token, category, page) => {
    try {

        const response = await apiService.get(`/staf?category=${category}&page=${page}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searchStaff = async (token, keyword) => {
    try {
        const response = await apiService.get(`/staf/search?keyword=${keyword}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const showStaff = async (token, staffId) => {
    try {

        const response = await apiService.get(`/staf/show/${staffId}`, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postStaff = async (token, formData) => {
    try {

        const response = await apiService.post(`/staf/create`, formData, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const updateStaff = async (token, staffId, name, email, password) => {
    try {

        const body = new FormData();
        body.append('name', name)
        body.append('email', email)
        body.append('password', password)

        const response = await apiService.post(`/staf/update/${staffId}`, body, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const deleteStaff = async (token, stafId) => {
    try {
        const response = await apiService.post(`/staf/delete/${stafId}`, {}, {
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}