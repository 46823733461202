import apiService from "./api_service"

export const getTarifLayananCategory = async (accessToken) => {
    try {

        const response = await apiService.get(`/tarif-layanan/category`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const getTarifLayanan = async (accessToken, category, page) => {
    try {

        const response = await apiService.get(`/tarif-layanan?category=${category}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searchTarifLayanan = async (accessToken, category, keyword, page) => {
    try {

        const response = await apiService.get(`/tarif-layanan/search?category=${category}&keyword=${keyword}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postTarifLayanan = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/tarif-layanan/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

