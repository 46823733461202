import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faEye, faFilter, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { Link, useHref, useNavigate } from "react-router-dom"
import { getPengadaanBarang } from "../../../../services/pengadaan_barang_service"
import { parseISO, isAfter } from "date-fns"
import CustomHour from "../../../../components/custom_hour"
import CustomDayName from "../../../../components/custom_day_name"
import CustomDay from "../../../../components/custom_day"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomCurrency from "../../../../components/custom_currency"
import LoadingPengadaanBarang from "../../../../components/loadings/loading_pengadaan_barang"
import Pagination from "../../../../components/pagination"

const InventoryPengadaanBarang = () => {
    const currentPath = useHref()
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    let [number, setNumber] = useState(1);
    // let [numberV2, setNumberV2] = useState(1);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    const fetchData = async () => {
        setLoading(true)
        const response = await getPengadaanBarang(accessToken, currentPage)
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setNumber(response.data.meta.from ?? 1)
            // setNumberV2(response.data.meta.from ?? 1)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page)
            setTotalData(response.data.meta.total)
        } else if (response.status === 403) {
            navigate('/403')
        } else {
            alert('Silahkan periksa koneksi internet Anda..!')
        }

        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchData()
        }
    }, [refresh])

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else if (!['super admin', 'admin'].includes(userRole)) {
                navigate('/403')
            } else {
                fetchData()
            }
        }
    }, [currentPath, navigate]);

    return (
        <>
            <Helmet>
                <title>Inventory Pengadaang Barang - Klinik Yasinta</title>
            </Helmet>
            <Layout
                children={
                    <>
                        <Header>
                            <p className="uppercase">Inventory Pengadaan Barang - Klinik Yasinta</p>
                            <Link to="/dashboard/inventory/pengadaan-barang/create" className="flex items-center space-x-3 bg-custom_green px-3 py-2 rounded-md text-white font-semibold text-center">
                                <FontAwesomeIcon icon={faPlus} />
                                <p>Pengadaan Baru</p>
                            </Link>
                        </Header>

                        {/* <div className="flex items-end justify-end px-5 py-3 ">
                            <button type="button" className="flex items-center space-x-3 bg-custom_green px-3 py-2 rounded-md text-white font-semibold">
                                <FontAwesomeIcon icon={faFilter} />
                                <p>Bulan Ini</p>
                            </button>
                        </div> */}

                        <div className="w-full px-5 pb-10 overflow-auto pt-5 scrollbar-thin scrollbar-track-custom_orange scrollbar-thumb-custom_green">
                            <table className="w-full min-w-max max-w-screen-xl">
                                <thead>
                                    <tr className="bg-green-500 text-white">
                                        <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                        <th className="px-3 py-2 text-start" colSpan={2}>Waktu & Tanggal</th>
                                        <th className="px-3 py-2 text-start">Nama Admin</th>
                                        <th className="px-3 py-2 text-start">Jumlah Barang</th>
                                        <th className="px-3 py-2 text-center">Total Pengadaan</th>
                                        <th className="px-3 py-2 text-center">Status</th>
                                        <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading === true ?
                                        Array.from({ length: 20 }).map(() => (
                                            <LoadingPengadaanBarang />
                                        ))
                                        :
                                        totalData === 0 ? 'tidak ada data' :
                                            data.map((item) => (
                                                <tr className="hover:bg-gray-200 transition-colors">
                                                    <td className="px-3 py-2 text-center">{number++}</td>
                                                    <td className="px-3 py-2 ">
                                                        <CustomHour data={item.created_at} />
                                                    </td>
                                                    <td className="px-3 py-2 ">
                                                        <CustomDayName data={item.created_at} />,&nbsp;
                                                        <CustomDay data={item.created_at} />-
                                                        <CustomMonth data={item.created_at} />-
                                                        <CustomYear data={item.created_at} />
                                                    </td>
                                                    <td className="px-3 py-2 ">{item?.admin?.name}</td>
                                                    <td className="px-3 py-2 text-center">{item?.barang.length} Barang</td>
                                                    <td className="px-3 py-2 text-end">
                                                        <CustomCurrency number={item.total_price} />
                                                    </td>
                                                    <td className="px-3 py-2 ">
                                                        <p className="bg-custom_orange rounded-full font-semibold text-white text-center px-3">
                                                            {item.status}
                                                        </p>
                                                    </td>
                                                    <td className="  px-1 py-2 h-full w-16">
                                                        <Link to={`detail/${item.id}`} className=" w-full py-1 bg-green-300 hover:bg-green-400 transition-colors rounded-md flex items-center justify-center">
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Link>
                                                    </td>
                                                    <td className="  px-1 py-2 h-full w-16">
                                                        <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                    </td>
                                                    <td className="  px-1 py-2 h-full w-16">
                                                        <button type="button" className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    }

                                    <Pagination
                                        currentPage={currentPage} setCurrentPage={setCurrentPage}
                                        lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default InventoryPengadaanBarang