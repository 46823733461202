import LogoutButton from "../../logout"
import NavLinkItem from "../../nav_link_item"

const ContentMenuFarmasi = () => {
    return (
        <>
            <NavLinkItem link="/dashboard/index" name="Dashboard" />
            <NavLinkItem link="/dashboard/kunjungan" name="Kunjungan" />
            <NavLinkItem link="/dashboard/pasien" name="Pasien" />
            <NavLinkItem link="/dashboard/obat/suplayer" name="Data Suplayer" />
            <NavLinkItem link="/dashboard/obat/pengadaan" name="Pengadaan Obat" />
            <NavLinkItem link="/dashboard/obat/monitoring" name="Data Obat" />
            {/* <NavLinkItem link="/dashboard/obat/stok-opname" name="Stok Opname Obat" /> */}
            <NavLinkItem link="/dashboard/obat/resep" name="Resep Dokter" />
            <NavLinkItem link="/dashboard/keuangan" name="Keuangan" />

            <LogoutButton />
        </>
    )
}

export default ContentMenuFarmasi