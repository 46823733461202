const Header = ({children}) => {
    return (
        <div className="w-full bg-white sticky top-0 z-20">
            <div className="w-full h-16 flex items-center lg:rounded-tl-3xl justify-between px-3 lg:px-5 bg-gradient-to-r from-custom_green to-orange-600 text-white font-semibold">
                {children}
            </div>
        </div>
    )
}

export default Header