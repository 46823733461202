import apiService from "./api_service"

export const getUserRole = async (accessToken) => {
    try {

        const response = await apiService.get(`/user-role`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searchUserRole = async (accessToken, keyword) => {
    try {

        const response = await apiService.get(`/user-role/search?keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}