const LoadingPengadaanBarang = () => {
    return (
        <tr className="hover:bg-gray-200 transition-colors animate-pulse">
            {Array.from({ length: 9 }).map(() => (
                <td className="px-3 py-2 text-center ">
                    <div className="w-full px-3 py-2 rounded-full bg-gray-300"></div>
                </td>
            ))}
        </tr>
    )
}
export default LoadingPengadaanBarang