import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useState } from "react"
import { getDrugs } from "../../../../../services/drug_service"
import CustomSelect from "../../../../../components/custom_select"
import CustomInput from "../../../../../components/custom_input"

const ModalAddObat = ({ openModal, setOpenModal, setRefresh, data, setData }) => {
    const [loading, setLoading] = useState(false)
    const [dataObat, setDataObat] = useState([])

    const [drugId, setDrugId] = useState('')
    const [name, setName] = useState('')
    const [unit, setUnit] = useState('tablet')
    const [price, setPrice] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [howToUse, setHowToUse] = useState('')

    const fetchDrugs = async () => {
        const response = await getDrugs(localStorage.getItem('access_token'))

        console.log(response);
        if (response.status === 200) {
            setDataObat(response.data.data)
        } else {

        }
    }


    useEffect(() => {
        fetchDrugs()
    }, [])

    const handleDrugChange = (e) => {
        const selectedItemId = e.target.value;
        const selectedItem = dataObat.find(item => item.id === selectedItemId);

        if (selectedItem) {
            setDrugId(selectedItem.id);
            setName(selectedItem.name);
            setUnit(selectedItem.unit ?? '-');
            setPrice(selectedItem.fixed_price);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const newList = [...data];
        // Tambahkan data baru ke dalam array
        newList.push({
            drug_id: drugId,
            unit: unit,
            name: name,
            price: price,
            quantity: quantity,
            total_price: price * quantity,
            how_to_use : howToUse
        });
        // Update state dengan data baru
        setData(newList);
        setOpenModal(false)
        setRefresh(true)
        setLoading(false)

    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[370px] md:h-[400px] lg:h-[420px]  delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data obat Baru</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full  px-3 overflow-auto py-[70px]">
                    <div className="w-full flex flex-col items-center justify-center space-y-3">
                        <div className="w-full flex flex-col">
                            <label htmlFor={'drug'} className="font-semibold text-xs md:text-sm lg:text-base">Pilih Obat</label>
                            <select id={'drug'} value={drugId} onChange={handleDrugChange} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md">
                                {dataObat.map((item) => (
                                    <option value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </div>

                        <CustomInput
                            type="number" id={`quantity`}
                            name={`Kuantitas`}
                            value={quantity} setValue={setQuantity} />

                        <CustomInput
                            id={`howtoUse`}
                            name={`Aturan Pake`}
                            value={howToUse} setValue={setHowToUse} />

                        <div className="w-full flex items-center space-x-3">
                            <button
                                type="button"
                                onClick={() => { setOpenModal(false) }}
                                className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 py-2 rounded-md font-semibold text-white">
                                Kembali
                            </button>

                            <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                                {loading === true ? <>
                                    <div className="spinner-wave ">
                                        <div className="spinner-wave-dot  bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                    </div>
                                </> : 'SIMPAN'}
                            </button>
                        </div>
                    </div>
                </form>
                <div className="absolute bottom-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalAddObat