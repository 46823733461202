import apiService from "./api_service"

export const getFinances = async (accessToken, category, page) => {
    try {

        const response = await apiService.get(`/finances?category=${category}&page=${page}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

