import { faClose, faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { showStaff, updateStaff } from "../../../../services/staff_service"

const ModalUpdateStaff = ({ category, staffId, openModal, setOpenModal, setRefresh }) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleShowPassword = () => {
        if (showPassword === true) {
            setShowPassword(false)
        } else {
            setShowPassword(true)
        }
    }

    const token = localStorage.getItem('access_token');

    const fetchData = async (token) => {
        try {
            const response = await showStaff(token, staffId);
            console.log(response.data.data.name);
            if (response.status === 200) {
                setName(response.data?.data?.name)
                setEmail(response.data?.data?.email)
            } else {
                alert('Data tidak ditemukan..!')
            }
        } catch (error) {
            alert('Silahkan periksa jaringan Anda..!')
        }
        setLoading(false);
        setRefresh(false)
    };

    useEffect(() => {
        if (openModal === true) {
            fetchData(token)
        }
        // disable
    }, [openModal])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await updateStaff(token, staffId, name, email, password)
            setOpenModal(false)
            setLoading(false)
            setRefresh(true)
            if (response.status === 200) {
            } else {
                alert('error')
            }

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[450px] lg:h-[500px] delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>
                <button
                    type="button"
                    onClick={() => { setOpenModal(false) }}
                    className="text-red-700 bg-red-200 w-fit aspect-square rounded-full absolute top-5 right-5 hover:bg-red-300 transition-colors">
                    <FontAwesomeIcon icon={faClose} className="w-10"></FontAwesomeIcon>
                </button>

                <div className="w-full text-center border-b-2 pt-12 pb-3 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Update Data {category}</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-3 space-y-3 px-3">
                    <div className="flex flex-col">
                        <label htmlFor="name" className="font-semibold">Nama Lengkap</label>
                        <input id="name" placeholder="Nama Lengkap" type="text" value={name} onChange={(e) => { setName(e.target.value) }} className="px-3 py-2 rounded-md outline-none border-2 transition-colors focus:border-green-500" />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="email" className="font-semibold">Email</label>
                        <input id="email" placeholder="contoh@gmail.com" type="email" value={email} onChange={(e) => { setEmail(e.target.value) }} className="px-3 py-2 rounded-md outline-none border-2 transition-colors focus:border-green-500" />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="password" className="font-semibold">Password</label>
                        <div className="w-full flex items-center">
                            <input id="password" type={showPassword === true ? 'text' : 'password'} value={password} onChange={(e) => { setPassword(e.target.value) }} className="w-full px-3 py-2 rounded-md outline-none border-2 transition-colors focus:border-green-500" />
                            <button type="button" onClick={handleShowPassword} className={`px-3 py-3 absolute right-3 hover:text-green-600 ${showPassword === true ? 'text-green-500' : 'text-black'}`}>
                                <FontAwesomeIcon icon={faEye} />
                            </button>
                        </div>
                    </div>

                    <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-custom_green hover:bg-green-600 py-2 rounded-md font-semibold text-white transition-colors">
                        {loading === true ? <>
                            <div className="spinner-wave ">
                                <div className="spinner-wave-dot  bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                            </div>
                        </> : 'SIMPAN'}
                    </button>
                </form>

                <div className="w-full text-center border-t-2 pt-3 pb-12 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalUpdateStaff