import React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const Navlinks = ({ children, to, offset = -50, mobileMenu = false }) => {
    const handleLinkClick = () => {
        // Scroll to the top if it's not a mobile menu
        if (!mobileMenu) {
            scroll.scrollToTop();
        }
    };

    const classNames = mobileMenu
        ? 'text-lg text-custom_black w-full text-center hover:bg-[linear-gradient(90deg,_#72BB43_0%,_#FF542C_100%)]'
        : 'text-lg lg:text-xl cursor-pointer hover:bg-[linear-gradient(90deg,_#72BB43_0%,_#FF542C_100%)] hover:bg-clip-text hover:text-transparent';

    return (
        <ScrollLink
            to={to}
            spy={true}
            smooth={true}
            offset={offset}
            duration={1000}
            onClick={handleLinkClick}
            className={classNames}
        >
            {children}
        </ScrollLink>
    );
};

export default Navlinks;
