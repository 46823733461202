import LogoutButton from "../../logout"
import NavLinkItem from "../../nav_link_item"

const ContentMenuPerawat = () => {
    return (
        <>
            <NavLinkItem link="/dashboard/index" name="Dashboard" />

            <NavLinkItem link="/dashboard/kunjungan" name="Kunjungan" />
            <NavLinkItem link="/dashboard/pasien" name="Pasien" />
            <NavLinkItem link="/dashboard/ruangan" name="Ruangan" />

            <LogoutButton />
        </>
    )
}

export default ContentMenuPerawat