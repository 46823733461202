const CustomSelectAddress = ({ id, name, nameSelect, select, handeleChange, data, errorMessage }) => {
    return (
        <div className="flex flex-col ">
            <label htmlFor={id} className="capitalize">{name}</label>
            <select id={id} value={select} onChange={handeleChange} className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                <option value={null} className="text-center">{nameSelect}</option>
                {data.map((item) => (
                    <option value={item.code} name={item.name}>{item.name}</option>
                ))}
            </select>
            {errorMessage === null ? '' : <p className="text-red-500 text-sm">{errorMessage}</p>}
        </div>
    )
}

export default CustomSelectAddress