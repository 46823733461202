import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft, faEdit, faPlus, faSave, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import ModalAddObat from "./modals/add_obat"
import ModalUpdateResepList from "./modals/update_obat _obat"
import { postResep } from "../../../../services/resep_service"

const BuatResep = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const kunjungan_id = searchParams.get('kunjungan_id');
    const patient_id = searchParams.get('patient_id');
    const backlink = searchParams.get('back_link');


    const navigate = useNavigate()
    const [openModal, setOpenModal] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [index, setIndex] = useState('')
    const [note, setNote] = useState('')
    let number = 1;
    let numberV2 = 1;

    useEffect(() => {

    }, [refresh])

    const [resepList, setResepList] = useState([]);

    const hapusData = (index) => {
        // Buat array baru tanpa elemen yang akan dihapus
        const newList = resepList.filter((item, idx) => idx !== index);
        // Update state dengan array baru
        setResepList(newList);
    };

    const editData = (index, newData) => {
        // Buat array baru dengan data yang diubah
        const newList = [...resepList];
        newList[index] = newData;
        // Update state dengan array baru
        setResepList(newList);
    };

    const formData = {
        'note': note,
        'patien_id': patient_id,
        'kunjungan_id': kunjungan_id,
        'resep_item': resepList
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const response = await postResep(localStorage.getItem('access_token'), formData)
        console.log(response);
        if (response.status === 200) {
            navigate(`${backlink ?? "/dashboard/obat/resep"}`)
        }else{
            alert("ada kelalahan..!")
        }

        setLoading(false)
    }

    const totalHargaKeseluruhan = resepList.reduce((total, item) => total + item.total_price, 0);
    return (
        <>
            <Helmet>
                <title>Buat Resep</title>
            </Helmet>
            <Layout
                modal={
                    <>
                        <ModalAddObat
                            openModal={openModal} setOpenModal={setOpenModal}
                            setRefresh={setRefresh}
                            data={resepList}
                            setData={setResepList}
                        />

                        <ModalUpdateResepList
                            openModal={openModalUpdate} setOpenModal={setOpenModalUpdate}
                            setRefresh={setRefresh}
                            data={resepList}
                            setData={setResepList}
                            index={index}
                        />
                    </>
                }

                children={
                    <>
                        <form onSubmit={handleSubmit}>
                            <Header>
                                <Link to={backlink ?? "/dashboard/obat/resep"} className="flex items-center space-x-3 px-3 py-2" >
                                    <FontAwesomeIcon icon={faChevronCircleLeft} />
                                    <p className="hidden md:flex">Kembali</p>
                                </Link>

                                <p>Buat Resep</p>

                                <button type="submit" className="flex items-center space-x-3 px-3 py-2 bg-custom_green rounded-md hover:bg-green-600 transition-colors" >
                                    <FontAwesomeIcon icon={faSave} />
                                    <p className="hidden md:flex">Simpan</p>
                                </button>
                            </Header>

                            <div className="w-full px-5 py-5 space-y-3">
                                <button type="button" onClick={() => setOpenModal(true)} className="px-3 py-2 rounded-md bg-custom_green flex items-center space-x-3 text-white font-semibold">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p>Tambah Obat</p>
                                </button>
                                <div className="hidden md:block">
                                    <table className=" w-full">
                                        <thead>
                                            <tr className="border-b-2 border-custom_green  text-white">
                                                <th className="px-3 py-2 text-center bg-custom_green rounded-tl-xl" colSpan={2}>Aksi</th>
                                                <th className="px-3 py-2 text-center bg-custom_green">No</th>
                                                <th className="px-3 py-2 text-center bg-custom_green">Nama Obat</th>
                                                <th className="px-3 py-2 text-center bg-custom_green">Harga</th>
                                                <th className="px-3 py-2 text-center bg-custom_green" colSpan={2}>Jumlah Obat</th>
                                                <th className="px-3 py-2 text-end bg-custom_green rounded-tr-xlz">Total Harga</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resepList.length === 0 ?
                                                <tr>
                                                    <td className="px-3 py-2 text-center text-red-500">
                                                        -
                                                    </td>
                                                    <td className="px-3 py-2 text-center">0</td>
                                                    <td className="px-3 py-2">-</td>
                                                    <td className="px-3 py-2 text-center">-</td>
                                                    <td className="px-3 py-2 text-center">-</td>
                                                    <td className="px-3 py-2 text-end">-</td>
                                                </tr> :
                                                resepList.map((item, index) => (
                                                    <tr>
                                                        <td className="px-3 py-2 text-center text-red-500">
                                                            <button type="button" onClick={() => hapusData(index)}>
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                        <td className="px-3 py-2 text-center text-red-500">
                                                            <button type="button" onClick={() => {
                                                                setOpenModalUpdate(true)
                                                                setIndex(index)
                                                            }}>
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </td>
                                                        <td className="px-3 py-2 text-center">{number++}</td>
                                                        <td className="px-3 py-2">{item.name}</td>
                                                        <td className="px-3 py-2 text-center">Rp. {item.price}</td>
                                                        <td className="px-3 py-2 text-end">{item.quantity}</td>
                                                        <td className="px-3 py-2 text-start">{item.unit}</td>
                                                        <td className="px-3 py-2 text-end">Rp. {item.total_price}</td>
                                                    </tr>
                                                ))
                                            }
                                            <tr className="w-full border-t-2 border-custom_green font-bold bg-custom_green text-white">
                                                <td colSpan={7} className="text-end px-3 py-2">
                                                    Total yang harus dibayar
                                                </td>
                                                <td className="text-end px-3 py-2">
                                                    Rp. {totalHargaKeseluruhan}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <div className="flex items-center justify-between border-2 shadow-md px-3 py-2 rounded-xl text-sm">
                                    <div className="flex space-x-3">
                                        <div>
                                        <p>No</p>
                                        <p>{numberV2}</p>
                                        </div>
                                        <div>
                                            <p>Nama Obat</p>
                                            <p>Rp 250.000</p>
                                        </div>
                                    </div>
                                    <div className=" flex flex-col justify-end items-end">
                                        <p>12 Tablet</p>
                                        <p>Rp. 250.000</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="flex flex-col px-5">
                                <label htmlFor="keterangan">Keterangan</label>
                                <textarea id="keterangan" value={note} onChange={(e) => setNote(e.target.value)} cols={3} rows={5} className="px-3 py-2 border-2 focus:border-custom_green rounded-xl"></textarea>
                            </div>
                        </form>
                    </>
                }
            />
        </>
    )
}

export default BuatResep