import { Helmet } from "react-helmet"
import Layout from "../../../components/layouts"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../services/location_service"
import { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faSave } from "@fortawesome/free-solid-svg-icons"
import { postPatient } from "../../../services/patient_service"
import { Link, useNavigate } from "react-router-dom"
import Header from "../../../components/header"
import { getGenders } from "../../../services/gender_service"
import CustomSprinner from "../../../components/custom_spinner"
import { getWeddingStatus } from "../../../services/wedding_status_service"
import { parseISO, isAfter } from 'date-fns'
import CustomInputV2 from "../../../components/custom_input_v2"

const TambahPasien = () => {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const accessToken2 = localStorage.getItem('access_token2')
    const userRole = localStorage.getItem('user_role')
    const userName = localStorage.getItem('user_name')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [category, setCategory] = useState('non_bpjs')
    const [bpjsNumber, setBpjsNumber] = useState('');
    const [nik, setNik] = useState('');
    const [name, setName] = useState('');
    const [placeOfBirth, setPlaceOfBirth] = useState('');
    const [birthday, setBirthDay] = useState('');

    const [email, setEmail] = useState('ihsan@gmail.com');
    const [phoneNumber, setPhoneNumber] = useState('083101589902');

    const [genders, setGenders] = useState([]);
    const [genderName, setGenderName] = useState('laki-laki');
    const [genderDisplay, setGenderDisplay] = useState('male');

    const [patientStatus, setPatientStatus] = useState([]);
    const [patientStatusCode, setPatientStatusCode] = useState('U');
    const [patientStatusDisplay, setPatientStatusDisplay] = useState('unmerried');

    const [province, setProvince] = useState([])
    const [provinceId, setProvinceId] = useState('')

    const [regency, setRegency] = useState([])
    const [regencyId, setRegencyId] = useState('')
    const [regencyName, setRegencyName] = useState('')

    const [district, setDistrict] = useState([])
    const [districtId, setDistrictId] = useState('')

    const [village, setVillage] = useState([])
    const [villageId, setVillageId] = useState('')

    const [rt, setRt] = useState('01');
    const [rw, setRw] = useState('02');

    const [detail, setDetail] = useState('');

    const [errorBpjs, setErrorBpjs] = useState(null)
    const [errorNik, setErrorNik] = useState(null)
    const [errorName, setErrorName] = useState(null)
    const [errorPlaceOfBirth, setErrorPlaceOfBirth] = useState(null)
    const [errorBirthday, setErrorBirthday] = useState(null)
    const [errorPhoneNumber, setErrorPhoneNumber] = useState(null)
    const [errorEmail, setErrorEmail] = useState(null)

    const [errorProvince, setErrorProvince] = useState(null)
    const [errorRegency, setErrorRegency] = useState(null)
    const [errorDistrict, setErrorDistrict] = useState(null)
    const [errorVillage, setErrorVillage] = useState(null)
    const [errorDetail, setErrorDetail] = useState(null)

    const [loading, setLoading] = useState(false)

    const fetchProvince = async () => {
        const response = await getProvince(accessToken2, null)
        console.log(response);
        if (response.status === 200) {
            setProvince(response.data?.data)
            setProvinceId('')
        } else {
            setProvince([])
            setProvinceId('')
        }
    }

    const fetchRegency = async (provinceId) => {
        const response = await getRegency(accessToken2, provinceId, null)
        if (response.status === 200) {
            setRegency(response.data?.data)
            setRegencyId('')
        }
    }

    const fetchDistrict = async (regencyId) => {
        const response = await getDistrict(accessToken2, regencyId, null)
        if (response.status === 200) {
            setDistrict(response.data?.data)
            setDistrictId('')
        }
    }

    const fetchVillage = async (districtId) => {
        const response = await getVillage(accessToken2, districtId, null)
        if (response.status === 200) {
            setVillage(response.data?.data)
            setVillageId('')
        }
    }

    const fetchGenders = async () => {
        const response = await getGenders()
        if (response.status === 200) {
            setGenders(response.data)
        } else {
            setGenders([])
        }
    }

    const fetchWeddingStatus = async () => {
        const response = await getWeddingStatus()
        if (response.status === 200) {
            setPatientStatus(response.data)
        } else {
            setPatientStatus([])
        }
    }

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchProvince()
                fetchGenders()
                fetchWeddingStatus()
            }
        }
    }, [accessToken, accessToken2]);

    const handeleProvince = (e) => {
        setProvinceId(e.target.value)
        fetchRegency(e.target.value)
        setRegencyId('')

        setDistrict([])
        setDistrictId('')

        setVillage([])
        setVillageId('')
    }

    const handeleRegency = (e) => {
        const index = e.target.options.selectedIndex
        setRegencyName(e.target.options[index].getAttribute('name'))

        setRegencyId(e.target.value)
        fetchDistrict(e.target.value)
        setDistrictId('')

        setVillage([])
        setVillageId('')
    }

    const handeleDistrict = (e) => {
        setDistrictId(e.target.value)
        fetchVillage(e.target.value)
        setVillageId('')
    }

    const handeleVillage = (e) => {
        setVillageId(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        setErrorNik(null)
        setErrorName(null)
        setErrorPlaceOfBirth(null)
        setErrorBirthday(null)
        setErrorPhoneNumber(null)

        setErrorProvince(null)
        setErrorRegency(null)
        setErrorDistrict(null)
        setErrorVillage(null)

        // console.log(birthday.length);

        // 

        if (nik.length !== 0 && nik.length < 16 || nik.length !== 0 && nik.length > 16) {
            setErrorNik('nik harus memiliki 16 angka')
        } else if (name.length === 0) {
            setErrorName('nama tidak boleh kosong..!')
        } else if (name.length < 3) {
            setErrorName('nama minimal terdiri dari 3 huruf..!')
        } else if (placeOfBirth.length === 0) {
            setErrorPlaceOfBirth('Silahkan isi tempat lahirnya..!')
        } else if (birthday.length === 0) {
            setErrorBirthday('Silahkan isi tanggal lahirnya..!')
        } else if (phoneNumber.length !== 0 && phoneNumber.length < 12 || phoneNumber.length !== 0 && phoneNumber.length > 13) {
            setErrorPhoneNumber('no telepon minimal memiliki 12-13 angka')
        } else if (provinceId.length === 0) {
            setErrorProvince('data harus diisi..!')
        } else if (regencyId.length === 0) {
            setErrorRegency('data harus diisi..!')
        } else if (districtId.length === 0) {
            setErrorDistrict('data harus diisi..!')
        } else if (villageId.length === 0) {
            setErrorVillage('data harus diisi..!')
        }else if (detail.length === 0) {
            setErrorDetail('detail alamat harus di isi..!')
        } else {
            if (category === 'non_bpjs') {
                setBpjsNumber(null)
            }

            // if (phoneNumber === '') {
            //     phoneNumber = null
            // }

            const formData = new FormData()

            formData.append('category', category)
            formData.append('name', name)
            formData.append('bpjs_number', bpjsNumber)
            formData.append('nik', nik)
            formData.append('place_of_birth', placeOfBirth)
            formData.append('birthday', birthday)
            formData.append('gender', genderName)
            formData.append('gender_display', genderDisplay)

            formData.append('phone_number', phoneNumber)
            formData.append('email', email)

            formData.append('patient_status_code', patientStatusCode)
            formData.append('patient_status_display', patientStatusDisplay)

            formData.append('province_id', provinceId)
            formData.append('regency_id', regencyId)
            formData.append('regency_name', regencyName)
            formData.append('district_id', districtId)
            formData.append('village_id', villageId)
            formData.append('rt', rt)
            formData.append('rw', rw)
            formData.append('detail', detail)

            formData.append('token_v2', accessToken2)

            const response = await postPatient(
                accessToken,
                formData
            )
            console.log(response);
            switch (response.status) {
                case 200:
                    navigate('/dashboard/pasien')
                    break;

                case 422:
                    console.log(response.data[0]);
                    if (response.data[0] != undefined) {
                        if (response.data[0].nik != undefined) {
                            setErrorNik(true)
                        } else {
                            setErrorNik(false)
                        }

                        if (response.data[0].name != undefined) {
                            setErrorName(true)
                        } else {
                            setErrorName(false)
                        }

                        if (response.data[0].place_of_birth != undefined) {
                            setErrorPlaceOfBirth(true)
                        } else {
                            setErrorPlaceOfBirth(false)
                        }

                        if (response.data[0].birthday != undefined) {
                            setErrorBirthday(true)
                        } else {
                            setErrorBirthday(false)
                        }
                    }
                    break;

                default:
                    alert('tambah data gagal..!')
                    break;
            }
        }
        
        setLoading(false)

    }


console.log(accessToken2);
    return (
        <>
            <Helmet>
                <title>Tambah Data Pasien - KLINIK YASINTA</title>
            </Helmet>

            <Layout>
                <Header>
                    <Link to="/dashboard/pasien" className="px-3 py-2 rounded-md bg-custom_orange flex items-center text-white justify-center">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Link>
                    <div className="uppercase font-semibold">
                        Tambah Data Pasien baru
                    </div>
                    <button type="button" onClick={handleSubmit} className="capitalize px-3 py-2 bg-custom_green flex items-center space-x-3 rounded-md font-semibold text-white hover:bg-custom_orange transition-colors">
                        {loading === true ?
                            <CustomSprinner /> :
                            <>
                                <FontAwesomeIcon icon={faSave} />
                                <p>Simpan </p>
                            </>
                        }
                    </button>
                </Header>

                <form className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 px-3 py-10 md:px-5 text-sm">

                    <div className="w-full  space-y-3">
                        <div className="flex flex-col ">
                            <label htmlFor="category" className="capitalize">Kategori Pasien</label>
                            <select value={category} onChange={(e) => setCategory(e.target.value)} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                <option value="bpjs">BPJS</option>
                                <option value="non_bpjs">Non BPJS</option>
                            </select>
                        </div>

                        {category === 'bpjs' ?
                            <div className="flex flex-col ">
                                <label htmlFor="bpjsNumber" className="capitalize">No BPJS</label>
                                <input type="text" id="bpjsNumber" value={bpjsNumber} onChange={(e) => setBpjsNumber(e.target.value)} className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                                <p className="text-red-500 text-sm">no bpjs tidak boleh kosong..!</p>
                            </div> : ''
                        }

                        <CustomInputV2
                            type="number"
                            id="nik" name="No NIK"
                            value={nik} setValue={setNik}
                            errorMessage={errorNik}
                            onchangeValue={
                                (e) => {
                                    if (e.target.value.length < 17) {
                                        setNik(e.target.value)
                                    }
                                }} >
                        </CustomInputV2>

                        <CustomInputV2
                            id="name" name="Nama Pasien"
                            value={name}
                            setValue={setName}
                            errorMessage={errorName}>
                        </CustomInputV2>

                        <CustomInputV2
                            id="placeOfBirth" name="Tempat Lahir"
                            value={placeOfBirth}
                            setValue={setPlaceOfBirth}
                            errorMessage={errorPlaceOfBirth}>
                        </CustomInputV2>

                        <CustomInputV2
                            type="date"
                            id="birthday" name="Tanggal Lahir"
                            value={birthday}
                            setValue={setBirthDay}
                            errorMessage={errorBirthday}>
                        </CustomInputV2>

                        <div className="flex flex-col ">
                            <label htmlFor="gender" className="capitalize">Jenis Kelamin</label>
                            <select id="gender" value={genderName}
                                onChange={(e) => {
                                    const index = e.target.options.selectedIndex
                                    setGenderDisplay(e.target.options[index].getAttribute('display'))
                                    setGenderName(e.target.value)
                                }}
                                className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                {genders.map((item) => (
                                    <option value={item.name} display={item.display}>{item.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="flex flex-col ">
                            <label htmlFor="gender" className="capitalize">Status Pernikahan</label>
                            <select id="patientStatus"
                                value={patientStatusCode}
                                onChange={(e) => {
                                    const index = e.target.options.selectedIndex
                                    setPatientStatusDisplay(e.target.options[index].getAttribute('display'))
                                    setPatientStatusCode(e.target.value)
                                }}
                                className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                {patientStatus.map((item) => (
                                    <option value={item.code} display={item.display}>{item.name}</option>
                                ))}
                            </select>
                        </div>

                        <CustomInputV2
                            type="number"
                            id="phoneNumber" name="No Telepon"
                            value={phoneNumber} setValue={setPhoneNumber}
                            errorMessage={errorPhoneNumber}
                            onchangeValue={
                                (e) => {
                                    if (e.target.value.length < 14) {
                                        setPhoneNumber(e.target.value)
                                    }
                                }} >
                        </CustomInputV2>

                        <CustomInputV2
                            type="email"
                            id="email" name="Email"
                            value={email} setValue={setEmail}
                            errorMessage={errorEmail}>
                        </CustomInputV2>


                    </div>

                    <div className="w-full space-y-3">

                        <div className="flex flex-col ">
                            <label htmlFor="category" className="capitalize">Provinsi</label>
                            <select value={provinceId} onChange={handeleProvince} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                <option value={null} className="text-center">== Pilih Provinsi ==</option>
                                {province?.map((item) => (
                                    <option value={item.code}>{item.name}</option>
                                ))}
                            </select>
                            {errorProvince === null ? '' : <p className="text-red-500 text-sm">silahkan pilih provinsi..!</p>}
                        </div>

                        <div className="flex flex-col ">
                            <label htmlFor="category" className="capitalize">Kabupaten / Kota</label>
                            <select value={regencyId} onChange={handeleRegency} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                <option value={null} className="text-center">== Pilih Kabupaten / Kota ==</option>
                                {regency?.map((item) => (
                                    <option value={item.code} name={item.name}>{item.name}</option>
                                ))}
                            </select>
                            {errorRegency === null ? '' : <p className="text-red-500 text-sm">silahkan pilih kabupaten..!</p>}
                        </div>

                        <div className="flex flex-col">
                            <label htmlFor="category" className="capitalize">Kecamatan</label>
                            <select value={districtId} onChange={handeleDistrict} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                <option value={null} className="text-center">== Pilih Kecamatan ==</option>
                                {district?.map((item) => (
                                    <option value={item.code}>{item.name}</option>
                                ))}
                            </select>
                            {errorDistrict === null ? '' : <p className="text-red-500 text-sm">silahkan pilih kecamatan..!</p>}
                        </div>

                        <div className="flex flex-col ">
                            <label htmlFor="category" className="capitalize">Desa / Kelurahan</label>
                            <select value={villageId} onChange={handeleVillage} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                                <option value={null} className="text-center">== Pilih Desa / Kelurahan ==</option>
                                {village?.map((item) => (
                                    <option value={item.code}>{item.name}</option>
                                ))}
                            </select>
                            {errorVillage === null ? '' : <p className="text-red-500 text-sm">silahkan pilih Desa / Kelurahan..!</p>}
                        </div>

                        <div className="w-full flex items-center space-x-3">
                            <CustomInputV2
                                type="number"
                                id="rt" name="RT"
                                value={rt}
                                setValue={setRt}>
                            </CustomInputV2>

                            <CustomInputV2
                                type="number"
                                id="rw" name="RW"
                                value={rw}
                                setValue={setRw}>
                            </CustomInputV2>
                        </div>


                        <div className="flex flex-col ">
                            <label htmlFor="detail" className="capitalize">Desa / Kelurahan</label>
                            <textarea id="detail" value={detail} onChange={(e) => setDetail(e.target.value)} rows={5} className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md"></textarea>
                            {errorDetail === null ? '' : <p className="text-red-500 text-sm">{errorDetail}</p>}

                        </div>

                    </div>

                </form>
            </Layout>
        </>
    )
}

export default TambahPasien