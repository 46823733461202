import { faClose, faHouseMedicalFlag } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"

const MobileMenu = ({ children }) => {
    const [openMenu, setOpenMenu] = useState(false)

    const popupRef = useRef()
    useEffect(() => {
        // Fungsi untuk menutup popup saat klik diluar area popup
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenMenu(false);
            }
        }

        // Mendaftarkan event listener saat komponen dimount
        document.addEventListener("mousedown", handleClickOutside);

        // Membersihkan event listener saat komponen dibongkar
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="fixed lg:hidden bottom-5 right-5 flex flex-col items-end z-50" ref={popupRef}>
            <div className={`bg-transparent transition-all duration-500 shadow-md border backdrop-blur-sm -mb-10 mr-5 rounded-3xl px-3 py-3   ${openMenu === true ? 'h-96 w-56  overflow-auto p-3' : 'h-0 w-0'}`}>
                <div className="w-full h-full flex flex-col space-y-3  hide-scrollbar overflow-auto rounded-2xl">
                    {children}
                </div>
            </div>

            <button type="button" onClick={() => setOpenMenu(!openMenu)} className=" h-14 flex items-center justify-center aspect-square bg-transparent backdrop-blur-sm shadow-md border rounded-full z-50 relative">
                <FontAwesomeIcon icon={faHouseMedicalFlag} className={`absolute object-contain h-6 transition-all duration-500 ${openMenu === true ? 'scale-0 rotate-180' : 'scale-100 rotate-0'}`} />
                <FontAwesomeIcon icon={faClose} className={`object-contain h-6 transition-all duration-500 ${openMenu === true ? 'scale-100 rotate-0' : 'scale-0 -rotate-180'}`} />
            </button>
        </div>
    )
}


export default MobileMenu
