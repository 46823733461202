import { useEffect, useState } from "react"
import CustomSelect from "../../../../components/custom_select"
import CustomInput from "../../../../components/custom_input"
import { useNavigate } from "react-router-dom"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../../services/location_service"
import { parseISO, isAfter } from "date-fns"
import CustomSelectAddress from "../../../../components/customs/select/address"
import { postOfficeAddress } from "../../../../services/office_address_service"

const ModalOfficeAddress = ({ officeAddress, accessToken, openModal, setOpenModal, setRefresh }) => {
    const navigate = useNavigate()
    const accessToken2 = localStorage.getItem('access_token2')
    const userRole = localStorage.getItem('user_role')
    const userName = localStorage.getItem('user_name')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)

    const [dataSuplier, setDataSuplier] = useState([])
    const [suplayerId, setSuplayerId] = useState('')

    const [province, setProvince] = useState([])
    const [provinceId, setProvinceId] = useState('')

    const [regency, setRegency] = useState([])
    const [regencyId, setRegencyId] = useState('')
    const [regencyName, setRegencyName] = useState('')

    const [district, setDistrict] = useState([])
    const [districtId, setDistrictId] = useState('')

    const [village, setVillage] = useState([])
    const [villageId, setVillageId] = useState('')

    const [postalCode, setPostalCode] = useState('')
    const [rt, setRt] = useState('01');
    const [rw, setRw] = useState('02');

    const [detail, setDetail] = useState('');

    const [errorProvince, setErrorProvince] = useState(null)
    const [errorRecency, setErrorRecency] = useState(null)
    const [errorDistrict, setErrorDistrict] = useState(null)
    const [errorVillage, setErrorVillage] = useState(null)
    const [errorPostalCode, setErrorPostalCode] = useState(null)
    const [errorRt, setErrorRt] = useState(null)
    const [errorRw, setErrorRw] = useState(null)
    const [errorDetail, setErrorDetail] = useState(null)

    const fetchProvince = async () => {
        const response = await getProvince(accessToken2, null)
        if (response.status === 200) {
            setProvince(response.data?.data)
            setProvinceId('')
        } else {
            setProvince([])
            setProvinceId('')
        }
    }

    const fetchRegency = async (provinceId) => {
        const response = await getRegency(accessToken2, provinceId, null)
        if (response.status === 200) {
            setRegency(response.data?.data)
            setRegencyId('')
        }
    }

    const fetchDistrict = async (regencyId) => {
        const response = await getDistrict(accessToken2, regencyId, null)
        if (response.status === 200) {
            setDistrict(response.data?.data)
            setDistrictId('')
        }
    }

    const fetchVillage = async (districtId) => {
        const response = await getVillage(accessToken2, districtId, null)
        if (response.status === 200) {
            setVillage(response.data?.data)
            setVillageId('')
        }
    }



    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchProvince()
                // fetchRegency(officeAddress.regency_id ?? null)
                // fetchDistrict(officeAddress.district_id ?? null)
                // fetchVillage(officeAddress.village_id ?? null)
            }
        }
    }, [accessToken, accessToken2]);

    const handleProvince = (e) => {
        setProvinceId(e.target.value)
        fetchRegency(e.target.value)
        setRegencyId('')

        setDistrict([])
        setDistrictId('')

        setVillage([])
        setVillageId('')
    }

    const handleRegency = (e) => {
        const index = e.target.options.selectedIndex
        setRegencyName(e.target.options[index].getAttribute('name'))

        setRegencyId(e.target.value)
        fetchDistrict(e.target.value)
        setDistrictId('')

        setVillage([])
        setVillageId('')
    }

    const handleDistrict = (e) => {
        setDistrictId(e.target.value)
        fetchVillage(e.target.value)
        setVillageId('')
    }

    const handleVillage = (e) => {
        setVillageId(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()
        formData.append('province_id', provinceId)
        formData.append('regency_id', regencyId)
        formData.append('regency_name', regencyName)
        formData.append('district_id', districtId)  
        formData.append('village_id', villageId)
        formData.append('postal_code', postalCode)
        formData.append('rt', rt)
        formData.append('rw', rw)
        formData.append('detail', detail)

        const response = await postOfficeAddress(accessToken, formData)
        console.log(response);

        setLoading(false)
        setOpenModal(false)
        setRefresh(true)
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-300'}`}>

            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[90%] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full max-h-[90%] delay-300' : 'w-0 max-h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Update Alamat Klinik</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full py-[70px] overflow-auto px-3">
                    <div className=" grid grid-cols-1 md:grid-cols-2 gap-3">
                        <div className="space-y-3">
                            <CustomSelectAddress
                                id={province}
                                name="Provinsi"
                                value={provinceId} handeleChange={handleProvince}
                                data={province} nameSelect="== Pilih Provinsi =="
                                errorMessage={errorProvince}
                            />
                            <CustomSelectAddress
                                id={regency}
                                name="Kabupaten / Kota"
                                value={regencyId} handeleChange={handleRegency}
                                data={regency} nameSelect="== Pilih Kabupaten / Kota =="
                                errorMessage={errorRecency}
                            />
                            <CustomSelectAddress
                                id={district}
                                name="Kecamatan"
                                value={districtId} handeleChange={handleDistrict}
                                data={district} nameSelect="== Pilih Kecamatan =="
                                errorMessage={errorDistrict}
                            />
                            <CustomSelectAddress
                                id={village}
                                name="Desa / Kelurahan"
                                value={villageId} handeleChange={handleVillage}
                                data={village} nameSelect="== Pilih Desa / Kelurahan =="
                                errorMessage={errorVillage}
                            />
                        </div>
                        <div className="space-y-3">
                            <CustomInput type="number" id="postalCode" name="Kode Pos" value={postalCode} setValue={setPostalCode} />
                            <CustomInput type="text" id="stock" name="Rt" value={rt} setValue={setRt} />
                            <CustomInput type="text" id="stock" name="Rw" value={rw} setValue={setRw} />
                            <div className="w-full flex flex-col">
                                <label htmlFor="detail">Detail Alamat</label>
                                <textarea id="detail" value={detail} onChange={(e) => setDetail(e.target.value)} cols={3} className="px-3 py-2 outline-none border-2 focus:border-custom_green"></textarea>
                            </div>
                        </div>

                    </div>
                    <div className="w-full flex items-center space-x-3 pt-3">
                        <button
                            type="button"
                            onClick={() => { setOpenModal(false) }}
                            className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 py-2 rounded-md font-semibold text-white">
                            Kembali
                        </button>


                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>
                <div className="absolute bottom-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div >
    )
}

export default ModalOfficeAddress