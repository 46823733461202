import { Document, Image, Page, StyleSheet, Text, View, Ta } from "@react-pdf/renderer"
import Logo from '../../../../assets/icons/logo_yasinta.png'
import { parseISO } from 'date-fns'

const DocumentResep = ({ data }) => {
    const styles = StyleSheet.create({
        page: {
            backgroundColor: 'white',
            padding: '20px'
        },

        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
            textAlign: 'center',
            borderBottomWidth: 2,
            borderBottomColor: 'black'

        },

        heading: {
            textAlign: 'center',
            fontWeight: 'bold',
            borderBottomWidth: 2,
            borderBottomColor: 'black',
            paddingBottom: '10px'
        },

        contentTab: {
            fontSize: '10pt',
            display: 'flex',
            flexDirection: 'row',
            margin: '5px 0px'
        },

        rowHeader: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderTop: 1,
            borderBottom: 1,
            borderColor: '#AAAAAA',
        },

        row: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },

        rowBottom: {
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: '#AAAAAA',
        },

        cell: {
            padding: 5,
            fontSize: '10pt',
            textAlign: 'center'
        },

        cell1: {
            width: '20px',
            padding: 5,
            fontSize: '10pt',
            textAlign: 'center'
        },

        cell2: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'start'
        },

        cell3: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'right'
        },

        cell4: {
            padding: 5,
            width: '150px',
            fontSize: '10pt',
            textAlign: 'right'
        }
    });
    let number = 1;
    let totalPayment = 0
    const getHours = (data) => {
        return new Date(data).getHours().toString() + ":" + new Date(data).getMinutes().toString();
    }

    const getDayName = (createdAt) => {
        const days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'kamis', "Jum'at", 'Sabtu'];
        const dayIndex = new Date(createdAt).getDay();
        return days[dayIndex];
    };

    const day = (day) => {
        return new Date(day).getDate();
    }

    const year = (year) => {
        return new Date(year).getFullYear();
    }

    const getMonthName = (dateString) => {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
        ];

        const date = new Date(dateString);
        const monthIndex = date.getMonth();

        return months[monthIndex];
    };


    return (
        <Document >
            <Page size="A5" orientation="landscape" style={styles.page}>
                <View style={{ marginBottom: '5px', flexDirection: 'row', alignItems: 'center' }}>
                    <Image src={Logo} style={{ height: '60px', width: '60', objectFit: 'content' }} />
                    <Text style={styles.heading}>Resep - KLINIK YASINTA</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: '-5px', }}>
                    <Text style={{ fontSize: '10pt' }}>
                        {getHours(data?.created_at)}  {getDayName(data?.created_at)}
                        , {day(data?.created_at)}-
                        {getMonthName(data?.created_at)}-
                        {year(data?.created_at)}
                    </Text>
                </View>
                <View style={styles.contentTab}>
                    <Text style={{ width: '100px' }}>Nama Lengkap</Text>
                    <Text style={{ width: '10px' }}>:</Text>
                    <Text style={{}}>Nama Staf</Text>
                </View>
                <View style={styles.contentTab}>
                    <Text style={{ width: '100px' }}>Jabatan</Text>
                    <Text style={{ width: '10px' }}>:</Text>
                    <Text style={{}}>{data?.id}</Text>
                </View>
                <View style={styles.contentTab}>
                    <Text style={{ width: '100px' }}>Kode Resep</Text>
                    <Text style={{ width: '10px' }}>:</Text>
                    <Text style={{}}>{data?.recipe_number}</Text>
                </View>

                <View style={styles.rowHeader}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell1}>No</Text>
                        <Text style={styles.cell2}>Nama Obat</Text>
                        <Text style={styles.cell2}>Aturan pake</Text>
                        <Text style={styles.cell2}>Harga</Text>
                        <Text style={styles.cell2}>Jumlah</Text>
                    </View>xa
                    <Text style={styles.cell3}>total Harga</Text>
                </View>

                {data?.recipe_item?.map((item) => {
                    const itemTotalPrice = item?.price * item?.quantity;
                    // Tambahkan total harga item ke dalam total pembayaran
                    totalPayment += itemTotalPrice;
                    return (

                        <View style={styles.row}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={styles.cell1}>{number++}</Text>
                                <Text style={styles.cell2}>{item?.name} </Text>
                                <Text style={styles.cell2}>{item?.how_to_use}</Text>
                                <Text style={styles.cell2}>Rp. {item?.price.toLocaleString()}</Text>
                                <Text style={styles.cell2}>{item?.quantity}</Text>
                            </View>
                            <Text style={styles.cell4}>Rp. {item.total_price.toLocaleString()}</Text>
                        </View>
                    )
                })}
                <View style={{
                    width: "100%",
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: 1,
                    borderColor: '#AAAAAA',
                }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell3}>Total Pembayaran</Text>
                    </View>
                    <Text style={styles.cell}>Rp. {totalPayment.toLocaleString()}</Text>
                </View>
                <View style={{
                    width: "100%",
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: 1,
                    borderColor: '#AAAAAA',
                }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell3}>Uang Pembayaran</Text>
                    </View>
                    <Text style={styles.cell}>Rp. {!data?.payment ? 0 : data?.payment?.uang_pembayaran.toLocaleString()}</Text>
                </View>
                <View style={{
                    width: "100%",
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderTop: 1,
                    borderColor: '#AAAAAA',
                }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={styles.cell3}>Kembalian</Text>
                    </View>
                    <Text style={styles.cell}>Rp. {!data?.payment ? 0 : data?.payment?.kembalian.toLocaleString()}</Text>
                </View>
                <View style={{
                    width: '100%', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '20px'
                }}>
                    <View style={{ width: '180px' }}>
                        <Text style={styles.cell}>KLINIK KESEHATAN DAN SKINCARE</Text>
                        <Text style={{ textAlign: 'center', marginTop: '50px', fontSize: '10pt', borderBottom: 1, borderBottomColor: '#AAAAAA' }}>( Dr. Sinatryana Bahran )</Text>
                        <Text style={styles.cell}>DIREKTUR </Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default DocumentResep