import { faCaretDown, faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect } from "react"
import { useState } from "react"
import { getPatient } from "../../../../services/patient_service"
import { getKunjungan, postKunjungan } from "../../../../services/kunjungan_service"
import { useNavigate } from "react-router-dom"
import { parseISO, isAfter } from "date-fns"
import { getRoom } from "../../../../services/room_service"
import { getDokter } from "../../../../services/dokter_service"

const ModalCreateKunjungan = ({ accessToken, openModal, setOpenModal, setRefresh }) => {
    const navigate = useNavigate()
    const accessToken2 = localStorage.getItem('access_token2')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)

    const [category, setCategory] = useState('rawat jalan')
    const [data, setData] = useState([])
    const [patientId, setPatientId] = useState('')
    const [patientName, setPatientName] = useState('')

    const [dataDokter, setDataDokter] = useState([])
    const [dokterCurrentPage, setDokterCurrentPage] = useState(1)
    const [dokterId, setDokterId] = useState('')
    const [dokterName, setDokterName] = useState('')

    const [dataRuangan, setDataRuangan] = useState([])
    const [ruanganId, setRuanganId] = useState('')
    const [ruanganName, setRuanganName] = useState('')
    const [ruanganCode, setRuanganCode] = useState('')


    const [openSelect, setOpenSelect] = useState(false)
    const [openSelectDokter, setOpenSelectDokter] = useState(false)
    const [openSelectRuangan, setOpenSelectRuangan] = useState(false)

    const [errorMessageRuanganId, setErrorMessageRuanganId] = useState(null)
    const [errorMessagePatientId, setErrorMessagePatientId] = useState(null)
    const [errorMessageDokterId, setErrorMessageDokterId] = useState(null)

    const fetchPatient = async () => {
        setLoading(true)
        const response = await getKunjungan(accessToken, '-', 'name', 'asc', 1)
        if (response.status === 200) {
            setData(response.data.data)
            setPatientId(response?.data?.data[0].id ?? null)
        }
        setLoading(false)
        setRefresh(false)
    }

    const fetchDataRuangan = async () => {
        setLoading(true)
        const response = await getRoom(accessToken)
        // console.log(response);
        if (response.status === 200) {
            setDataRuangan(response.data.data)
        }

        setLoading(false)
        setRefresh(false)

    }

    const fetchDataDokter = async () => {
        setLoading(true);
        try {
            const response = await getDokter(accessToken, dokterCurrentPage);
            switch (response.status) {
                case 200:
                    setDataDokter(response.data.data);
                // setTotalData(response.data.meta.total);
                // setCurrentPage(response.data.meta.current_page);
                // setLastPage(response.data.meta.last_page);
                // setNumnber(response.data.meta.current_page);
                // setNumnberV2(response.data.meta.current_page);
                // break;
            }
        } catch (error) {
            alert('silahkan cek koneksi internet Anda..!')
        }
        setLoading(false);
        setRefresh(false)
    };

    useEffect(() => {
        if (accessToken === null) {
            navigate('/login')
        } else {
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchPatient()
                fetchDataDokter()
                fetchDataRuangan()
            }
        }
        // eslint-disable-next-line
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setOpenSelect(false)
        setErrorMessagePatientId(null)
        setErrorMessageDokterId(null)
        setErrorMessageRuanganId(null)

        if (patientId.length === 0) {
            setErrorMessagePatientId('Silahkan Pilih pasien terlebih dahulu..!')
        } else if (dokterId.length === 0) {
            setErrorMessageDokterId('Silahkan Pilih pasien terlebih dahulu..!')
        } else if (ruanganId.length === 0) {
            setErrorMessageRuanganId('Silahkan Pilih pasien terlebih dahulu..!')
        } else {
            const formData = new FormData()
            formData.append('token_v2', accessToken2)
            formData.append('category', category)
            formData.append('patient_id', patientId)
            formData.append('dokter_id', dokterId)
            formData.append('room_id', ruanganId)

            const response = await postKunjungan(accessToken, formData)
            console.log(response);
            switch (response.status) {
                case 200:
                    setRefresh(true)
                    setOpenModal(false)
                    break;

                case 403:
                    // navigate('/403')
                    break;

            }

            setLoading(false)
        }
    }



    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center 
                ${openModal === true ? `w-full delay-200 py-[70px] 
                ${openSelect === true ? 'max-h-[90%]' : 'max-h-[90%]'}` : 'w-0 max-h-0 overflow-hidden delay-0'}`}>

                <div className="w-full absolute z-10 top-0 text-center  h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Buat Kunjungan Baru</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full space-y-3 px-3 overflow-auto scrollbar-thin scrollbar-track-custom_green scrollbar-thumb-custom_orange">
                    <div className="w-full">
                        <select value={category} onChange={(e) => setCategory(e.target.value)} id="category" className="w-full px-3 py-2 outline-none border-2 hover:border-custom_green focus:border-custom_green rounded-md" >
                            <option value="rawat inap">Rawat Inap</option>
                            <option value="rawat jalan">Rawat Jalan</option>
                        </select>
                    </div>

                    {/* Select Pasien */}
                    <div className=" w-full">
                        <button type="button"
                            onClick={() => {
                                setPatientId('')
                                setPatientName('')
                                setOpenSelect(!openSelect)
                            }}
                            className="w-full flex items-center justify-between space-x-5 capitalize py-2 px-3 border-2 hover:border-custom_green rounded-md">
                            <div className="flex items-center space-x-3">
                                {
                                    !patientName ?
                                        <p className="line-clamp-1">Pilih Pasien</p> :
                                        <p>{patientName}</p>
                                }
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className={`transition-all duration-500 ${openSelect === true ? '-rotate-180' : 'rotate-0'}`} />
                        </button>

                        {errorMessagePatientId === null ? '' :
                            <p className="text-sm text-red-500">
                                {errorMessagePatientId}
                            </p>
                        }
                        <div className={` bg-white w-full flex flex-col bg-transparent rounded-xl  text-sm text-black  transition-all duration-300 
                            ${openSelect === true ? 'space-y-3 mt-3 max-h-[350px] px-3 pb-5 border shadow-md overflow-auto'
                                : 'max-h-0 border-none pb-0 mt-0 overflow-hidden'}`}>

                            <div className="w-full sticky top-0 pt-5 bg-white">
                                <input
                                    type="search"
                                    placeholder="Cari Pasien"
                                    className="w-full outline-none border-2 focus:border-custom_green px-3 py-2 rounded-md" />
                            </div>
                            {data.map((item) => (
                                <button type="button"
                                    onClick={() => {
                                        setPatientId(item.id)
                                        setPatientName(item.name)
                                        setOpenSelect(false)
                                    }}
                                    className="w-full flex items-center justify-between border-2 rounded-md px-3 py-2 hover:bg-gray-200 transition-colors">
                                    <div className="text-start">
                                        <p>{item.name}</p>
                                        <p>{item.nik}</p>
                                    </div>
                                    <p className={`${item.category === 'bpjs' ? 'bg-custom_orange' : 'bg-custom_green'} rounded-full px-3 text-white font-semibold`}>
                                        {item.category}
                                    </p>
                                </button>
                            ))}
                        </div>

                    </div>

                    {/* Select Dokter */}
                    <div className=" w-full">
                        <button type="button"
                            onClick={() => {
                                setDokterId('')
                                setDokterName('')
                                setOpenSelectDokter(!openSelectDokter)
                            }}
                            className="w-full flex items-center justify-between space-x-5 capitalize py-2 px-3 border-2 hover:border-custom_green rounded-md">
                            <div className="flex items-center space-x-3">
                                {
                                    !dokterName ?
                                        <p className="line-clamp-1">Pilih Dokter</p> :
                                        <p>{dokterName}</p>
                                }
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className={`transition-all duration-500 ${openSelectDokter === true ? '-rotate-180' : 'rotate-0'}`} />
                        </button>

                        {errorMessageDokterId === null ? '' :
                            <p className="text-sm text-red-500">
                                {errorMessageDokterId}
                            </p>
                        }
                        <div className={` bg-white w-full flex flex-col bg-transparent rounded-xl  text-sm text-black  transition-all duration-300 
                            ${openSelectDokter === true ? 'space-y-3 mt-3 max-h-[350px] px-3 pb-5 border shadow-md overflow-auto'
                                : 'max-h-0 border-none pb-0 mt-0 overflow-hidden'}`}>

                            <div className="w-full sticky top-0 pt-5 bg-white">
                                <input
                                    type="search"
                                    placeholder="Cari Dokter"
                                    className="w-full outline-none border-2 focus:border-custom_green px-3 py-2 rounded-md" />
                            </div>
                            {dataDokter.map((item) => (
                                <button type="button"
                                    onClick={() => {
                                        setDokterId(item.id)
                                        setDokterName(item.name)
                                        setOpenSelectDokter(false)
                                    }}
                                    className="w-full flex items-center justify-between border-2 rounded-md px-3 py-2 hover:bg-gray-200 transition-colors">
                                    <div className="text-start">
                                        <p>{item?.name}</p>
                                        <p>{item?.userRole?.name}</p>
                                    </div>
                                </button>
                            ))}
                        </div>

                    </div>

                    {/* Select ruangan */}
                    <div className=" w-full ">
                        <button type="button"
                            onClick={() => {
                                setRuanganId(null)
                                setRuanganName(null)
                                setOpenSelectRuangan(!openSelectRuangan)
                            }}
                            className="w-full flex items-center justify-between space-x-5 capitalize py-2 px-3 border-2 hover:border-custom_green rounded-md">
                            <div className="flex items-center space-x-3">
                                {
                                    !ruanganName ?
                                        <p className="line-clamp-1">Pilih Ruangan</p> :
                                        <p>{ruanganName}</p>
                                }
                            </div>
                            <FontAwesomeIcon icon={faCaretDown} className={`transition-all duration-500 ${openSelect === true ? '-rotate-180' : 'rotate-0'}`} />
                        </button>

                        {errorMessageRuanganId === null ? '' :
                            <p className="text-sm text-red-500">
                                {errorMessageRuanganId}
                            </p>
                        }
                        <div className={` bg-white w-full flex flex-col bg-transparent rounded-xl  text-sm text-black space-y-3 transition-all duration-300 ${openSelectRuangan === true ? 'mt-3 max-h-[350px] px-3 pb-5 border shadow-md overflow-auto' : 'max-h-0 border-none mt-0 overflow-hidden'}`}>
                            <div className="w-full sticky top-0 pt-5 bg-white">
                                <input
                                    type="search"
                                    placeholder="Cari Ruangan"
                                    className="w-full outline-none border-2 focus:border-custom_green px-3 py-2 rounded-md" />
                            </div>
                            {dataRuangan.map((item) => (
                                <button type="button"
                                    onClick={() => {
                                        setRuanganId(item.id)
                                        setRuanganCode(item.code)
                                        setRuanganName(item.name)
                                        setOpenSelectRuangan(false)
                                    }}
                                    className="w-full flex items-center justify-between border-2 rounded-md px-3 py-2 hover:bg-gray-200 transition-colors">
                                    <div className="text-start">
                                        <p>{item.name}</p>
                                        <p>{item.code}</p>
                                    </div>
                                    {/* <p className={`${item.category === 'bpjs' ? 'bg-custom_orange' : 'bg-custom_green'} rounded-full px-3 text-white font-semibold`}>
                                        {item.category}
                                    </p> */}
                                </button>
                            ))}
                        </div>

                    </div>

                    <div className="flex items-center space-x-3">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModal(false)
                                setOpenSelect(false)
                            }}
                            className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 hover:bg-red-600 py-2 rounded-md font-semibold text-white">
                            Kembali
                        </button>
                        <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 hover:bg-green-600 py-2 rounded-md font-semibold text-white">
                            {loading === true ? <>
                                <div className="spinner-wave ">
                                    <div className="spinner-wave-dot  bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                    <div className="spinner-wave-dot bg-white"></div>
                                </div>
                            </> : 'SIMPAN'}
                        </button>
                    </div>
                </form>

                <div className="w-full absolute bottom-0 text-center border-t-2 h-16 flex items-center justify-center font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalCreateKunjungan