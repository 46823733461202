const CustomCurrency = ({number}) => {
    const rupiah = new Intl.NumberFormat("id", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0
    }).format(number)
    return (
        <p>{rupiah}</p>
    )
}

export default CustomCurrency