import { Helmet } from "react-helmet"
import Layout from "../../../components/layouts"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCaretDown, faEdit, faEye, faFilter, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"
import ModalCreateKunjungan from "./modals/create"
import { useEffect } from "react"
import { getKunjungan, getKunjungan2, searchKunjungan } from "../../../services/kunjungan_service"
import Pagination from "../../../components/pagination"
import ModalUpdateKunjungan from "./modals/update"
import CustomHour from "../../../components/custom_hour"
import CustomDayName from "../../../components/custom_day_name"
import CustomDay from "../../../components/custom_day"
import CustomMonth from "../../../components/custom_month"
import CustomYear from "../../../components/custom_year"
import Header from "../../../components/header"
import CustomRefPopper from "../../../components/custom_ref_poper"
import CustomSelectV2 from "../../../components/custom_select_v2"
import CustomLoading from "../../../components/loadings/loading"
import { parseISO, isAfter } from "date-fns"

const Kunjungan = () => {
    const navigate = useNavigate()
    const accessToken = localStorage.getItem('access_token')
    const userRole = localStorage.getItem('user_role')
    const expiredToken = localStorage.getItem('expired_token')
    const nowDate = new Date()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [openModalUpdate, setOpenModalUpdate] = useState(false)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)

    let [number, setNumnber] = useState(1)
    let [numberV2, setNumnberV2] = useState(1)

    const [category, setCategory] = useState('semua');
    const [sortBy, setSortBy] = useState('created_at');
    const [sortFrom, setSortFrom] = useState('desc');

    const [kunjunganId, setKunjunganId] = useState('');
    const [kunjunganStatus, setKunjunganStatus] = useState('');

    const [keyword, setKeyword] = useState('')
    const [openDropdown, setOpenDropdown] = useState(false)
    const popupRef = useState(null)



    const fetchKunjungan = async () => {
        setLoading(true)
        try {
            const response = await getKunjungan2(accessToken, category, sortBy, sortFrom, currentPage)
            console.log("=====================");
            console.log(response);
            console.log("=====================");
            setLoading(false)
            setRefresh(false)
            switch (response.status) {
                case 200:
                    setData(response.data.data);
                    setTotalData(response.data.meta.total);
                    setCurrentPage(response.data.meta.current_page);
                    setLastPage(response.data.meta.last_page);

                    setNumnber(response.data.meta.current_page);
                    setNumnberV2(response.data.meta.current_page);
                    break;

                case 401:
                    navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 404:
                    // navigate('/403')
                    break;


            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    const fetchSearchKunjungan = async () => {
        setLoading(true)
        try {
            const response = await searchKunjungan(accessToken, keyword)
            setLoading(false)
            setRefresh(false)
            switch (response.status) {
                case 200:
                    setData(response.data.data);
                    setTotalData(response.data.meta.total);
                    setCurrentPage(response.data.meta.current_page);
                    setLastPage(response.data.meta.last_page);

                    setNumnber(response.data.meta.current_page);
                    setNumnberV2(response.data.meta.current_page);
                    break;

                case 401:
                    navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 404:
                    // navigate('/403')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    useEffect(() => {
        if (refresh === true) {
            fetchKunjungan()
        }
    }, [refresh])

    useEffect(() => {

        if (accessToken === null || accessToken === '') {
            navigate('/login')
        } else {
            // if (!['super admin', 'admin', 'dokter umum', 'dokter gigi', 'farmasi', 'asisten farmasi', 'perawat'].includes(userRole)) {
            //     navigate('/403')
            if (isAfter(nowDate, parseISO(expiredToken))) {
                navigate('/login')
            } else {
                fetchKunjungan()
            }
        }

    }, [])

    const handleSearch = (e) => {
        e.preventDefault()

        setLoading(true)
        fetchSearchKunjungan(accessToken)
        setLoading(false)
    }
    return (
        <>
            <Helmet>
                <title>Kunjungan Pasien - KLINIK YASINTA</title>
            </Helmet>
            <Layout
                modal={
                    <>
                        <ModalCreateKunjungan
                            accessToken={accessToken} openModal={openModal}
                            setOpenModal={setOpenModal} setRefresh={setRefresh} />

                        <ModalUpdateKunjungan
                            accessToken={accessToken} openModal={openModalUpdate}
                            setOpenModal={setOpenModalUpdate} setRefresh={setRefresh}
                            kunjunganId={kunjunganId} kunjunganStatus={kunjunganStatus} setKunjunganStatus={setKunjunganStatus} />
                    </>
                }
            >
                <Header>
                    <p className="uppercase">Data Kunjungan - Klinik Yasinta</p>
                    <p className="flex items-end space-x-2 justify-self-end">
                        <span className="text-2xl font-semibold">{totalData}</span>
                        <span>Kunjungan</span>
                    </p>
                </Header>

                {/* Sub Header */}
                <div className="w-full py-2 flex items-end justify-between px-5 text-sm space-x-3">
                    {/* search */}
                    <form onSubmit={handleSearch} className="flex items-center relative w-full md:w-fit">
                        <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full lg:w-[220px] h-[40px] outline-none border-2 rounded-l-md px-3 py-2 text-black text-sm" />
                        <button type="submit" className="bg-green-500 px-3 h-[40px] rounded-r-md border-2 border-green-500 text-sm text-white font-semibold">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>

                    <CustomRefPopper setOpenPopper={setOpenDropdown} popperRef={popupRef} />

                    {/* Button Filter & Add */}
                    <div className="flex items-center space-x-3 " ref={popupRef}>
                        <div className="w-fit flex flex-col items-end relative">
                            <button type="button" onClick={() => setOpenDropdown(!openDropdown)} className=" w-fit flex items-center justify-center bg-custom_green hover:bg-custom_green text-white font-semibold space-x-3 px-3 h-[40px] rounded-md border-2 border-custom_green">
                                <FontAwesomeIcon icon={faFilter} />
                                <p className="hidden md:flex capitalize">Filter ({category}) </p>
                            </button>
                            <div className={`absolute top-10 bg-black/30 mt-3 w-[200px] z-10 rounded-xl justify-self-end backdrop-blur-sm inline-flex flex-col justify-center space-y-3 transition-all duration-500 overflow-hidden ${openDropdown === true ? 'h-[120px] p-3' : 'h-0 mt-0 p-0'}`}>
                                <button type="button" onClick={() => {
                                    setCategory('semua')
                                    setRefresh(true)
                                }} className={`text-black font-semibold bg-gray-100 rounded-full hover:bg-green-500 hover:text-white transition-all ${category === 'semua' ? 'bg-green-500 text-white' : ''}`}>
                                    Semua
                                </button>
                                <button type="button" onClick={() => {
                                    setCategory('rawat inap')
                                    setRefresh(true)
                                }} className={`text-black font-semibold bg-gray-100 rounded-full hover:bg-green-500 hover:text-white transition-all ${category === 'rawat inap' ? 'bg-green-500 text-white' : ''}`}>
                                    Rawat Inap
                                </button>
                                <button type="button" onClick={() => {
                                    setCategory('rawat jalan')
                                    setRefresh(true)
                                }} className={`text-black font-semibold bg-gray-100 rounded-full hover:bg-green-500 hover:text-white transition-all ${category === 'rawat jalan' ? 'bg-green-500 text-white' : ''}`}>
                                    Rawat Jalan
                                </button>
                            </div>
                        </div>
                        {userRole === 'admin' || userRole === 'super admin' ?
                            <button type="button" onClick={() => setOpenModal(true)} className="flex items-center bg-custom_green hover:bg-custom_green text-white font-semibold space-x-3 px-3 h-[40px] w-fit rounded-md border-2 border-custom_green">
                                <FontAwesomeIcon icon={faPlus} />
                                <p className="hidden md:flex capitalize">Kunjungan Baru</p>
                            </button> : ''
                        }
                    </div>
                </div>

                <div className="w-full px-5 text-xs md:text-sm">
                    <div className="w-full hidden md:block overflow-auto rounded-t-xl">
                        <table className="w-full min-w-max max-w-screen-2xl">
                            <thead>
                                <tr className="bg-green-500 text-white">
                                    <th className="px-3 py-2 rounded-tl-xl" colSpan={1}>Aksi</th>
                                    <th className="px-3 py-2 ">No</th>
                                    <th className="px-3 py-2" colSpan={2}>Waktu / Tanggal</th>
                                    <th className="px-3 py-2">Nama Pasien</th>
                                    <th className="px-3 py-2">NIK</th>
                                    <th className="px-3 py-2">No Kunjungan</th>
                                    <th className="px-3 py-2">Kategori</th>
                                    <th className="px-3 py-2 rounded-tr-xl">Status</th>
                                </tr>
                            </thead>
                            <tbody>

                                {loading === true ?
                                    <CustomLoading counter={20} length={9} /> :
                                    data.map((item) => (
                                        <tr className="hover:bg-gray-200">
                                            <td className=" text-white px-3">
                                                <Link to={`/dashboard/kunjungan/detail/${item.id}`}
                                                    className="px-3 py-2 mx-auto w-full flex items-center justify-center bg-green-400 hover:bg-green-600 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Link>
                                            </td>
                                            {/* <td className=" text-white ">
                                                <button type="button"
                                                    onClick={() => {
                                                        if (item.status !== 'sudah dilayani') {
                                                            {
                                                                setOpenModalUpdate(true)
                                                                setKunjunganId(item.id)
                                                                setKunjunganStatus(item.status)
                                                            }
                                                        }
                                                    }
                                                    }
                                                    className="px-3 py-2 w-full bg-yellow-400 hover:bg-yellow-600 transition-colors rounded-md">
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </td> */}
                                            <td className="px-3 py-2 text-center">{number++}</td>
                                            <td className="px-3 py-2 ">
                                                <CustomHour data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2 ">
                                                <CustomDayName data={item.created_at} />,&nbsp;
                                                <CustomDay data={item.created_at} />-
                                                <CustomMonth data={item.created_at} />-
                                                <CustomYear data={item.created_at} />
                                            </td>
                                            <td className="px-3 py-2">{item.patient?.name ?? '-'}</td>
                                            <td className="px-3 py-2">{item.patient?.nik}</td>
                                            <td className="px-3 py-2 text-center">{item.no_kunjungan}</td>
                                            <td className="px-3 py-2 text-center">{item.category}</td>
                                            <td className="px-3 py-2 text-center">
                                                <p className={`px-3 ${item.status === 'belum dilayani' ? 'bg-red-600' : item.status === 'sedang dilayani' ? 'bg-yellow-600' : 'bg-green-600'} rounded-full font-semibold text-sm text-white`}>{item.status}</p>
                                            </td>
                                        </tr>
                                    ))
                                }

                                <Pagination
                                    currentPage={currentPage} setCurrentPage={setCurrentPage}
                                    lastPage={lastPage} setRefresh={setRefresh} colSpan={12} />

                            </tbody>
                        </table>

                        <div className="hidden">{numberV2++}</div>
                        <div className="hidden">{totalData}</div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default Kunjungan