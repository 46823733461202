const CustomInputV2 = ({ type, id, name, value, setValue, onchangeValue, errorMessage }) => {
    return (
        <div className="w-full flex flex-col ">
            <label htmlFor={id} className="capitalize">{name}</label>
            <input type={type ?? 'text'} id={id} value={value}
                onChange={onchangeValue ? onchangeValue : (e) => setValue(e.target.value)}
                className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
            {errorMessage === null ? '' : <p className="text-red-500 text-sm">{errorMessage}</p>}
        </div>
    )
}

export default CustomInputV2