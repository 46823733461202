import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useHref } from "react-router-dom"
import HandleShowHide from "./handle_show_hide"

const CustomeAccording = ({link, name, children, height }) => {
    const currentLink = useHref()
    const [open, setOpen] = useState(false)
    return (
        <div className='w-full'>
            <button
                type='button' onClick={() => HandleShowHide(open, setOpen)}
                className={`w-full px-3 py-2 flex items-center justify-between rounded-full hover:bg-orange-600 hover:text-white transition-colors ${currentLink.startsWith(link) ? 'bg-custom_green text-white' : 'bg-white'}`} >
                <p className="font-semibold capitalize">{name}</p>
                <FontAwesomeIcon icon={faCaretDown} className={`transition-all duration-500 ${open === true ? '-rotate-180' : 'rotate-0'}`} />
            </button>
            <div className={`w-full pl-5  flex flex-col space-y-1 transition-all duration-500 relative overflow-hidden ${open === true ? `${height ?? 'h-44'} pt-1` : 'h-0'}`}>
                {children}
            </div>
        </div>
    )
}

export default CustomeAccording