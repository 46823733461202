import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { postTarifLayanan } from "../../../../../services/tarif_layanan_service"

const ModalAddTarifLayanan = ({ accessToken, openModal, setOpenModal, setRefresh, getCategory, getCategoryId }) => {
    const [loading, setLoading] = useState(false)
    const category = getCategory ?? []
    const [categoryId, setCategoryId] = useState(getCategoryId)
    const [name, setName] = useState('')
    const [tarif, setTarif] = useState(0)

    const handleSubmit = async (e) => {

        e.preventDefault()
        setLoading(true)

        const formData = new FormData()
        formData.append('category_id', categoryId)
        formData.append('name', name)
        formData.append('tarif', tarif)

        const response = await postTarifLayanan(accessToken, formData)
        setOpenModal(false)
        setRefresh(true)
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[450px] lg:h-[500px] delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>
                <button
                    type="button"
                    onClick={() => { setOpenModal(false) }}
                    className="text-red-700 bg-red-200 w-fit aspect-square rounded-full absolute top-5 right-5 hover:bg-red-300 transition-colors">
                    <FontAwesomeIcon icon={faClose} className="w-10"></FontAwesomeIcon>
                </button>

                <div className="w-full text-center border-b-2 pt-12 pb-3 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data Tarif Layanan</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-3 space-y-3 px-3">
                    <div className="w-full">
                        <label htmlFor="category" className="block">Nama Layanan</label>
                        <select id="category"
                            value={categoryId}
                            onChange={(e) => setCategoryId(e.target.value)}
                            className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md">
                            {category.map((item) => (
                                <option value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full">
                        <label htmlFor="category" className="block">Nama Layanan</label>
                        <input type="text"
                            id="name"
                            value={name} onChange={(e) => setName(e.target.value)}
                            className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="tarif" className="block">Tarif Layanan</label>
                        <input id="tarif" type="number"
                            value={tarif}
                            onChange={(e) => setTarif(e.target.value)}
                            className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>

                    <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                        {loading === true ? <>
                            <div className="spinner-wave ">
                                <div className="spinner-wave-dot  bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                            </div>
                        </> : 'SIMPAN'}
                    </button>
                </form>

                <div className="w-full text-center border-t-2 pt-3 pb-12 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalAddTarifLayanan