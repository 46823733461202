const LoadingStaf = () => {
    return (
        <>
            {Array.from({ length: 20 }).map(() => (
                <div className="w-full py-3 px-3 hover:bg-gray-200 transition-colors border shadow-md rounded-xl flex items-center justify-between space-x-3 animate-pulse md:hidden">
                    <div className="flex items-center space-x-3">
                        <div className="h-10 aspect-square rounded-md border bg-gray-300 flex items-center justify-center"></div>
                        <div className="h-12 aspect-square rounded-full bg-gray-500"></div>
                        <div className="text-sm">
                            <p className="line-clamp-1 h-4 w-[100px] rounded-full bg-gray-300"></p>
                            <p className="line-clamp-1 h-4 mt-2 w-[100px] rounded-full bg-gray-300"></p>
                        </div>
                    </div>
                    <div className="relative flex items-start justify-end">
                        <button
                            type="button"
                            class="px-3 bg-gray-100 py-2 rounded-md">
                            {/* <FontAwesomeIcon icon={faEllipsisVertical} /> */}
                        </button>
                    </div>
                </div>
            ))}
        </>
    )
}

export default LoadingStaf