import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const ButtonBack = ({ link }) => {
    return (
        <Link to={link} className="flex items-center space-x-3 px-3 py-2" >
            <FontAwesomeIcon icon={faChevronCircleLeft} />
            <p className="hidden md:flex">Kembali</p>
        </Link>
    )
}

export default ButtonBack