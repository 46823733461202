import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Layout from "../../../components/layouts"
import { faEdit, faEye, faPlus, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons"
import ModalAddObat from "./modals/add"
import { useEffect, useState } from "react"
import Header from "../../../components/header"
import { deleteDrug, getDrugs, importDrugs, searchDrugs } from "../../../services/drug_service"
import ModalImportObat from "./modals/import"
import Pagination from "../../../components/pagination"

const Obat = () => {

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModal, setOpenModal] = useState(false)
    const [openModalImport, setOpenModalImport] = useState(false)

    let [number, setNumber] = useState(1)
    let [numberV2, setNumberV2] = useState(1)

    const [data, setData] = useState([])
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    const [perPage, setPerPage] = useState(0)

    const [keyword, setKeyword] = useState('')
    const fetchDrugs = async () => {
        setLoading(true)
        const response = await getDrugs(localStorage.getItem('access_token'))
        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setPerPage(response.data.meta.per_page)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page);
            setNumber(response.data.meta.from)
            setNumberV2(response.data.meta.from)

        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
        setLoading(false)
        setRefresh(false)
    }

    const fetchSearchDrugs = async () => {
        setLoading(true)
        const response = await searchDrugs(
            localStorage.getItem('access_token'),
            keyword
        )
        console.log(response);
        if (response.status === 200) {
            setData(response.data.data)
            setTotalData(response.data.meta.total)
            setPerPage(response.data.meta.per_page)
            setCurrentPage(response.data.meta.current_page)
            setLastPage(response.data.meta.last_page);
            setNumber(response.data.meta.from)
            setNumberV2(response.data.meta.from)

        } else {
            alert('silahkan periksa jaringan anda dan refresh kembali halaman ini..!')
        }
        setLoading(false)
        setRefresh(false)
    }

    useEffect(() => {
        if (refresh === true) {
            fetchDrugs()
        }
    }, [refresh])

    useEffect(() => {
        fetchDrugs()
    }, [])

    const handleSearch = (e) => {
        e.preventDefault()
        setLoading(true)
        fetchSearchDrugs()
        setLoading(false)
    }

    const handleDelete = async (e, id) => {
        e.preventDefault()
        setLoading(true)
        const response = await deleteDrug(
            localStorage.getItem('access_token'),
            id
        )
        if (response.status !== 200) {
            alert('data gagal di hapus..!')
        }
        setLoading(false)
        setRefresh(true)
    }
    return (
        <>
            <Layout

                modal={
                    <>
                        <ModalAddObat openModal={openModal} setOpenModal={setOpenModal} setRefresh={setRefresh} />
                        <ModalImportObat openModal={openModalImport} setOpenModal={setOpenModalImport} setRefresh={setRefresh} />
                    </>
                }
                children={
                    <>

                        <Header>
                            <p className="uppercase">Data Obat - Klinik Yasinta</p>
                            <p className="flex items-end space-x-2">
                                <span className="text-2xl font-semibold">{totalData}</span>
                                <span>Jenis Obat</span>
                            </p>
                        </Header>

                        <div className="w-full flex items-center justify-between space-x-3 px-3 md:px-5 py-3">
                            {/* search */}
                            <form onSubmit={handleSearch} className="flex items-center relative w-full lg:w-fit">
                                <input type="text" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="w-full lg:w-[220px] h-[40px] outline-none border-2 rounded-l-md px-3 py-2 text-black text-sm" />
                                <button type="submit" className="bg-green-500 px-3 h-[40px] rounded-r-md border-2 border-green-500 text-sm text-white font-semibold">
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </form>


                            <div className="flex items-center space-x-3">
                                <button type="button" onClick={() => setOpenModalImport(true)} className="px-3 h-[40px] bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p className="hidden lg:flex">Import</p>
                                </button>
                                <button type="button" onClick={() => setOpenModal(true)} className="px-3 h-[40px] bg-custom_green font-semibold flex items-center justify-center space-x-3 rounded-md text-white hover:bg-green-600 transition-colors">
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p className="hidden lg:flex">Tambah Data Obat</p>
                                </button>
                            </div>
                        </div>

                        <div className="w-full px-3 md:px-5">
                            <div className="w-full pb-10 overflow-auto rounded-xl ">
                                <table className="w-full min-w-max max-w-screen-2xl">
                                    <thead>
                                        <tr className="bg-green-500 text-white">
                                            <th className="px-3 py-2 text-center rounded-tl-xl">No</th>
                                            <th className="px-3 py-2 text-start">Nama Obat</th>
                                            <th className="px-3 py-2 text-start">Harga Faktur</th>
                                            <th className="px-3 py-2 text-start">50%</th>
                                            <th className="px-3 py-2 text-start">Harga Jual / Tablet</th>
                                            <th className="px-3 py-2 text-center" colSpan={2}>Stok</th>
                                            <th className="px-3 py-2 text-center rounded-tr-xl" colSpan={3}>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item) => (
                                            <tr className="hover:bg-gray-200 transition-colors">
                                                <td className="px-3 py-2 text-center">{number++}</td>
                                                <td className="px-3 py-2 ">{item.name}</td>
                                                <td className="px-3 py-2 ">Rp. {item.invoice_price}</td>
                                                <td className="px-3 py-2 ">Rp. {item.invoice_price / 2}</td>
                                                <td className="px-3 py-2 ">Rp. {item.fixed_price}</td>
                                                <td className="px-3 py-2 text-end">{item.stock}</td>
                                                <td className="px-3 py-2 text-start">{item.unit}</td>
                                                <td className="  px-1 py-2 h-full w-16">
                                                    <button type="button" className=" w-full bg-green-300 hover:bg-green-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </button>
                                                </td>
                                                <td className="  px-1 py-2 h-full w-16">
                                                    <button type="button" className=" w-full bg-yellow-300 hover:bg-yellow-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                </td>
                                                <td className="  px-1 py-2 h-full w-16">
                                                    <button type="button" onClick={(e) => handleDelete(e, item.id)} className=" w-full bg-red-300 hover:bg-red-400 transition-colors rounded-md">
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        <Pagination
                                            currentPage={currentPage} setCurrentPage={setCurrentPage}
                                            lastPage={lastPage} setRefresh={setRefresh} colSpan={10} />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                }
            />
        </>
    )
}

export default Obat