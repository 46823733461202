
import { useState } from "react"
import CustomInput from "../../../../components/custom_input"
import { importDrugs } from "../../../../services/drug_service"

const ModalImportObat = ({ openModal, setOpenModal, setRefresh }) => {
    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState(null)

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const response = await importDrugs(
            localStorage.getItem('access_token'),
            file
        )
        console.log(response);
        setFile(null)
        setLoading(false)
        setOpenModal(false)
        setRefresh(true)
    }

    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-300'}`}>

            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[250px] md:h-[280px] lg:h-[280px] max-h-[90%] delay-300' : 'w-0 h-0 overflow-hidden delay-0'}`}>

                <div className="absolute top-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data obat Baru</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full h-full py-[70px] overflow-auto">
                    <div className=" flex flex-col items-center justify-center space-y-3 px-3">

                        <div className="w-full flex flex-col">
                            <label htmlFor={`file`} className={`uppercase font-semibold text-xs md:text-sm lg:text-base space-x-3`}>
                                <span>Input File</span>
                                {/* {errorMessage === null || errorMessage === '' ? '' : <span className="text-red-500 text-xs lowercase">{errorMessage}</span>} */}
                            </label>
                            <input id={`file`} type="file" onChange={handleFileChange} className={` w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md`} />
                        </div>

                        <div className="w-full flex items-center space-x-3">
                            <button
                                type="button"
                                onClick={() => { setOpenModal(false) }}
                                className="w-full flex items-center justify-center space-x-3 uppercase bg-red-500 py-2 rounded-md font-semibold text-white">
                                Kembali
                            </button>


                            <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                                {loading === true ? <>
                                    <div className="spinner-wave ">
                                        <div className="spinner-wave-dot  bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                        <div className="spinner-wave-dot bg-white"></div>
                                    </div>
                                </> : 'SIMPAN'}
                            </button>
                        </div>
                    </div>
                </form>
                <div className="absolute bottom-0 w-full text-center py-3 font-semibold bg-custom_orange text-white h-16 flex items-center justify-center">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div >
    )
}

export default ModalImportObat