import apiService from "./api_service"

export const getResep = async (accessToken) => {
    try {
        const response = await apiService.get(`/resep`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searchResep = async (accessToken, keyword) => {
    try {
        const response = await apiService.get(`/resep/search?keyword=${keyword}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const showResep = async (accessToken, id) => {
    try {
        const response = await apiService.get(`/resep/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postResep = async (accessToken, formData) => {
    try {
        const response = await apiService.post(`/resep/create`, formData, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const deleteResep = async (accessToken, id) => {
    try {
        const response = await apiService.post(`/resep/delete/${id}`, {}, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}