import { Helmet } from "react-helmet"
import Layout from "../../../../components/layouts"
import Header from "../../../../components/header"
import { Link, useHref, useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { showResep } from "../../../../services/resep_service"
import CustomMonth from "../../../../components/custom_month"
import CustomYear from "../../../../components/custom_year"
import CustomDay from "../../../../components/custom_day"
import CustomDayName from "../../../../components/custom_day_name"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import CustomHour from "../../../../components/custom_hour"
import ModalPaymentResep from "./modals/payment"

const DetailResep = () => {
    const path = useHref()
    const { id } = useParams()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState([])
    const [openModalPayment, setOpenModalPayment] = useState(false)
    let number = 1;

    const fetchData = async () => {
        setLoading(true)
        try {
            const response = await showResep(
                localStorage.getItem('access_token'),
                id
            )
            setLoading(false)
            console.log(response);
            switch (response.status) {
                case 401:
                    // navigate('/login')
                    break;

                case 403:
                    // navigate('/403')
                    break;

                case 200:
                    setData(response.data.data);
                    break;

                default:
                    navigate('/404')
                    break;
            }

        } catch (error) {
            alert('silahkan cek internet Anda..!')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const CardList = ({ name, value, addClassValue }) => {
        return (
            <div className="flex items-center space-x-3">
                <p className="w-40">{name}</p>
                <p>:</p>
                <p className={addClassValue}>{value}</p>
            </div>
        )
    }
    let totalPayment = 0;
    const calculateTotalPrice = (items) => {
        return items.reduce((acc, item) => acc + item.total_price, 0);
    };

    const totalPrice = !data?.recipe_item ? 0 : calculateTotalPrice(data.recipe_item);
    return (
        <>
            <Helmet>
                <title>Detail Resep</title>
            </Helmet>
            <Layout
                modal={
                    <>
                        <ModalPaymentResep idResep={id} totalBayar={totalPrice}
                            openModal={openModalPayment}
                            setOpenModal={setOpenModalPayment} setRefresh={setRefresh} />
                    </>
                }
            >
                <Header>
                    <Link to="/dashboard/obat/resep" className="flex items-center space-x-3 px-3 py-2" >
                        <FontAwesomeIcon icon={faChevronCircleLeft} />
                        <p className="hidden md:flex">Kembali</p>
                    </Link>
                    <p>Detail Resep</p>
                </Header>

                <div className="w-full px-3 md:px-5 py-2">
                    <div className="flex items-end justify-between pb-3">
                        <div>
                            <CardList name={`Watu`} value={<CustomHour data={data?.created_at} />} />
                            <CardList name={`Tanggal dan Waktu`} value={
                                <>
                                    {data.created_at == null ? '' :
                                        <>
                                            <CustomDayName data={data?.created_at} />, &nbsp;
                                            <CustomDay data={data?.created_at} />-
                                            <CustomMonth data={data?.created_at} />-
                                            <CustomYear data={data?.created_at} />
                                        </>
                                    }
                                </>
                            } />
                            <CardList name={`Kode Resep`} value={data?.recipe_number} addClassValue="font-semibold" />
                            <CardList name={`Status`} value={<>
                                <div className={`px-3 py-1 ${data?.status === 'sudah dibayar' ? 'bg-custom_green' : 'bg-red-500'} rounded-full text-white font-bold text-sm`}>
                                    {data?.status}
                                </div>
                            </>} addClassValue="font-semibold" />
                        </div>
                        <div className="flex items-center space-x-3">
                            {data?.status === "sudah dibayar" ? '' :
                                <button type="button" onClick={() => setOpenModalPayment(true)} className="px-3 py-2 flex items-center justify-center text-white w-32 h-fit font-semibold bg-custom_green rounded-md">
                                    Pembayaran
                                </button>
                            }
                            <Link to={`/dashboard/obat/resep/document/${id}?back_link=${path}`} className="px-3 py-2 flex items-center justify-center text-white w-32 h-fit font-semibold bg-custom_green rounded-md">
                                Print
                            </Link>
                        </div>
                    </div>

                    <table className="w-full">
                        <thead>
                            <tr className="bg-custom_green text-white">
                                <th className="px-3 py-2 rounded-tl-md">No</th>
                                <th className="px-3 py-2">Nama Obat</th>
                                <th className="px-3 py-2">Aturan pake</th>
                                <th className="px-3 py-2">Harga</th>
                                <th className="px-3 py-2">Jumlah</th>
                                <th className="px-3 py-2 rounded-tr-md">Total Harga</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.recipe_item?.map((item) => {
                                const itemTotalPrice = item?.price * item?.quantity;
                                // Tambahkan total harga item ke dalam total pembayaran
                                totalPayment += itemTotalPrice;

                                return (
                                    <tr>
                                        <td className="px-3 py-2">{number++}</td>
                                        <td className="px-3 py-2">{item?.name} ({item?.unit === '' || item?.unit === null ? '-' : item?.unit})</td>
                                        <td className="px-3 py-2 text-start">{item?.how_to_use ?? '-'}</td>
                                        <td className="px-3 py-2 text-end">{item?.price.toLocaleString()}</td>
                                        <td className="px-3 py-2 text-center">{item?.quantity}</td>
                                        <td className="px-3 py-2 text-end">{item?.total_price.toLocaleString()}</td>
                                    </tr>
                                )
                            })}

                            <tr className="font-semibold bg-custom_green text-white">
                                <td className="px-3 py-2 text-center" colSpan={5}>Total Pembayaran</td>
                                <td className="px-3 py-2 text-end">Rp. {totalPayment}</td>
                            </tr>

                        </tbody>
                    </table>


                    <CardList name={`Kategori Pasien`} value={data?.patient?.category} />
                    {data?.patient?.category === 'non_bpjs' ? '' : <CardList name={`No BPJS`} value={data?.patient?.bpjs_number} />}
                    <CardList name={`Nik`} value={data?.patient?.nik} />
                    <CardList name={`Nama Pasien`} value={data?.patient?.name} />
                    <CardList name={`Tempat Lahir`} value={data?.patient?.place_of_birth} />
                    <CardList name={`Tanggal Lahir`} value={data?.patient?.birthday} />
                    <CardList name={`Jenis Kelamin`} value={data?.patient?.gender} />
                    <CardList name={`No Telepon`}
                        value={data?.patient?.phone_number === null ||
                            data?.patient?.phone_number === 'null' ? '-' :
                            data?.patient?.phone_number} />
                </div>

            </Layout>
        </>
    )
}

export default DetailResep