import apiService from "./api_service"

export const getOfficeAddress = async (accessToken) => {
    try {

        const response = await apiService.get(`/office-address`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const postOfficeAddress = async (accessToken, body) => {
    try {

        const response = await apiService.post(`/office-address/create`, body, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

