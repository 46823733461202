import { useEffect, useRef, useState } from "react"
import HandleShowHide from "../handle_show_hide"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"


const CardStaff = ({ number, profile, name, email, position, id, setStaffId, setOpenModalUpdate }) => {
    const [openPopper, setOpenPopper] = useState(false)
    const popupRef = useRef(null);

    useEffect(() => {
        // Fungsi untuk menutup popup saat klik diluar area popup
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopper(false);
            }
        }

        // Mendaftarkan event listener saat komponen dimount
        document.addEventListener("mousedown", handleClickOutside);

        // Membersihkan event listener saat komponen dibongkar
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleTogglePopper = () => {
        setOpenPopper(!openPopper);
    };

    return (
        <div className="w-full py-3 px-3 hover:bg-gray-200 transition-colors border shadow-md rounded-xl flex items-center justify-between space-x-3">
            <div className="flex items-center space-x-3">
                <div className="h-10 aspect-square rounded-md border bg-gray-100 flex items-center justify-center">{number}</div>
                {profile === null ?
                    <div className="h-12 aspect-square rounded-full bg-gray-500"></div> : ''
                }
                <div className="text-sm">
                    <p className="line-clamp-1">{name}</p>
                    <p className="line-clamp-1">{email}</p>
                    <p className="line-clamp-1 h-5 mt-3 bg-custom_green font-semibold px-3 rounded-full text-white w-[150px] text-center">{position}</p>
                </div>
            </div>
            <div className="relative flex items-start justify-end">
                <button
                    type="button"
                    onClick={handleTogglePopper}
                    class="px-3 bg-gray-100 py-2 rounded-md">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </button>
                <div
                    className={`absolute z-10 inline bg-black/30 top-10 rounded-xl text-white overflow-hidden transition-all duration-300 ${openPopper === true ? 'w-36 h-20 px-5 py-2' : 'w-0 h-0'}`}
                    ref={popupRef}
                >
                    <div className="flex items-center flex-col space-y-3">
                        <button
                            type="button"
                            onClick={() => {
                                setOpenModalUpdate(true)
                                setStaffId(id)
                            }}
                        >
                            Edit
                        </button>
                        <a href="">Hapus</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardStaff