import apiService from "./api_service"

export const getIcd = async (page) => {
    try {

        const response = await apiService.get(`/icd?page=${page}`, {
            headers: {
                // Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}

export const searchIcd = async (keyword, page) => {
    try {

        const response = await apiService.get(`/icd/search?keyword=${keyword}&page=${page}`, {
            headers: {
                // Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response
    }
}