import { faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { getDistrict, getProvince, getRegency, getVillage } from "../../../../../services/location_service"
import { useNavigate } from "react-router-dom"
import { postDrugSuplier } from "../../../../../services/drug_suplier_service"

const ModalAddSuplayer = ({ openModal, setOpenModal, setRefresh }) => {
    const navigate = useNavigate()
    const [accessToken, setAccessToken] = useState('')
    const [accessToken2, setAccessToken2] = useState('')

    const [loading, setLoading] = useState(false)
    const [companyName, setCompanyName] = useState('')
    const [personResponsible, setPersonResponsible] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [name, setName] = useState('')
    const [invoicePrice, setInvoicePrice] = useState('')
    const [fixedPrice, setFixdPrice] = useState('')

    const [province, setProvince] = useState([])
    const [provinceId, setProvinceId] = useState(null)

    const [regency, setRegency] = useState([])
    const [regencyId, setRegencyId] = useState(null)

    const [district, setDistrict] = useState([])
    const [districtId, setDistrictId] = useState(null)

    const [village, setVillage] = useState([])
    const [villageId, setVillageId] = useState(null)

    const [detail, setDetail] = useState('');

    const [errorProvince, setErrorProvince] = useState(false)
    const [errorRegency, setErrorRegency] = useState(false)
    const [errorDistrict, setErrorDistrict] = useState(false)
    const [errorVillage, setErrorVillage] = useState(false)
    const [errorDetail, setErrorDetail] = useState(false)

    const fetchProvince = async () => {
        const response = await getProvince(localStorage.getItem('access_token2'), null)
        if (response.status === 200) {
            setProvince(response.data?.data)
            setProvinceId(null)
        }
    }

    const fetchRegency = async (provinceId) => {
        const response = await getRegency(localStorage.getItem('access_token2'), provinceId, null)
        if (response.status === 200) {
            setRegency(response.data?.data)
            setRegencyId(null)
        }
    }

    const fetchDistrict = async (regencyId) => {
        const response = await getDistrict(localStorage.getItem('access_token2'), regencyId, null)
        if (response.status === 200) {
            setDistrict(response.data?.data)
            setDistrictId(null)
        }
    }

    const fetchVillage = async (districtId) => {
        const response = await getVillage(localStorage.getItem('access_token2'), districtId, null)
        if (response.status === 200) {
            setVillage(response.data?.data)
            setVillageId(null)
        }
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token')
        const accessToken2 = localStorage.getItem('access_token2')
        setAccessToken(accessToken)
        setAccessToken2(accessToken2)

        fetchProvince()
    }, [accessToken, accessToken2]);

    const handeleProvince = (e) => {
        setProvinceId(e.target.value)
        fetchRegency(e.target.value)
        setDistrict([])
        setDistrictId(null)
        setVillage([])
        setVillageId(null)
    }

    const handeleRegency = (e) => {
        setRegencyId(e.target.value)
        fetchDistrict(e.target.value)
        setVillage([])
        setVillageId(null)
    }

    const handeleDistrict = (e) => {
        setDistrictId(e.target.value)
        fetchVillage(e.target.value)
    }

    const handeleVillage = (e) => {
        setVillageId(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const response = await postDrugSuplier(
            localStorage.getItem('access_token'),
            companyName, personResponsible,
            phoneNumber, provinceId,
            regencyId, districtId,
            villageId, detail
        )

        setOpenModal(false)
        setRefresh(true)
        setLoading(false)
    }
    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[450px] lg:h-[550px] delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>
                <button
                    type="button"
                    onClick={() => { setOpenModal(false) }}
                    className="text-red-700 bg-red-200 w-fit aspect-square rounded-full absolute top-5 right-5 hover:bg-red-300 transition-colors">
                    <FontAwesomeIcon icon={faClose} className="w-10"></FontAwesomeIcon>
                </button>

                <div className="w-full text-center border-b-2 pt-12 pb-3 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Tambah Data Suplayer</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-3 space-y-3 px-3 overflow-auto">
                    <div className="w-full">
                        <label htmlFor="category" className="block">Nama Perusahaan / Suplayer</label>
                        <input type="text" value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="category" className="block">Nama Penanggung Jawab</label>
                        <input type="text" value={personResponsible} onChange={(e) => setPersonResponsible(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>
                    <div className="w-full">
                        <label htmlFor="category" className="block">No Telepon</label>
                        <input type="number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" />
                    </div>

                    <div className="flex flex-col ">
                        <label htmlFor="category" className="capitalize">Provinsi</label>
                        <select value={provinceId} onChange={handeleProvince} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value={null} className="text-center">== Pilih Provinsi ==</option>
                            {province.map((item) => (
                                <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                        <p className="text-red-500 text-sm">silahkan pilih provinsi..!</p>
                    </div>
                    <div className="flex flex-col ">
                        <label htmlFor="category" className="capitalize">Kabupaten / Kota</label>
                        <select value={regencyId} onChange={handeleRegency} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value={null} className="text-center">== Pilih Kabupaten / Kota ==</option>
                            {regency.map((item) => (
                                <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                        <p className="text-red-500 text-sm">silahkan pilih kabupaten..!</p>
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="category" className="capitalize">Kecamatan</label>
                        <select value={districtId} onChange={handeleDistrict} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value={null} className="text-center">== Pilih Kecamatan ==</option>
                            {district.map((item) => (
                                <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                        <p className="text-red-500 text-sm">silahkan pilih kecamatan..!</p>
                    </div>
                    <div className="flex flex-col ">
                        <label htmlFor="category" className="capitalize">Desa / Kelurahan</label>
                        <select value={villageId} onChange={handeleVillage} id="category" className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value={null} className="text-center">== Pilih Desa / Kelurahan ==</option>
                            {village.map((item) => (
                                <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                        <p className="text-red-500 text-sm">silahkan pilih Desa / Kelurahan..!</p>
                    </div>
                    <div className="flex flex-col ">
                        <label htmlFor="detail" className="capitalize">Desa / Kelurahan</label>
                        <textarea id="detail" value={detail} onChange={(e) => setDetail(e.target.value)} rows={5} className="px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md"></textarea>
                        <p className="text-red-500 text-sm">detail alamat harus diisi..!</p>
                    </div>

                    <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-green-500 py-2 rounded-md font-semibold text-white">
                        {loading === true ? <>
                            <div className="spinner-wave ">
                                <div className="spinner-wave-dot  bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                            </div>
                        </> : 'SIMPAN'}
                    </button>
                </form>

                <div className="w-full text-center border-t-2 pt-3 pb-12 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalAddSuplayer