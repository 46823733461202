import { useEffect } from "react";

const CustomRefPopper = ({ setOpenPopper, popperRef }) => {
    useEffect(() => {
        // Fungsi untuk menutup popup saat klik diluar area popup
        function handleClickOutside(event) {
            if (popperRef.current && !popperRef.current.contains(event.target)) {
                setOpenPopper(false);
            }
        }

        // Mendaftarkan event listener saat komponen dimount
        document.addEventListener("mousedown", handleClickOutside);

        // Membersihkan event listener saat komponen dibongkar
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setOpenPopper, popperRef]);

    // Komponen ini tidak perlu mereturn apapun, karena hanya mengatur event listener
    return null;
}

export default CustomRefPopper