import { faClose} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ModalFilterPatient = ({ loading, setLoading, category, setCategory, sortBy, setSortBy, sortFrom, setSortFrom, openModal, setOpenModal, setRefresh }) => {
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setOpenModal(false)
        setRefresh(true)
    }
    
    return (
        <div className={`fixed bg-black/50 flex items-center justify-center duration-500 px-5 text-xs md:text-sm lg:text-base ${openModal === true ? 'z-50 w-full h-screen delay-0' : 'z-0 w-0 h-0 rounded-full delay-200'}`}>
            <div className={` bg-white rounded-2xl relative transition-all duration-500 max-w-[500px] overflow-hidden flex flex-col justify-between items-center ${openModal === true ? 'w-full h-[450px] lg:h-[500px] delay-200' : 'w-0 h-0 overflow-hidden delay-0'}`}>
                <button
                    type="button"
                    onClick={() => { setOpenModal(false) }}
                    className="text-red-700 bg-red-200 w-fit aspect-square rounded-full absolute top-5 right-5 hover:bg-red-300 transition-colors">
                    <FontAwesomeIcon icon={faClose} className="w-10"></FontAwesomeIcon>
                </button>

                <div className="w-full text-center border-b-2 pt-12 pb-3 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">Set  Filter</p>
                </div>

                <form onSubmit={handleSubmit} className="w-full py-3 space-y-3 px-3">
                    <div className="w-full">
                        <label htmlFor="category" className="block">Kategori Pasien</label>
                        <select value={category} onChange={(e) => setCategory(e.target.value)} id="category" className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value="all">Semua</option>
                            <option value="bpjs">BPJS</option>
                            <option value="non_bpjs">NON BPJS</option>
                        </select>
                    </div>
                    <div className="w-full">
                        <label htmlFor="sortBy" className="block">Urutkan Berdasarkan</label>
                        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)} id="sortBy" className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value="name">Nama</option>
                            <option value="created_at">Tanggal Dibuat</option>
                        </select>
                    </div>

                    <div className="w-full">
                        <label htmlFor="sortFrom" className="block">Urutkan</label>
                        <select value={sortFrom} onChange={(e) => setSortFrom(e.target.value)} id="sortFrom" className="w-full px-3 py-2 outline-none border-2 focus:border-custom_green rounded-md" >
                            <option value="asc">Naik (ASC)</option>
                            <option value="desc">Menurun (DESC)</option>
                        </select>
                    </div>
                    <button type="submit" className="w-full flex items-center justify-center space-x-3 uppercase bg-custom_green hover:bg-green-600 transition-colors py-2 rounded-md font-semibold text-white">
                        {loading === true ? <>
                            <div className="spinner-wave ">
                                <div className="spinner-wave-dot  bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                                <div className="spinner-wave-dot bg-white"></div>
                            </div>
                        </> : 'SIMPAN'}
                    </button>
                </form>

                <div className="w-full text-center border-t-2 pt-3 pb-12 font-semibold bg-orange-600 text-white">
                    <p className="text-base md:text-xl lg:text-2xl uppercase">KLINIK YASINTA</p>
                </div>
            </div>
        </div>
    )
}

export default ModalFilterPatient